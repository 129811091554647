import React from 'react'
import { Link } from 'react-router-dom';

interface btnType {
    children: React.ReactNode;
    onClick?: () => void;
    cssClass?: any;
}

interface linkType {
  children: React.ReactNode;
  cssClass?: any;
  to: string;
  params?: any
}

export const CustomBtn = ({children, onClick, cssClass} : btnType) => {
  return (
    <button onClick={onClick} className={cssClass + " w-full sm:w-fit text-white focus:outline-none font-black rounded-lg text-sm px-5 py-2.5 text-center duration-500 "}>
        {children}
    </button>
  )
}

export const SuccessBtn = ({children, onClick, cssClass} : btnType) => {
  return (
    <button onClick={onClick} className={cssClass + " w-full sm:w-fit text-xs px-4 py-[.68rem] text-white bg-green-700 hover:bg-green-600 rounded shadow-xl hover:scale-[1.02] "}>
        {children}
    </button>
  )
}

export const DangerBtn = ({children, onClick, cssClass} : btnType) => {
  return (
    <button onClick={onClick} className={cssClass + " w-full sm:w-fit text-xs px-4 py-[.68rem] text-white bg-red-700 hover:bg-red-600 rounded shadow-xl hover:scale-[1.02] "}>
        {children}
    </button>
  )
}

export const PrimaryBtn = ({children, onClick, cssClass} : btnType) => {
    return (
      <button onClick={onClick} className={cssClass + " w-full sm:w-fit px-4 py-[.68rem] text-xs text-white bg-blue-500 rounded shadow-xl hover:scale-[1.02] "}>
          {children}
      </button>
    )
}

export const TransparentBtn = ({children, onClick, cssClass} : btnType) => {
  return (
    <button onClick={onClick} className={cssClass + " w-full sm:w-fit px-4 py-2 text-xs text-zinc-700 bg-zinc-100 rounded shadow-xl hover:scale-[1.02] border-2 border-zinc-500 "}>
      {children}
    </button>
  )
}


export const TransparentLink = ({children, cssClass, to, params} : linkType) => {
  return (
    <Link to={to} state={{params}} className={cssClass + " w-full sm:w-fit h-fit text-center px-4 py-2 text-xs text-zinc-700 bg-zinc-100 rounded shadow-xl hover:scale-[1.02] border-2 border-zinc-500 "}>
      {children}
    </Link>
  )
}