import React, { useRef, useState, useEffect} from 'react'
import Member_Header from './header.tsx';
import { Modal } from '../../components/modal.tsx'
import { AdminTableList } from '../../components/adminTableList.tsx';
import SignatureCanvas from 'react-signature-canvas';
import { PrimaryBtn, SuccessBtn , TransparentBtn} from '../../components/btn.tsx'
import { ItemContainer, DropDownThreeStatus, DropDownDefualtValue, ConvertPersianNumToEnglishNum, DropDownTwoStatus, DropDownPercentStatus, DatePicketComponent, HandlerNavigateTankCode, HandlerChangeDigitToEn, CustomInput, CustomTextArea, SerachInput } from '../../components/common.tsx'
import { defaultStateVal, uploadError } from '../../array/array.ts'
import axios from 'axios'
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText } from '../../array/array.ts';
import {ShowProtectedImage} from '../../components/showProtectedImage.tsx';

const defaultFenderValue = [
    {title:'ندارد'},
    {title:'دارد و سالم'},
    {title:'دارد و یک گلگیر معیوب'},
    {title:'دارد و دو گلگیر معیوب'},
    {title:'دارد و سه گلگیر معیوب'}
]

const defaultBoltAndNutValue = [
    {title:'کامل'},
    {title:'ناقص - یکی ندارد'},
    {title:'ناقص - دوتا ندارد'},
    {title:'ناقص - سه تا ندارد'},
]

const defaultrimValue = [
    {title:'سالم'},
    {title:'معیوب - یک رینگ شکسته'},
    {title:'معیوب - دو رینگ شکسته'},
    {title:'معیوب - سه رینگ شکسته'},
    {title:'معیوب - چهار رینگ شکسته'},
    {title:'معیوب - پنج رینگ شکسته'},
    {title:'معیوب - شیش رینگ شکسته'},
]

const defaultFaucetCapValue = [
    {title:'ندارد'}, 
    {title:'دارد و ۱ عدد'}, 
    {title:'دارد و ۲ عدد'}, 
    {title:'دارد و ۳ عدد'}
]

const tableHead = [
    {pName:"تلفن", eName:"number", type:"number"},
    {pName:"نام خانوادگی", eName:"family", type:"string", sorted:true},
    {pName:"نام", eName:"name", type:"string", sorted:true},
]

const DeliveryReport = () => {
    const navigate = useNavigate();
    const tankCode = localStorage.getItem("tankCode");
    const tankId = localStorage.getItem("tankId");

    const [loader, setLoader] = useState<boolean>(false)
    const [driverId, setDriverId] = useState<number>(0)
    const [searchItem, setSearchItem] = useState<string>('')
    const [driverData, setDriverData] = useState<any[]>([])
    const [driversList, setDriversList] = useState<any[]>([])
    const [filteredDriverData, setFilteredDriverData] = useState<any[]>([])
  
    const [companyName, setCompanyName] = useState<string>('')
    const [chassisNumber, setChassisNumber] = useState<string>('')
    const [deliveryDate, setDeliveryDate] = useState<string>()
    const [contractNumber, setContractNumber] = useState<string>('')
    const [fName, setFName] = useState<string>('')
    const [lName, setLName] = useState<string>('')
    const [driverNumber, setDriverNumber] = useState<number>()
    const [nationalCode, setNationalCode] = useState<number>()
    const [fromState, setFromState] = useState<string>('')

    const [tankStatus, setTankStatus] = useState<string>(defaultStateVal)
    const [valveBox, setValveBox] = useState<string>(defaultStateVal)
    const [rightFender, setRightFender] = useState<string>(defaultStateVal)
    const [leftFender, setLeftFender] = useState<string>(defaultStateVal)
    const [rearSideLights, setRearSideLights] = useState<string>(defaultStateVal)
    const [powerPlug, setPowerPlug] = useState<string>(defaultStateVal)
    const [spreadMud, setSpreadMud] = useState<string>(defaultStateVal)

    const [spareStrap, setSpareStrap] = useState<string>(defaultStateVal)
    const [faucetConversion, setFaucetConversion] = useState<string>(defaultStateVal)
    const [faucetCap, setFaucetCap] = useState<string>(defaultStateVal)

    const [safetyValve, setSafetyValve] = useState<string>(defaultStateVal)
    const [ladder, setLadder] = useState<string>(defaultStateVal)
    const [tankerJack, setTankerJack] = useState<string>(defaultStateVal)
    const [brakeRatchets, setBrakeRatchets] = useState<string>(defaultStateVal)
    const [rearBumper, setRearBumper] = useState<string>(defaultStateVal)

    const [machine, setMachine] = useState<string>(defaultStateVal)
    const [rim, setRim] = useState<string>(defaultStateVal)
    const [boltAndNut, setBoltAndNut] = useState<string>(defaultStateVal)
    const [earthChain, setEarthChain] = useState<string>(defaultStateVal)
    const [spare, setSpare] = useState<string>(defaultStateVal)
    const [waterTank, setWaterTank] = useState<string>(defaultStateVal)
    const [fireBox, setFireBox] = useState<string>(defaultStateVal)
    const [fireCapsule, setFireCapsule] = useState<string>(defaultStateVal)
    const [fifthGear, setFifthGear] = useState<string>(defaultStateVal)
    const [barometer, setBarometer] = useState<string>(defaultStateVal)
    const [thermometer, setThermometer] = useState<string>(defaultStateVal)
    const [rotorGage, setRotorGage] = useState<string>(defaultStateVal)
    const [rotorGageDisc, setRotorGageDisc] = useState<string>(defaultStateVal)
    const [valvePercentageGauge, setValvePercentageGauge] = useState<string>(defaultStateVal)
    const [fastClosingSystem, setFastClosingSystem] = useState<string>(defaultStateVal)
    const [fullDesc, setFullDesc] = useState<string>('')

    const [brakePad, setBrakePad] = useState<number>(50)
    const [openSigModal, setOpenSigModal] = useState<boolean>(false)
    const [imageSignature, setImageSignature] = useState<string>(); 
    const [isBase64, setIsBase64] = useState<boolean>(); 
    const [openSubmitModal, setOpenSubmitModal] = useState<boolean>(false)
    const [openSearchDriverModal, setopenSearchDriverModal] = useState<boolean>(false)

    const [wheelPercent1, setWheelPercent1] = useState<number>(50)
    const [wheelPercent2, setWheelPercent2] = useState<number>(50)
    const [wheelPercent3, setWheelPercent3] = useState<number>(50)
    const [wheelPercent4, setWheelPercent4] = useState<number>(50)
    const [wheelPercent5, setWheelPercent5] = useState<number>(50)
    const [wheelPercent6, setWheelPercent6] = useState<number>(50)

    const [wheelName1, setWheelName1] = useState<string>('')
    const [wheelName2, setWheelName2] = useState<string>('')
    const [wheelName3, setWheelName3] = useState<string>('')
    const [wheelName4, setWheelName4] = useState<string>('')
    const [wheelName5, setWheelName5] = useState<string>('')
    const [wheelName6, setWheelName6] = useState<string>('')
    const sigCanvas = useRef({});
    

    function handlerSigCanvas() {
        setOpenSigModal(false);
        setImageSignature(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
        setIsBase64(true)
    }

    function handlerSubmitModal() {
        if(companyName === '' 
            || chassisNumber === '' || !deliveryDate || !contractNumber || fName === '' 
            || lName === '' || !driverNumber || !nationalCode
            || fromState === '' || tankStatus === '' || valveBox === defaultStateVal 
            || rightFender === defaultStateVal || leftFender === defaultStateVal || rearSideLights === defaultStateVal ||
            powerPlug === defaultStateVal || spareStrap === defaultStateVal || faucetConversion === defaultStateVal ||
            faucetCap === defaultStateVal || safetyValve === defaultStateVal || ladder === defaultStateVal ||
            tankerJack === defaultStateVal || brakeRatchets === defaultStateVal || rearBumper === defaultStateVal
            || machine === defaultStateVal || rim === defaultStateVal || boltAndNut === defaultStateVal ||
            earthChain === defaultStateVal || spare === defaultStateVal || waterTank === defaultStateVal ||
            fireBox === defaultStateVal || fireCapsule === defaultStateVal || fifthGear === defaultStateVal || barometer === defaultStateVal ||
            thermometer === defaultStateVal || rotorGageDisc === defaultStateVal || valvePercentageGauge === defaultStateVal ||
            fastClosingSystem === defaultStateVal || imageSignature === undefined || wheelName1 === '' || wheelName2 === '' || wheelName3 === '' || wheelName4 === '' || wheelName5 === '' || wheelName6 === ''
        ){
            alert('لطفا تمامی فیلد هارا کامل کنید.')
        }else{
            //  -------- check for same driver --------
            const sameNumber = driversList.find(item => item.number === driverNumber)
            const sameFamily = driversList.find(item => item.family === lName)
            const sameNationalCode = driversList.find(item => item.nationalCode === nationalCode)
            // ----- 0 for while if driver dident select from list ------
            if(sameNationalCode && driverId === 0){
                alert('این کد ملی قبلا برای راننده ثبت شده. لطفا راننده را از لیست انتخاب کنید')
                handlerSelectDriver()
                // navigate(0)
            }else{
                setOpenSubmitModal(!openSubmitModal)
            }
        }
    }
    
    const handlerSubmitBtn = async () => {
        setOpenSubmitModal(false)
        setLoader(true)

        axios.post(`${process.env.REACT_APP_API_ADDRESS}users/deliveryReport`, 
            {
                tank_id: tankId,
                user_id: Cookies.get('id'),
                driver_id: driverId,
                companyName: companyName,
                chassisNumber: ConvertPersianNumToEnglishNum(chassisNumber),
                deliveryDate: HandlerChangeDigitToEn(deliveryDate),
                contractNumber: ConvertPersianNumToEnglishNum(contractNumber),
                driverName: fName,
                driverFamily: lName,
                driverNumber: ConvertPersianNumToEnglishNum(driverNumber),
                nationalCode: ConvertPersianNumToEnglishNum(nationalCode),
                fromState: fromState,
                tankStatus: tankStatus,
                valveBox: valveBox,
                rightFender: rightFender,
                leftFender: leftFender,
                rearSideLights: rearSideLights,
                powerPlug: powerPlug,
                spreadMud: spreadMud,
                spareStrap: spareStrap,
                faucetConversion: faucetConversion,
                faucetCap: faucetCap,
                safetyValve: safetyValve,
                ladder: ladder,
                tankerJack: tankerJack,
                brakeRatchets: brakeRatchets,
                rearBumper: rearBumper,
                machine: machine,
                rim: rim,
                boltAndNut: boltAndNut,
                earthChain: earthChain,
                spare: spare,
                waterTank: waterTank,
                fireBox: fireBox,
                fireCapsule: fireCapsule,
                fifthGear: fifthGear,
                barometer: barometer,
                thermometer: thermometer,
                rotorGage: rotorGage,
                rotorGageDisc: rotorGageDisc,
                valvePercentageGauge: valvePercentageGauge,
                fastClosingSystem: fastClosingSystem,
                fullDesc: fullDesc,
                brakePad: brakePad,
                wheelPercent1: wheelPercent1,
                wheelPercent2: wheelPercent2,
                wheelPercent3: wheelPercent3,
                wheelPercent4: wheelPercent4,
                wheelPercent5: wheelPercent5,
                wheelPercent6: wheelPercent6,
                wheelName1: wheelName1,
                wheelName2: wheelName2,
                wheelName3: wheelName3,
                wheelName4: wheelName4,
                wheelName5: wheelName5,
                wheelName6: wheelName6,
                imageSignature:imageSignature,
                // ---- this line show that if image was in db dont save it again else save new image
                isBase64:isBase64
            },
            {
                headers: {
                    'auth-token': Cookies.get('token'),
                    'auth-id': Cookies.get('id')
                }
            })
            .then(response => {
                // console.log('Response:', response.data);
                setLoader(false)
                alert('فرم به درستی ثبت شد . برای نمایش لیست گزارشات ثبت شده به صفحه لیست گزارش تحویل مراجعه کنید.')
                // window.open(`/member/printableDeliveryReport?data=${response.data}`, '_blank');
                navigate(0)
            })
            .catch(error => {
                // console.error('Error:', error);
                setLoader(false)
                alert(uploadError)
            }
        );
    };
    
    useEffect(() => {
        setLoader(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}users/driversList`, {
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                setFilteredDriverData(response.data);
                setDriversList(response.data);
                setLoader(false);
            } catch (error) {
                // console.error('Error fetching tanks:', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate('/member')
            }
        };
    
        fetchData();
    }, []);
    
    function handlerSelectDriver() {
        setopenSearchDriverModal(true)
    }

    function handlerClearSelectedDriver() {
        setDriverData([])
        setDriverId(0)
        setFName('')
        setLName('')
        setDriverNumber(0)
        setNationalCode(0)
        setImageSignature(undefined)
        setopenSearchDriverModal(false)
    }
    
    function SelectedDriverModalBody() {
        const handleInputSearch = (e) => { 
            const searchTerm = e.target.value;
            setSearchItem(searchTerm);
            const filteredItems = driversList.filter((item) =>
                item.family && item.family.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase())
            );
            setFilteredDriverData(filteredItems);
        }
        function handlerSelectedBtn(e) {
            setIsBase64(false)
            setDriverData(e)
        }
        
        return(
            <>
                <div className='flex flex-row flex-wrap-reverse items-end justify-between gap-3 m-2'>
                    <div className='flex items-start justify-start flex-row gap-3'>
                        <SerachInput title={"جستجوی بر اساس نام خانوادگی"} state={searchItem} handler={handleInputSearch} customWidth={'w-[16rem]'}/>
                    </div>
                </div>
                <div className='h-[22rem] overflow-y-scroll'>
                    <AdminTableList
                        TableList={filteredDriverData} 
                        tableHeadParams={tableHead}
                        setSelect={handlerSelectedBtn}
                        itemsPerPageCount={filteredDriverData.length+1}
                        stickyHeader
                    />
                </div>
            </>
        )
    }

    useEffect(()=>{
        if(driverData[0]){
            setopenSearchDriverModal(false)
            setDriverId(driverData[0].driver_id)
            setFName(driverData[0].name)
            setLName(driverData[0].family)
            setDriverNumber(driverData[0].number)
            setNationalCode(driverData[0].nationalCode)
            driverData[0].sigImg && setImageSignature(driverData[0].sigImg)
        }
    },[driverData])
    
    return (
        <div className='m-r-nav'>
            {loader && <FullLoader />}
            <Member_Header tankName={tankCode+' ثبت گزارش تحویل مخزن '}/>
            
            {
                tankCode ?
                    <div className='mx-4 sm:p-2 -mt-10'>

                        <ItemContainer>
                            {CustomInput({state:companyName, setState:setCompanyName, label:'ساخت کارخانه', width:30, length:50})}
                            {CustomInput({state:chassisNumber, setState:setChassisNumber, label:'شماره شاسی', width:15, length:15})}
                            <DatePicketComponent title="در تاریخ" state={deliveryDate} setState={setDeliveryDate} />
                            {CustomInput({state:contractNumber, setState:setContractNumber, label:'شماره قرارداد ', width:15, length:10})}
                        </ItemContainer>

                        <p className='text-center p-6 pt-0'>
                            <span>برای انتخاب راننده ابتدا بر روی دکمه انتخاب راننده کلیک کرده و از لیست باز شده راننده مورد نظر را انتخاب کنید</span>
                            <br/>
                            <span>در صورتی که راننده در لیست یافت نشد میتوانید در فیلد های نام و نام خانوادگی و شماره تلفن بطور دستی اطلاعات راننده را وارد کنید</span>
                        </p>
                        <div className='w-full center'>
                            <PrimaryBtn onClick={handlerSelectDriver} cssClass={'px-10 py-2'}>
                                <p className='text-sm font-bold'>انتخاب راننده از لیست</p>
                            </PrimaryBtn>
                        </div>

                        <ItemContainer>
                            {CustomInput({state:fName, setState:setFName, label:'نام راننده', width:30, length:50, disable:driverData[0]&&true})}
                            {CustomInput({state:lName, setState:setLName, label:'نام خانوادگی راننده', width:30, length:50, disable:driverData[0]&&true})}
                            {CustomInput({state:driverNumber, setState:setDriverNumber, label:'شماره تلفن راننده', width:11, length:11, disable:driverData[0]&&true})}
                            {CustomInput({state:nationalCode, setState:setNationalCode, label:'کد ملی راننده', width:11, length:10, disable:driverData[0]&&true})}
                            {CustomTextArea({state:fromState, setState:setFromState, label:'اعزامی از', width:25, length:100})}
                        </ItemContainer>

                        <ItemContainer>
                            <DropDownTwoStatus val={'healthyFaulty'} state={tankStatus} setState={setTankStatus} dropTitle='وضعیت مخزن'/>
                            <DropDownThreeStatus state={valveBox} setState={setValveBox} dropTitle='جعبه شیرآلات'/>
                            <DropDownDefualtValue state={rightFender} setState={setRightFender} dropTitle='گلگیر سه طرف سمت شاگرد' list={defaultFenderValue}/>
                            <DropDownDefualtValue state={leftFender} setState={setLeftFender} dropTitle='گلگیر سه طرف سمت راننده' list={defaultFenderValue}/>
                            <DropDownThreeStatus state={rearSideLights} setState={setRearSideLights} dropTitle='چراغ های عقب بغل'/>
                            <DropDownThreeStatus state={powerPlug} setState={setPowerPlug} dropTitle='فیش برق و کفگرد'/>
                            <DropDownThreeStatus state={spreadMud} setState={setSpreadMud} dropTitle='گل پخش کن'/>
                        </ItemContainer>

                        <ItemContainer>
                            <DropDownTwoStatus val={'healthyFaulty'} state={spareStrap} setState={setSpareStrap} dropTitle='زاپاس بند'/>
                            <DropDownTwoStatus val={'yesNo'} state={faucetConversion} setState={setFaucetConversion} dropTitle='تبدیل شیرآلات'/>
                            <DropDownDefualtValue state={faucetCap} setState={setFaucetCap} dropTitle='درپوش شیرآلات' list={defaultFaucetCapValue}/>
                        </ItemContainer>

                        <ItemContainer>
                            <DropDownThreeStatus state={safetyValve} setState={setSafetyValve} dropTitle='سوپاپ های اطمینان'/>
                            <DropDownThreeStatus state={ladder} setState={setLadder} dropTitle='نردبان'/>
                            <DropDownThreeStatus state={tankerJack} setState={setTankerJack} dropTitle='جک تانکر با دسته'/>
                            <DropDownThreeStatus state={brakeRatchets} setState={setBrakeRatchets} dropTitle='جغجغه های ترمز'/>
                            <DropDownThreeStatus state={rearBumper} setState={setRearBumper} dropTitle='سپر عقب'/>
                        </ItemContainer>

                        <ItemContainer>
                            <DropDownTwoStatus val={'healthyFaulty'} state={machine} setState={setMachine} dropTitle='دستگاه'/>
                            <DropDownDefualtValue state={rim} setState={setRim} dropTitle='رینگ' list={defaultrimValue}/>
                            <DropDownDefualtValue state={boltAndNut} setState={setBoltAndNut} dropTitle='پیچ و مهره' list={defaultBoltAndNutValue}/>
                            <DropDownTwoStatus val={'yesNo'} state={earthChain} setState={setEarthChain} dropTitle='زنجیر ارت'/>
                            <DropDownTwoStatus val={'yesNo'} state={spare} setState={setSpare} dropTitle='زاپاس'/>
                            <DropDownTwoStatus val={'yesNo'} state={waterTank} setState={setWaterTank} dropTitle='تانک آب'/>
                            <DropDownTwoStatus val={'yesNo'} state={fireBox} setState={setFireBox} dropTitle='جعبه آتش نشانی'/>
                            <DropDownTwoStatus val={'yesNo'} state={fifthGear} setState={setFifthGear} dropTitle='دنده پنج'/>
                            <DropDownTwoStatus val={'yesNo'} state={fireCapsule} setState={setFireCapsule} dropTitle='کپسول آتش نشانی'/>
                            <DropDownTwoStatus val={'yesNo'} state={barometer} setState={setBarometer} dropTitle='فشار سنج'/>
                            <DropDownTwoStatus val={'yesNo'} state={thermometer} setState={setThermometer} dropTitle='ترمومتر'/>
                            <DropDownTwoStatus val={'yesNo'} state={rotorGage} setState={setRotorGage} dropTitle='روتور گیج'/>
                            <DropDownTwoStatus val={'yesNo'} state={rotorGageDisc} setState={setRotorGageDisc} dropTitle='صفحه روتور گیج'/>
                            <DropDownTwoStatus val={'yesNo'} state={valvePercentageGauge} setState={setValvePercentageGauge} dropTitle='شیر درصد سنج'/>
                            <DropDownTwoStatus val={'yesNo'} state={fastClosingSystem} setState={setFastClosingSystem} dropTitle='سیستم زود بند'/>
                        </ItemContainer>

                        <ItemContainer>
                            <DropDownPercentStatus state={wheelPercent1} setState={setWheelPercent1} dropTitle='درصد لاستیک محور عقب سمت راننده'/>
                            {CustomInput({state:wheelName1, setState:setWheelName1, label:'نام لاستیک محور عقب سمت راننده', width:30, length:50, cssClass:'pt-4'})}
                            <DropDownPercentStatus state={wheelPercent2} setState={setWheelPercent2} dropTitle='درصد لاستیک محور وسط سمت راننده'/>
                            {CustomInput({state:wheelName2, setState:setWheelName2, label:'نام لاستیک محور وسط سمت راننده', width:30, length:50, cssClass:'pt-4'})}
                            <DropDownPercentStatus state={wheelPercent3} setState={setWheelPercent3} dropTitle='درصد لاستیک محور جلو سمت راننده'/>
                            {CustomInput({state:wheelName3, setState:setWheelName3, label:'نام لاستیک محور جلو سمت راننده', width:30, length:50, cssClass:'pt-4'})}
                            <DropDownPercentStatus state={wheelPercent4} setState={setWheelPercent4} dropTitle='درصد لاستیک محور عقب سمت شاگرد'/>
                            {CustomInput({state:wheelName4, setState:setWheelName4, label:'نام لاستیک محور عقب سمت شاگرد', width:30, length:50, cssClass:'pt-4'})}
                            <DropDownPercentStatus state={wheelPercent5} setState={setWheelPercent5} dropTitle='درصد لاستیک محور وسط سمت شاگرد'/>
                            {CustomInput({state:wheelName5, setState:setWheelName5, label:'نام لاستیک محور وسط سمت شاگرد', width:30, length:50, cssClass:'pt-4'})}
                            <DropDownPercentStatus state={wheelPercent6} setState={setWheelPercent6} dropTitle='درصد لاستیک محور جلو سمت شاگرد'/>
                            {CustomInput({state:wheelName6, setState:setWheelName6, label:'نام لاستیک محور جلو سمت شاگرد', width:30, length:50, cssClass:'pt-4'})}
                        </ItemContainer>

                        <ItemContainer>
                            <div dir={'rtl'} className='w-full sm:w-[22rem]'>
                                <label htmlFor="brakePad" className="text-sm font-medium text-gray-900">
                                درصد لنت ترمز   % 
                                </label>
                                <label className="text-md font-medium text-gray-500">
                                    {brakePad}
                                </label>
                                <input
                                    type="range"
                                    className="transparent h-[4px] w-full cursor-pointer appearance-none border-transparent bg-neutral-200 dark:bg-neutral-600"
                                    min="0"
                                    max="100"
                                    step="5"
                                    id="brakePad"
                                    value={brakePad}
                                    onChange={e=>setBrakePad(Number(e.target.value))}
                                />
                            </div>
                            {CustomTextArea({state:fullDesc, setState:setFullDesc, label:'توضیحات', width:25, length:1000, height:6})}
                        </ItemContainer>
                        
                        <ItemContainer>
                            <div className={`center flex-wrap-reverse `}>
                                {
                                    imageSignature?
                                        imageSignature?.includes('.jpeg') ?
                                            <ShowProtectedImage
                                                imageRoute='signaturePhotos'
                                                imageName={imageSignature}
                                                imageAlt={"عکس امضا"} 
                                                imageClass={'object-contain w-44 h-44 m-3 rounded-md border-2 border-green-500 shadow-md p-2'}
                                            />
                                        : 
                                            <img
                                                src={imageSignature}
                                                // src={imageSignature?.includes('.jpeg') ? process.env.REACT_APP_API_ADDRESS + 'signaturePhotos' + imageSignature : imageSignature} 
                                                alt="عکس امضا"
                                                className='object-contain w-44 h-44 m-3 rounded-md border-2 border-green-500 shadow-md p-2'
                                            />
                                    : null
                                }
                                <TransparentBtn 
                                    cssClass={imageSignature === undefined ? "ring-gray-300":"ring-green-500"}
                                    onClick={()=>setOpenSigModal(true)}
                                >
                                    ثبت امضا تحویل گیرنده
                                </TransparentBtn>
                            </div>
                        </ItemContainer>

                        <ItemContainer>
                            <SuccessBtn onClick={handlerSubmitModal}>
                                ثبت و نمایش فرم نهایی
                            </SuccessBtn>
                        </ItemContainer>

                        <Modal 
                            title="ثبت امضای تحویل گیرنده"
                            body="لطفا در کادر پایین امضای خود را وارد و سپس آن را ثبت کنید"
                            btnCancel={setOpenSigModal}
                            btnClear={()=>sigCanvas.current.clear()}
                            btnSubmit={handlerSigCanvas}
                            onOpen={openSigModal}
                        >
                            <SignatureCanvas
                                ref={sigCanvas}
                                penColor='black'
                                canvasProps={{height:600, className: ' ring-2 ring-green-400 rounded my-4 w-full'}}
                            />
                        </Modal>

                        <Modal 
                            icon="success"
                            title="ثبت نهایی فرم"
                            body="آیا از صحت اطلاعات فوق اطمینان دارید."
                            btnCancel={setOpenSubmitModal}
                            btnSubmit={handlerSubmitBtn}
                            onOpen={openSubmitModal}
                        />

                        <Modal 
                            title="لیست راننده ها"
                            body="در صورتی که راننده در لیست یافت نشد میتوانید اطلاعات راننده را بصوت دستی در فیلد های نام و نام خانوادگی و شماره همراه وارد کنید"
                            btnCancel={setopenSearchDriverModal}
                            btnClear={handlerClearSelectedDriver}
                            onOpen={openSearchDriverModal}
                        >
                            {SelectedDriverModalBody()}
                        </Modal>
                    </div>
                :
                    <HandlerNavigateTankCode/>
            }   
        </div>
    )
}

export default DeliveryReport
