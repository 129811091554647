import React,{ useState, useEffect } from 'react'
import Admin_Header from './header.tsx';
import { AdminTableList } from '../../components/adminTableList.tsx'
import { PrimaryBtn, TransparentLink } from '../../components/btn.tsx';
import { DropDownStatus, DropDownSearchStatus, DatePicketComponent, HandlerChangeDigitToEn, ConvertPersianNumToEnglishNum, SerachInput, ImageCard } from '../../components/common.tsx';
import { Modal } from '../../components/modal.tsx';
import axios from 'axios';
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText, uploadError } from '../../array/array.ts';
import {ShowProtectedImage} from '../../components/showProtectedImage.tsx';

const tableHead = [
  {pName:"نام", eName:"name", type:"string", sorted:true, showAllData:true},
  {pName:"نام خانوادگی", eName:"family", type:"string", sorted:true, showAllData:true},
  {pName:"شماره همراه", eName:"number", type:"number", showAllData:true},
  {pName:"سمت", eName:"position", type:"string", showAllData:true, sorted:true,},
]

const tableHeadParams =[
  {pName:"نام", eName:"name", type:"string", star:true},
  {pName:"نام خانوادگی", eName:"family", type:"string", star:true},
  {pName:"شماره همراه", eName:"number", type:"number", star:true},
  {pName:"کد ملی", eName:"nationalCode", type:"number", star:true},
  {pName:"پسورد", eName:"password", type:"string", star:true},  
  {pName:"سمت شغلی", eName:"position", type:"string", star:true},
  {pName:"نام پدر", eName:"fatherName", type:"string",},
  {pName:"آدرس", eName:"address", type:"string"},
  {pName:"شماره کارت", eName:"cartNumber", type:"number"},
  {pName:"کد پرسنلی", eName:"personalCode", type:"number"},
  {pName:"تاریخ تولد", eName:"birthDate", type:"string"},
  {pName:"جنسیت", eName:"gender", type:"string"},
  {pName:"تحصیلات", eName:"education", type:"string"},
  {pName:"رشته تحصیلی", eName:"studyField", type:"string"},
  {pName:"امضا", eName:"sig", type:"string"}
]

const tableHeadShow =[
  {pName:"نام", eName:"name", type:"string"},
  {pName:"نام خانوادگی", eName:"family", type:"string"},
  {pName:"شماره همراه", eName:"number", type:"number"},
  {pName:"کد ملی", eName:"nationalCode", type:"number"},
  {pName:"سمت شغلی", eName:"position", type:"number"},
  {pName:"نام پدر", eName:"fatherName", type:"string",},
  {pName:"آدرس", eName:"address", type:"string"},
  {pName:"شماره کارت", eName:"cartNumber", type:"number"},
  {pName:"کد پرسنلی", eName:"personalCode", type:"number"},
  {pName:"تاریخ تولد", eName:"birthDate", type:"string"},
  {pName:"جنسیت", eName:"gender", type:"number"},
  {pName:"تحصیلات", eName:"education", type:"number"},
  {pName:"رشته تحصیلی", eName:"studyField", type:"number"},
  {pName:"امضا", eName:"sig", type:"string"}
]

const userGenderList = [
  {pName: 'مرد'},
  {pName: 'زن'},
]

const userPositionList = [
  {pName: 'کارمند'},
  {pName: 'مدیر'},
  {pName: 'مدیر تانک 22 تا 46'},
  {pName: 'مهمان'},
]

const userEducationList = [
  {pName: 'دیپلم'},
  {pName: 'فوق دیپلم / کاردانی'},
  {pName: 'لیسانس / کارشناسی'},
  {pName: 'فوق لیسانس / کارشناسی ارشد'},
  {pName: 'دکترا'},
  {pName: 'فوق دکتری'},
]

const defaultUserValue = {
  user_id:0, name:'', family:'', fatherName:'', number:undefined, nationalCode:undefined, address:'', cartNumber:undefined, personalCode:undefined, birthDate:undefined, position:'', education:'', studyField:'', gender:'', password:'', sig:''
}

interface openDeleteModalDataTypes {
  user_id:number, 
  name:string, 
  family?:string,
}

interface updateModalDataTypes {
  user_id:number,
  name:string,
  family:string,
  fatherName?:string,
  number?:number,
  nationalCode?:number,
  address?:string,
  cartNumber?:number,
  personalCode?:number,
  birthDate?:string,
  position?:string,
  education?:string,
  studyField?:string,
  gender?:string,
  password?:string,
  sig?:any,
  sigImg?: {
    url: string;
    size: number;
    type: string;
    base64: string;
  };
}

interface ShowModalDataTypes {
  user_id:number,
  name:string,
  family:string,
  fatherName?:string,
  number?:number,
  nationalCode?:number,
  address?:string,
  cartNumber?:number,
  personalCode?:number,
  birthDate?:string,
  position?:string,
  education?:string,
  studyField?:string,
  gender?:string,
  password?:string,
  sig?:any,
  sigImg?: {
    url: string;
    size: number;
    type: string;
    base64: string;
  };
}

interface handlerDeleteFuncTypes {
  user_id:number, 
  name:string,
  family?:string,
}

const Admin_UserAccountsList = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState<boolean>(false)
  const [refreshPage, setRefreshPage] = useState<number>(0)
  const [data, setData] = useState<any[]>([])
  const [filteredArray, setFilteredArray] = useState<any[]>([])
  const [searchDropDownParam, setSearchDropDownParam] = useState<string>(tableHead[0].pName)
  const [searchItem, setSearchItem] = useState<string>('')
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openDeleteModalData, setDeleteModalData] = useState<openDeleteModalDataTypes>({user_id:0, name:'', family:''})
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false)
  const [updateModalData, setUpdateModalData] = useState<updateModalDataTypes>(defaultUserValue)
  const [openInsertModal, setOpenInsertModal] = useState<boolean>(false)
  const [insertModalData, setInsertModalData] = useState<updateModalDataTypes>(defaultUserValue)
  const [openShowModal, setOpenShowModal] = useState<boolean>(false)
  const [showModalData, setShowModalData] = useState<updateModalDataTypes>(defaultUserValue)
  const [image, setImage] = useState<any>('');
  const headerTitle = 'لیست کارکنان';
  
  useEffect(() => {
    setLoader(true);
    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}admin/usersList`, {
                headers: {
                    'auth-token': Cookies.get('token'),
                    'auth-id': Cookies.get('id')
                }
            });
            setFilteredArray(response.data);
            setData(response.data);
            setLoader(false);
        } catch (error) {
            // console.error('Error fetching users:', error);
            setLoader(false); // Hide loader after fetching data
            alert(loginText)
            navigate('/admin')
        }
    };

    fetchData();
  }, [refreshPage]);
  
  const handlerInsertQuery = async () => {
    if(insertModalData.name && insertModalData.family && insertModalData.nationalCode && insertModalData.number && insertModalData.password && insertModalData.position ){
      setLoader(true)
      axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/insertUser`, 
        {
          name: insertModalData.name, 
          family: insertModalData.family, 
          fatherName: insertModalData.fatherName, 
          number: ConvertPersianNumToEnglishNum(insertModalData.number), 
          nationalCode: ConvertPersianNumToEnglishNum(insertModalData.nationalCode), 
          address: insertModalData.address, 
          cartNumber: ConvertPersianNumToEnglishNum(insertModalData.cartNumber), 
          personalCode: ConvertPersianNumToEnglishNum(insertModalData.personalCode), 
          birthDate: HandlerChangeDigitToEn(insertModalData.birthDate) ,
          education: insertModalData.education, 
          studyField: insertModalData.studyField, 
          password: ConvertPersianNumToEnglishNum(insertModalData.password),
          sigImg: image != '' ? image.base64 : null,
          gender: insertModalData.gender,
          position: insertModalData.position,
        },{
          headers: {
              'auth-token': Cookies.get('token'),
              'auth-id': Cookies.get('id')
          }
        })
        .then(response => {
            // console.log('Response:', response.data);
            setLoader(false)
            // alert('کاربر به درستی ثبت شد.')
            setRefreshPage((prev) => prev + 1)
            setOpenInsertModal(false)
            setInsertModalData(defaultUserValue)
            setImage('')
        })
        .catch(error => {
            // console.error('Error:', error);
            setLoader(false)
            setImage('')
            alert(uploadError)
            // alert(loginText)
            // navigate('/admin')        
          }
      )
    }else{
      alert('لطفا تمام فیلدهای ستاره دار را کامل کنید.')
    }
  }
  
  const handlerUpdateQuery = async () => {
    setLoader(true)
    axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/updateUser`, 
      {
        name: updateModalData.name, 
        family: updateModalData.family, 
        fatherName: updateModalData.fatherName, 
        number: ConvertPersianNumToEnglishNum(updateModalData.number), 
        nationalCode: ConvertPersianNumToEnglishNum(updateModalData.nationalCode), 
        address: updateModalData.address, 
        cartNumber: ConvertPersianNumToEnglishNum(updateModalData.cartNumber), 
        personalCode: ConvertPersianNumToEnglishNum(updateModalData.personalCode), 
        birthDate: HandlerChangeDigitToEn(updateModalData.birthDate) ,
        education: updateModalData.education, 
        studyField: updateModalData.studyField, 
        password: ConvertPersianNumToEnglishNum(updateModalData.password),
        gender: updateModalData.gender,
        position: updateModalData.position,
        sigImg: image != '' ? image.base64 : null,
        user_id: updateModalData.user_id,
      },{
        headers: {
            'auth-token': Cookies.get('token'),
            'auth-id': Cookies.get('id')
        }
      })
      .then(response => {
          // console.log('Response:', response.data);
          setLoader(false)
          alert('کاربر به درستی ویرایش شد.')
          setRefreshPage((prev) => prev + 1)
          setOpenUpdateModal(false)
          setImage('')
      })
      .catch(error => {
          // console.error('Error:', error);
          setLoader(false)
          setImage('')
          alert(uploadError)
          // alert(loginText)
          // navigate('/admin')
      }
    )
  }
  
  const handlerDeleteQuery = async () => {
    setLoader(true)
    axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/deleteUser`, 
        {
            user_id: openDeleteModalData.user_id
        },
        {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
        })
        .then(response => {
            // console.log('Response:', response.data);
            setLoader(false)
            setRefreshPage((prev) => prev + 1)
            setOpenDeleteModal(false)
        })
        .catch(error => {
            // console.error('Error:', error);
            setLoader(false)
            alert(uploadError)
            // alert(loginText)
            // navigate('/admin')
        }
    )
  };

  function SearchTableData() {
    const handleInputSearch = (e) => { 
      const searchTerm = ConvertPersianNumToEnglishNum(e.target.value);
      setSearchItem(searchTerm);
      const filteredItems = data.filter((item) =>
          searchDropDownParam === tableHead[0].pName ?
            item.name && item.name.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase()) :
          searchDropDownParam === tableHead[1].pName ?
            item.family && item.family.includes(searchTerm) :
          searchDropDownParam === tableHead[2].pName ?
            item.number && item.number.includes(searchTerm) :
          null
      );
    setFilteredArray(filteredItems);
  }
  return(
    <div className='w-full flex flex-row flex-wrap-reverse center md:items-center md:justify-between gap-3 p-4'>
      <div className='w-full sm:w-fit flex flex-row flex-wrap-reverse gap-2'>
        <PrimaryBtn onClick={()=>setOpenInsertModal(true)}>
            افزودن کاربر جدید
        </PrimaryBtn>
        <TransparentLink to={'/admin/printList/'} params={{array:filteredArray, listHead: tableHeadParams, pageHeader:headerTitle}}>
                فرم چاپی
        </TransparentLink>
      </div>
      <div className='w-fit flex items-start justify-start flex-row gap-3'>
        <SerachInput title={"جستجوی " + searchDropDownParam} state={searchItem} handler={handleInputSearch}/>
        <DropDownSearchStatus list={tableHead} state={searchDropDownParam} setState={setSearchDropDownParam}/>
      </div>
    </div>
  )}

  const handlerDeleteFunc = (item: handlerDeleteFuncTypes) => {
    setOpenDeleteModal(true)
    setDeleteModalData({user_id:item.user_id, name:item.name, family:item.family})
  } 
  
  const handlerUpdateFunc = (item: updateModalDataTypes) => {
    setImage('')
    setOpenUpdateModal(true)
    setUpdateModalData({user_id:item.user_id, name:item.name, family:item.family, fatherName:item.fatherName, number:item.number, nationalCode:item.nationalCode, address:item.address, cartNumber:item.cartNumber, personalCode:item.personalCode, birthDate:item.birthDate, position:item.position, education:item.education, studyField:item.studyField, gender:item.gender, sig:item.sigImg })
  }

  const handlerShowFunc = (item: ShowModalDataTypes) => {
    setOpenShowModal(true)
    setShowModalData({user_id:item.user_id, name:item.name, family:item.family, fatherName:item.fatherName, number:item.number, nationalCode:item.nationalCode, address:item.address, cartNumber:item.cartNumber, personalCode:item.personalCode, birthDate:item.birthDate, position:item.position, education:item.education, studyField:item.studyField, gender:item.gender, sig:item.sigImg })
  }

  const InsertModalBody = () => {
    const handlerInsertBirthDate = (e) => {
      setInsertModalData(prevState => ({...prevState,birthDate: e}))
    }
    const handlerInsertUserPosition = (e) => {
      setInsertModalData(prevState => ({...prevState, position: e}))
    }
    const handlerInsertUsergender = (e) => {
      setInsertModalData(prevState => ({...prevState, gender: e}))
    }
    const handlerInsertUserEducation = (e) => {
      setInsertModalData(prevState => ({...prevState,	education: e}))
    }
    return(
      <ul className='w-[20rem] sm:w-[25rem] space-y-2'>
      {
        tableHeadParams.map((item,key)=>(
          <li key={key} className='flex flex-row items-center'>
            <span className='text-sm w-2/5'>
              {item.pName}:
              {item.star && <span className='text-red-500'>*</span>}
            </span>
            {
              item.eName === 'birthDate' ?
                <DatePicketComponent 
                  title={item.pName} 
                  state={insertModalData[item.eName]} 
                  setState={handlerInsertBirthDate}  
                  type='simple'
                />
              :
                item.eName === 'position'?
                  <DropDownStatus state={insertModalData.position} setState={handlerInsertUserPosition} list={userPositionList} />
                :
                  item.eName === 'gender'?
                    <DropDownStatus state={insertModalData.gender} setState={handlerInsertUsergender} list={userGenderList} />
                  :
                    item.eName === 'education'?
                      <DropDownStatus top={true} state={insertModalData.education} setState={handlerInsertUserEducation} list={userEducationList} />
                    :
                      item.eName === 'sig' ?
                        <ImageCard 
                          state={image} 
                          setState={setImage} 
                          title={'آپلود عکس امضا'}
                          size={'xs'}
                        />
                      :
                        <input
                          type="text" 
                          id={item.pName}
                          key={item.eName}
                          className={" p-2 w-full sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " }
                          placeholder={item.pName}
                          value={insertModalData[item.eName]} 
                          onChange={e => setInsertModalData(prevState => ({...prevState,[item.eName]: e.target.value}))}
                        />
            }
          </li>
        ))
      }
    </ul>
    )
  }
  
  const UpdateModalBody = () => {
    const handlerUpdateBirthDate = (e) => {
      setUpdateModalData(prevState => ({...prevState,birthDate: e}))
    }
    const handlerUpdateUserPosition = (e) => {
      setUpdateModalData(prevState => ({...prevState, position: e}))
    }
    const handlerUpdateUsergender = (e) => {
      setUpdateModalData(prevState => ({...prevState, gender: e}))
    }
    const handlerUpdateUserEducation = (e) => {
      setUpdateModalData(prevState => ({...prevState,	education: e}))
    }
    
    return(
      <ul className='w-[20rem] sm:w-[25rem] space-y-2'>
        {
          tableHeadParams.map((item,key)=>(
            <li key={key} className='flex flex-row items-center'>
              <span className='text-sm w-2/5'>
                {item.pName}:
                {item.star && <span className='text-red-500'>*</span>}
              </span>
              {
                item.eName === 'birthDate' ?
                  <DatePicketComponent 
                    title={item.pName} 
                    state={updateModalData[item.eName]} 
                    setState={handlerUpdateBirthDate}  
                    type='simple'
                  />
                :
                  item.eName === 'position'?
                    <DropDownStatus state={updateModalData.position} setState={handlerUpdateUserPosition} list={userPositionList} />
                  :
                    item.eName === 'gender'?
                      <DropDownStatus state={updateModalData.gender} setState={handlerUpdateUsergender} list={userGenderList} />
                    :
                      item.eName === 'education'?
                        <DropDownStatus top={true} state={updateModalData.education} setState={handlerUpdateUserEducation} list={userEducationList} />
                      :
                        item.eName === 'sig' ?
                          <ImageCard
                            state={image ? image : updateModalData[item.eName] ? process.env.REACT_APP_API_ADDRESS + 'signaturePhotos' + updateModalData[item.eName] : image} 
                            setState={setImage} 
                            title={'آپلود عکس امضا'}
                            size={'xs'}
                          />
                        :
                          <input
                            type="text" 
                            id={item.pName}
                            key={item.eName}
                            className={" p-2 w-full sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " }
                            placeholder={item.pName}
                            value={updateModalData[item.eName]} 
                            onChange={e => setUpdateModalData(prevState => ({...prevState,[item.eName]: e.target.value}))}
                          />
              }
            </li>
          ))
        }
      </ul>
    )
  }

  const ShowModalBody = () => {
    return(
      <ul className='w-[20rem] sm:w-[25rem] space-y-2'>
        {
          tableHeadShow.map((item,key)=>(
            <li key={key} className='flex flex-row items-center'>
              <span className='text-sm w-2/5'>
                {item.pName}:
              </span>
              {
                item.eName === 'sig' ?
                  showModalData[item.eName] ? 
                    <ShowProtectedImage 
                      imageRoute={'signaturePhotos'} 
                      imageName={showModalData[item.eName]} 
                      imageAlt={'عکس امضا'} 
                      imageClass={'sm:max-w-80 h-36 object-contain'}
                    />
                    // <img 
                    //   className='sm:max-w-80 h-36 object-contain'
                    //   src={showModalData[item.eName] && process.env.REACT_APP_API_ADDRESS + 'signaturePhotos' + showModalData[item.eName]} 
                    //   alt={'عکس امضا'}
                    // />
                  :
                    <input
                      type="text" 
                      disabled={true}
                      id={item.pName}
                      key={item.eName}
                      className={" p-2 w-full sm:max-w-80 h-36 text-xs text-gray-900 border rounded-lg bg-gray-50 " }
                      placeholder={'امضا ثبت نشده'} 
                    />
                :
                  <input
                    type="text" 
                    disabled={true}
                    id={item.pName}
                    key={item.eName}
                    className={" p-2 w-full sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " }
                    placeholder={item.pName}
                    value={showModalData[item.eName]} 
                  />
              }
            </li>
          ))
        }
      </ul>
    )
  }
  
  return (
    <>
      {loader && <FullLoader />}
      <Admin_Header title={headerTitle}/>

      <div className='m-r-nav px-1 mb-4 sm:px-6'>
        {SearchTableData()}
        <AdminTableList 
          TableList={filteredArray} 
          tableHeadParams={tableHead}
          handlerShowFunc={handlerShowFunc}
          handlerDeleteFunc={handlerDeleteFunc}
          handlerUpdateFunc={handlerUpdateFunc}
        />
      </div>
      
      <Modal 
        title='حذف شخص'
        body={"میخوای  " + openDeleteModalData.name + ' ' + openDeleteModalData.family + " حذف بشه؟ "}
        icon='warning'
        onOpen={openDeleteModal}
        btnCancel={setOpenDeleteModal}
        btnDelete={handlerDeleteQuery}
      />

      <Modal 
        title='ویرایش شخص'
        body={UpdateModalBody()}
        onOpen={openUpdateModal}
        btnCancel={setOpenUpdateModal}
        btnSubmit={handlerUpdateQuery}
      />

      <Modal 
        title='افزودن شخص'
        body={InsertModalBody()}
        onOpen={openInsertModal}
        btnCancel={setOpenInsertModal}
        btnSubmit={handlerInsertQuery}
      />

      <Modal 
        title={'جزئیات کاربر'}
        body={ShowModalBody()}
        onOpen={openShowModal}
        btnCancel={setOpenShowModal}
      />
    </>
  )
}

export default Admin_UserAccountsList
