import React,{useState} from 'react'
import { TiArrowUnsorted } from "react-icons/ti";
import { DangerBtn, SuccessBtn } from './btn.tsx';
import { useNavigate } from 'react-router-dom';
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { paginationScrollTop } from './ScrollTop.tsx'
import { ConvertNumberSeparate } from './common.tsx';

interface TableDateTypes {
    children: string | React.JSX.Element;
    sortAs?: string;
    handlerClick?: any;
    paramType?: 'number' | 'string' | 'string/number';
}

interface handlerSortTypes {
    sortAs: string;
    paramType?: 'number' | 'string' | 'string/number' | 'date';
}

interface AdminTableListTypes {
    TableList: any, 
    tableHeadParams: any, 
    handlerDeleteFunc?: any, 
    handlerUpdateFunc?: any, 
    handlerShowFunc?: any, 
    linkTo?: string,
    setSelect?:any,
    itemsPerPageCount?:number
    printPage?:boolean
    stickyHeader?:boolean
}

export function AdminTableList ({
        TableList, 
        tableHeadParams, 
        handlerDeleteFunc,
        handlerUpdateFunc,
        handlerShowFunc,
        linkTo,
        setSelect,
        itemsPerPageCount,
        printPage,
        stickyHeader
    }: AdminTableListTypes) {
    const navigate = useNavigate();
    const [ascOrDes, setAscOrDes] = useState<boolean>(true)

    // ------- pagination setting --------
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = itemsPerPageCount ? itemsPerPageCount : 20; // Number of items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = TableList.slice(indexOfFirstItem, indexOfLastItem);     

    const handlerSort = ({sortAs, paramType} : handlerSortTypes) => {
        setAscOrDes(!ascOrDes)
        TableList.sort((a, b) => {
            const nameA = a[sortAs];
            const nameB = b[sortAs];
            if(paramType === 'number'){
                if (ascOrDes) {
                    return nameA - nameB;
                }
                else{
                    return nameB - nameA;
                }
            }else if(paramType === 'string'){
                if (ascOrDes) {
                    return nameA.localeCompare(nameB);
                }
                else{
                    return nameB.localeCompare(nameA);
                }
            }else if(paramType === 'string/number'){
                if (ascOrDes) {
                    return nameA.substring(1)-nameB.substring(1)
                }
                else{
                    return nameB.substring(1)-nameA.substring(1)
                }
            }else if(paramType === 'date'){
                if (ascOrDes) {
                    return new Date(nameA) - new Date(nameB)
                }
                else{
                    return new Date(nameB) - new Date(nameA)
                }
            }
        });
    }

    function TableHeader({sortAs, children, paramType} : TableDateTypes) {
        return(
            <th className='border-b-4'>
                <div 
                    className={'tooltip flex flex-row gap-1 sm:gap-3 select-none items-center justify-center h-14 cursor-pointer '} 
                    onClick={()=>sortAs !== undefined ? handlerSort({sortAs, paramType}) : ''} 
                >
                    {
                        sortAs !== undefined ?
                            <>
                                <TiArrowUnsorted size={'1.1rem'}/>
                                <span className='tooltipTextTop'>مرتب کردن بر اساس {children}</span>
                            </>
                        :
                            ''
                    }
                    {children}
                </div>
            </th>
        )
    }

    const handlerDeleteBtnFunc = (item) => {
        handlerDeleteFunc(item)
    }

    const handlerUpdateBtnFunc = (item) => {
        handlerUpdateFunc(item)
    }

    const handlerPageChange = (pagenumber) => {
        setCurrentPage(pagenumber);
        paginationScrollTop()
    }
    
    function TableBody() {
        function handlerSuccessBtn(tank_id, tankCode, linkTo) {
            navigate(linkTo.endsWith('/')? linkTo+tankCode : linkTo)
            // ----- save tankCode To LocalStorage -----
            localStorage.setItem('tankCode', tankCode);
            localStorage.setItem('tankId', tank_id);
            // ----- Use LocalStorage ------
            // localStorage.getItem("tankCode")
            // localStorage.getItem("tankId");
        }
        let count = 0; // Initialize a counter for unique numbering
        return(
            currentItems.map((item, key)=>(
                <tr 
                    key={key} 
                    className={`h-fit divide-x-2 divide-gray-50 border-b hover:border-r-cyan-800 hover:bg-cyan-50 border-r-2 ${key % 2 !== 0?"bg-gray-50":"bg-white"}`}
                >
                    {
                        localStorage.getItem('tank22to46') !== 'مدیر تانک 22 تا 46' ?
                            handlerDeleteFunc || handlerUpdateFunc ?
                                <td width="8%">
                                    <div 
                                        className='flex flex-col-reverse sm:flex-row gap-2 sm:gap-3 select-none items-center justify-center min-h-24 sm:min-h-14 cursor-pointer' 
                                    >
                                        {
                                            handlerDeleteFunc &&
                                                <DangerBtn cssClass={'tooltip pl-2 pr-2 pt-2 pb-2 max-w-fit'} onClick={()=> handlerDeleteBtnFunc(item)} >
                                                    <MdDeleteOutline size={'.9rem'}/>
                                                    <span className='tooltipTextTop'>حذف ردیف</span>
                                                </DangerBtn>

                                        }
                                        {
                                            handlerUpdateFunc &&                                    
                                                <SuccessBtn cssClass={'tooltip pl-2 pr-2 pt-2 pb-2 max-w-fit'} onClick={()=>handlerUpdateBtnFunc(item)}>
                                                    <CiEdit size={'.9rem'}/>
                                                    <span className='tooltipTextTop'>ویرایش ردیف</span>
                                                </SuccessBtn>
                                        }
                                    </div>
                                </td>
                            : 
                                null
                        :
                            null
                    }
                    {
                        tableHeadParams.slice().reverse().map((headItem, headKey)=>(
                            headItem.numberSeparate?
                                <td className='cursor-pointer p-1' key={headKey} onClick={()=>headItem.showAllData?handlerShowFunc(item):undefined} data-tooltip-target="tooltip-animation">
                                    {ConvertNumberSeparate(item[headItem.eName])}
                                </td>
                            :
                                <td className='cursor-pointer p-1' key={headKey} onClick={()=>headItem.showAllData?handlerShowFunc(item):undefined} data-tooltip-target="tooltip-animation">
                                    {item[headItem.eName]}
                                </td>
                        ))
                    }
                    
                    {
                        linkTo?
                            <td width="15%" className='cursor-pointer h-16'>
                                <SuccessBtn onClick={()=>handlerSuccessBtn(item.tank_id, item.tankCode, linkTo)} cssClass={'my-2'}>
                                    انتخاب
                                </SuccessBtn>
                            </td>
                        :
                        setSelect ?
                            <td width="15%" className='cursor-pointer h-16'>
                                <SuccessBtn onClick={()=>setSelect([item])} cssClass={'my-2'}>
                                    انتخاب
                                </SuccessBtn>
                            </td>
                        :
                            <td width="6%" className='cursor-pointer h-16' onClick={()=>handlerShowFunc(item)}>
                                {((currentPage - 1) * itemsPerPage) + (++count)}
                            </td>
                    }
                </tr>
                
            ))
        )
    }

    return(
        <>
            {/* ----- show text نمایش جزعیات ------ */}
            {tableHeadParams[0].showAllData && !printPage ? <p className='text-xs text-right p-2 pb-4 text-gray-600'>.برای نمایش جزئیات بیشتر ردیف مورد نظر خود را انتخاب کنید</p> : null}

            {/* ----- table ------ */}
            <table className="w-full text-gray-800 border-2 border-gray-200" >
                <thead className={`${stickyHeader && "sticky top-0 "} text-xs sm:text-sm text-white uppercase bg-cyan-600 text-center `}> 
                    <tr className=''>
                        {
                            localStorage.getItem('tank22to46') !== 'مدیر تانک 22 تا 46' ?
                                handlerDeleteFunc || handlerUpdateFunc ?
                                    <TableHeader>
                                        عملیات
                                    </TableHeader>
                                :
                                    null
                            :
                                null
                        }
                        {
                            tableHeadParams.slice().reverse().map((item, key) => (
                                <TableHeader key={key} sortAs={item.sorted?item.eName:undefined} paramType={item.type}>
                                    {item.pName}
                                </TableHeader>
                            ))
                        }
                        {
                            linkTo || setSelect?
                                <TableHeader>
                                    انتخاب
                                </TableHeader>
                            :
                                <TableHeader>
                                    تعداد
                                </TableHeader>
                        }
                        
                    </tr>
                </thead>

                <tbody className={`text-center text-xs sm:text-sm hover:bg-cyan-50`}>
                    <TableBody />
                </tbody>
            </table>

            {/* ----- if data not found ------ */}
            {!TableList[0] && <p className='w-full text-center text-sm py-3'>داده ای یافت نشد</p>}
            
            {/* ----- pagination btn ------ */}
            {
                TableList.length > itemsPerPage &&
                    <div className="center pt-4 pb-4">
                        <ResponsivePagination
                            current={currentPage}
                            total={Math.ceil(TableList.length / itemsPerPage)}
                            onPageChange={handlerPageChange}
                        />
                    </div>
            }

        </>
    )
}