import React, { useState, useEffect } from 'react'
import Admin_Header from './header.tsx'
import { AdminMenuArrList, loginText } from '../../array/array.ts'
import { HomeBigBtn } from '../../components/common.tsx'
import axios from 'axios';
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function List() {
    return(
        AdminMenuArrList.map((item, key) => (
            item.color &&
                <HomeBigBtn title={item.title} link={item.href} color={item.color} key={key} />
        ))
    )
}

const Admin_Home = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState<boolean>(true)

    useEffect(() => {
        setLoader(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}adminCheckAuth`, {
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                setLoader(false);
                // console.log(response.data)
            } catch (error) {
                // console.error('Error conecting server', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate('/admin')
            }
        };
    
        fetchData();
    }, []);

    return (
        loader ? 
            <FullLoader />
        :
            <div>
                <Admin_Header/>

                <div className='m-r-nav pt-24 flex flex-col sm:flex-row-reverse p-8 flex-wrap justify-center items-center gap-8'>
                    <List />
                </div>
            </div>
    )
}

export default Admin_Home
