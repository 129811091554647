import React,{useState,useEffect} from "react";
import { IoMdMenu } from "react-icons/io";
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { AdminMenuArrList, AdminMenuArrListWithoutAddUser } from '../../array/array.ts'
import { IoIosExit, IoMdImages } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { GiFuelTank, GiAutoRepair, GiRefinery } from "react-icons/gi";
import { PiClipboardTextFill } from "react-icons/pi";
import { FaRegIdCard, FaClipboardList, FaUserLock, FaHome } from "react-icons/fa";
import { FaUserGroup } from "react-icons/fa6";
import { MdMedicalServices, MdSupportAgent } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa";
import { HiBellAlert } from "react-icons/hi2";
import Cookies from 'js-cookie';

interface headerType {
    title?:string, 
    desc?:string, 
    backTo?:string
    hideDrawer?:boolean
}

let iconSizeMd = '1.8rem'
let iconSizeSm = '1.7rem'

const DrawerAdminIconList = [
    {icon:<FaHome size={iconSizeSm} />},
    {icon:<GiFuelTank size={iconSizeMd} />},
    {icon:<FaRegIdCard size={iconSizeMd} />},
    {icon:<PiClipboardTextFill size={iconSizeMd} />},
    {icon:<FaClipboardList size={iconSizeMd} />},
    {icon:<IoMdImages size={iconSizeMd} />},
    {icon:<GiAutoRepair size={iconSizeMd} />},
    {icon:<GiRefinery size={iconSizeMd} />},
    {icon:<FaUserGroup size={iconSizeMd} />},
    {icon:<FaUserLock size={iconSizeMd} />},
    // {icon:<MdAdminPanelSettings size={iconSizeMd} />},
    {icon:<MdSupportAgent size={iconSizeMd} />},
    {icon:<IoIosExit size={iconSizeMd} />},
]

const DrawerIconListWithoutAddUser = [
    {icon:<FaHome size={iconSizeSm} />},
    {icon:<GiFuelTank size={iconSizeMd} />},
    {icon:<FaRegIdCard size={iconSizeMd} />},
    {icon:<PiClipboardTextFill size={iconSizeMd} />},
    {icon:<FaClipboardList size={iconSizeMd} />},
    {icon:<IoMdImages size={iconSizeMd} />},
    {icon:<GiAutoRepair size={iconSizeMd} />},
    {icon:<GiRefinery size={iconSizeMd} />},
    {icon:<FaUserLock size={iconSizeMd} />},
    // {icon:<MdAdminPanelSettings size={iconSizeMd} />},
    {icon:<MdSupportAgent size={iconSizeMd} />},
    {icon:<IoIosExit size={iconSizeMd} />},
]

export default function Admin_Header({title, desc, backTo, hideDrawer}:headerType) {
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);
    const [adminList] = useState(localStorage.getItem('tank22to46') === 'مدیر تانک 22 تا 46' ? AdminMenuArrListWithoutAddUser : AdminMenuArrList);
    const [drawerIcon] = useState(localStorage.getItem('tank22to46') === 'مدیر تانک 22 تا 46' ? DrawerIconListWithoutAddUser : DrawerAdminIconList);
    const [showBellSpin, setShowBellSpin] = useState<boolean>(true);
    const toggleDrawer = () => setOpen(!open);
    const location = useLocation();
    const pagePathName = location.pathname;
        
    useEffect(()=>{
        setInterval(() => {
            setShowBellSpin(false);
        }, 5000);
    },[])

    const handlerExit = () => {
        localStorage.removeItem('tankCode');
        localStorage.removeItem('tankId');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('tank22to46');
        Cookies.remove('token')
        Cookies.remove('id')
        navigate('/')
    }

    const handlerWarningMessage = () => {
        navigate('/admin/systemWarning')
    }

    const handlerSiteServices = () => {
        navigate('/admin/siteServices')
    }

    return (
        <>
            {/* ----- header line ----- */}
            <nav className={`w-full fixed z-50 h-16 flex flex-row justify-between items-center px-4 sm:px-5 bg-gray-50 shadow-lg`}>
                {
                    backTo ?
                        !open ?
                            <Link to={backTo} className='p-1 sm:p-4 cursor-pointer hover:underline underline-offset-4 flex flex-row gap-2 '>
                                <FaArrowLeft className=' text-zinc-800 '/>
                                <p className='text-sm text-zinc-800 '>برگشت به صفحه قبل</p>
                            </Link>
                        :
                            <div></div>
                    :
                        <div className="flex flow-row items-center gap-4 cursor-pointer">
                            <div className="tooltip">
                                <span className='tooltipTextBottom'>خروج</span>
                                <IoIosExit color="text-zinc-700" size={'1.8rem'} onClick={handlerExit}/>
                            </div>
                            {
                                localStorage.getItem('tank22to46') !== 'مدیر تانک 22 تا 46' && window.screen.width >= 1000 ?
                                    <div className="tooltip">
                                        <span className='tooltipTextBottom'>خدمات سایت</span>
                                        <MdMedicalServices className="mb-1" color="text-zinc-700" size={'1.5rem'} onClick={handlerSiteServices}/>
                                    </div>
                                    :
                                    null
                            }
                            <div className="tooltip">
                                {showBellSpin && <span className={'absolute animate-ping w-2 h-2 bg-red-600 rounded-full right-0'}/>}
                                <span className='tooltipTextBottom'>پیغام ها</span>
                                <HiBellAlert color="text-zinc-700" size={'1.5rem'} onClick={handlerWarningMessage}/>
                            </div>
                        </div>
                }
                <div className="flex flex-row items-center">
                    <p className={`px-2 text-sm text-zinc-800 truncate `}>
                        {localStorage.getItem('userInfo')}
                        {` خوش آمدید`}
                    </p>
                    {
                        open?
                            <IoClose color="bg-zinc-700" size={'1.6rem'} onClick={toggleDrawer} className={`ml-2 sm:ml-2 sm:-mr-[.2rem] cursor-pointer`}/>
                        :
                            <IoMdMenu color="bg-zinc-700" size={'1.6rem'} onClick={toggleDrawer} className="ml-1 sm:ml-3 cursor-pointer"/>
                    }
                </div>
            </nav>
            {/* ----- under header title ----- */}
            {
                title || desc ?
                    <div className="m-auto px-6 pb-8 space-y-4 pt-24">
                        {title && <p className="text-2xl text-center"> {title} </p>}
                        {desc && <p className='font-bold text-base w-full text-center text-zinc-800 pt-0'>{desc}</p>}
                    </div>
                :
                    null
            }
            {/* ----- drawer ----- */}
            {
                hideDrawer ?
                    null
                :
                <div className={`${open?'w-full sm:w-1/4':'w-0 sm:w-16'} overflow-y-auto scrollbar-hide fixed h-full top-0 right-0 sm:top-0 z-40 flex max-w-full flex-col border-none bg-white bg-clip-padding text-neutral-700 shadow-2xl outline-none`}>
                    {/* {
                        open &&
                            <div className="relative w-full py-8 center">
                                <button className={`absolute right-4`}>
                                    <IoClose color="bg-zinc-700" size={'1.6rem'} onClick={toggleDrawer}/>
                                </button>
                            </div>
                    } */}
                    <div className={`mt-16 divide-y-2`}>
                        {
                            adminList.map((item, key)=>(
                                adminList.length-1 === key?
                                    <div 
                                        key={key}
                                        onClick={handlerExit}
                                        className={`tooltip text-right py-3 w-full flex justify-end items-center gap-4 pr-4 hover:bg-gray-100` }
                                    >
                                        {open && item.title}
                                        {drawerIcon[key].icon}
                                        {!open && <span className='tooltipTextLeft'>{item.title}</span>}
                                    </div>
                                :
                                    <Link 
                                        key={key}
                                        to={item.href}
                                        onClick={()=>setOpen(false)}
                                        // reloadDocument
                                        className={`${pagePathName.includes(item.href) ? 'bg-zinc-200' : 'bg-white'} tooltip text-right py-3 w-full flex justify-end items-center gap-4 pr-4 hover:bg-zinc-100`} 
                                    >
                                        {open && item.title}
                                        {drawerIcon[key].icon}
                                        {!open && <span className='tooltipTextLeft'>{item.title}</span>}
                                    </Link>
                            ))
                        }
                    </div>
                    {/* <div className="w-full py-3 text-center absolute bottom-12 hover:bg-gray-100">
                        <button>خروج از حساب کاربری</button>
                    </div> */}
                </div>
            }
        </>
  );
}