import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

const btnClass = 'px-16 sm:px-8 w-2/3 sm:w-fit py-3 bg-orange-500 text-white cursor-pointer rounded-md text-sm font-black shadow-lg transition duration-500 ease-in-out hover:bg-orange-700 transform hover:-translate-y-1 hover:scale-105'
const text = "شرکت فانوس ترابر ملل";

const Home = () => {
  return (
    <div className='main_page center'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          شرکت فانوس ترابر ملل
        </title>
        <meta name="description" content="شرکت فانوس ترابر ملل نمایندگی بیرجند با مدیریت اقای مهدی حیاتی و اقای علی یعقوبی" />
        <meta name="theme-color" content="#000" />
        <link rel="canonical" href="https://mhay.ir/" />
      </Helmet>

      <div className='bg-zinc-900 opacity-30 w-screen h-screen'/>

      <div className='absolute flex flex-col w-screen h-screen center gap-12 text-center '>
        <div className='flex flex-row-reverse'>
          <h1 className='transition-all delay-1000 duration-700 opacity-100 font-black text-6xl text-white shadow-[0px 0px 15px white;] p-4 leading-[5rem] '>
            {text}
          </h1>
          <h2 className='hidden'>
            {text}
          </h2>
          <p className='hidden'>
            {text}
          </p>
        </div>
        <div className='center gap-4 flex-row flex-wrap-reverse px-4'>
          <Link to={'/guest'} className={btnClass} >ورود مهمان</Link>
          <Link to={'/member'} className={btnClass} >ورود کاربران</Link>
          <Link to={'/admin'} className={btnClass} >ورود مدیران</Link>
        </div>
      </div>
      {/* <Link to={'/member'} className='px-12 py-3 bg-white rounded-md' >کاربران</Link>
      <Link to={'/admin'} className='px-12 py-3 bg-white rounded-md' >ادمین</Link> */}
    </div>
  )
}

export default Home
