import React, { useState, useEffect } from 'react'
import Admin_Header from './header.tsx'
import { useParams, useNavigate } from 'react-router-dom';
import { ConvertDateToJalali } from '../../components/common.tsx';
import axios from 'axios';
import { FullLoader } from '../../components/loader.tsx';
import Cookies from 'js-cookie';
import { loginText, uploadError } from '../../array/array.ts';
import { Modal } from '../../components/modal.tsx';
import { DangerBtn } from '../../components/btn.tsx';
import {ShowProtectedImage} from '../../components/showProtectedImage.tsx';

const Admin_TankerPhotosListDetail = () => {
    const params = useParams()
    const navigate = useNavigate();
    const tank_id = localStorage.getItem('tankId')

    const [loader, setLoader] = useState<boolean>(false)  
    const [refreshPage, setRefreshPage] = useState<number>(0)
    const [deliveryDate, setDeliveryDate] = useState<any[]>([])
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [selectedDateId, setSelectedDateId] = useState<string>('');
    const [imageFront, setImageFront] = useState<string>('');
    const [imageDriverSideFront, setImageDriverSideFront] = useState<string>('');
    const [imageDriverSideCenter, setImageDriverSideCenter] = useState<string>('');
    const [imageDriverSideBack, setImageDriverSideBack] = useState<string>('');
    const [imageBack, setImageBack] = useState<string>('');
    const [imageChauffeurSideFront, setImageChauffeurSideFront] = useState<string>('');
    const [imageChauffeurSideCenter, setImageChauffeurSideCenter] = useState<string>('');
    const [imageChauffeurSideBack, setImageChauffeurSideBack] = useState<string>('');
    const [faucets, setFaucets] = useState<string>('');
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

    const albumArray = [
        {value:imageFront,title:'عکس جلوی تانکر'},
        {value:imageDriverSideFront,title:'عکس سمت راننده - جلوی تانکر'},
        {value:imageDriverSideCenter,title:'عکس سمت راننده - وسط تانکر'},
        {value:imageDriverSideBack,title:'عکس لاستیک های سمت راننده'},
        {value:imageBack,title:'عکس عقب تانکر'},
        {value:imageChauffeurSideFront,title:'عکس سمت شاگرد - جلوی تانکر'},
        {value:imageChauffeurSideCenter,title:'عکس سمت شاگرد - وسط تانکر'},
        {value:imageChauffeurSideBack,title:'عکس لاستیک های سمت شاگرد'},
        {value:faucets,title:'عکس شیرآلات'},
    ]

    useEffect(() => {
        const fetchData = async () => {
            setLoader(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/tankPhotosDates`,
                {
                    tank_id: tank_id
                } 
                ,{
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                setDeliveryDate(response.data);
                setLoader(false);
            } catch (error) {
                // console.error('Error fetching tanks:', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate('/admin')
            }
        };

        fetchData();
    }, [refreshPage]);
    
    useEffect(() => {
        const fetchData = async () => {
            setLoader(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/tankPhotosList`,
                {
                    photo_id: selectedDateId
                } 
                ,{
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                const data = response.data[0];
                setImageFront(data.imageFront)
                setImageDriverSideFront(data.imageDriverSideFront)
                setImageDriverSideCenter(data.imageDriverSideCenter)
                setImageDriverSideBack(data.imageDriverSideBack)
                setImageBack(data.imageBack)
                setImageChauffeurSideFront(data.imageChauffeurSideFront)
                setImageChauffeurSideCenter(data.imageChauffeurSideCenter)
                setImageChauffeurSideBack(data.imageChauffeurSideBack)
                setFaucets(data.faucets);
                setLoader(false);
            } catch (error) {
                // console.error('Error fetching photo:', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate('/admin')
            }
        };
        
        if(selectedDateId){
            fetchData();
        }
    }, [selectedDateId]);

    const handlerDeleteQuery = async () => {
        setLoader(true)
        axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/deleteTankPhotosDates`, 
            {
                photo_id: selectedDateId
            },
            {
                headers: {
                    'auth-token': Cookies.get('token'),
                    'auth-id': Cookies.get('id')
                }
            })
            .then(response => {
                // console.log('Response:', response.data);
                // alert(`عکس های تانک ${selectedDate} حذف شد`)
                setLoader(false)
                setSelectedDateId('')
                setSelectedDate('')
                setRefreshPage((prev) => prev + 1)
                setOpenDeleteModal(false)
            })
            .catch(error => {
                // console.error('Error:', error);
                setOpenDeleteModal(false)
                // alert(loginText)
                // navigate('/admin')
                alert(uploadError)
                setLoader(false); // Hide loader in case of an error
            }
        )
    };

    const openImageInNewTab = (item) => {
        window.open(item, '_blank');
    };

    const TankerPhotos = () => {
        return(
            albumArray.map((item, key)=>(
                <div 
                    className={`w-72 h-60 pb-9 flex items-center justify-center relative overflow-hidden border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 hover:border-gray-400`}
                    // onClick={()=>openImageInNewTab(process.env.REACT_APP_API_ADDRESS + 'tankPhotos' + item.value)}
                >
                    <label htmlFor={item.title} className="flex flex-col items-center justify-center cursor-pointer w-full h-full text-center">
                        <ShowProtectedImage
                            imageRoute={'tankPhotos'} 
                            imageName={item.value} 
                            imageAlt={item.title + ' ثبت نشده '} 
                            imageClass={'w-full h-full object-contain'}
                        />
                        {/* <img 
                            className='w-full h-full object-contain'
                            src={process.env.REACT_APP_API_ADDRESS + 'tankPhotos' + item.value} 
                            alt={item.title + ' ثبت نشده '}
                        />   */}
                    </label>
                    <p className='absolute w-full h-fit p-2 text-gray-100 text-sm text-center bottom-0 rounded-b-lg bg-gradient-to-r from-indigo-500 to-blue-500 cursor-pointer'>{item.title}</p>  
                </div>
            ))
        )
    }

    const RegisteredDate = () => {
        function handlerRegisteredDate(item) {
            setSelectedDateId(item.photo_id)
            setSelectedDate(ConvertDateToJalali(item.createAt))
        }
        return(
            deliveryDate.map((item, key)=>(
                <p 
                    key={key}
                    onClick={()=>handlerRegisteredDate(item)}
                    className={`${selectedDateId === item.photo_id ? 'border-green-600 text-green-900 ': 'border-zinc-400 '} px-8 py-2 text-sm rounded-lg border-2 bg-transparent hover:shadow-md hover:bg-zinc-50 text-right cursor-pointer hover:scale-[1.02]`}
                >
                    {ConvertDateToJalali(item.createAt)}
                </p>
            ))
        )
    }
    
    return (
        <div>
            <div className='m-r-nav'>
                {loader && <FullLoader />}
                <Admin_Header title={`${params.name} عکس های تانک`} desc='برای نمایش عکس های تانک ابتدا تاریخ مورد نظر را انتخاب کنید' backTo={'/admin/tankerPhotosList'}/>
                <div className='center flex-wrap flex-row-reverse p-4 gap-10'>
                    <div className='w-full flex items-end justify-center sm:justify-start flex-row-reverse flex-wrap gap-4' >
                        <RegisteredDate />
                    </div>
                    {
                        selectedDateId ?
                            <>
                                <TankerPhotos />

                                {
                                    localStorage.getItem('tank22to46') !== 'مدیر تانک 22 تا 46' ?
                                        <div className='w-full center'>
                                            <DangerBtn onClick={()=>setOpenDeleteModal(true)} cssClass={'m-8 mt-0'}>
                                                <p className='px-4 py-2'>حذف عکس های تاریخ {selectedDate}</p>
                                            </DangerBtn>
                                        </div>
                                    :
                                        null
                                }
                            </>
                        :
                            !deliveryDate[0] ? <p> عکسی ثبت نشد</p> : <p>لطفا تاریخ مد نظر را انتخاب کنید</p> 
                    }
                </div>
            </div>

            <Modal 
                title='حذف عکس ها '
                body={"میخوای عکس های تاریخ " + selectedDate + " حذف بشه؟ "}
                icon='warning'
                onOpen={openDeleteModal}
                btnCancel={setOpenDeleteModal}
                btnDelete={handlerDeleteQuery}
            />
        </div>
    )
}

export default Admin_TankerPhotosListDetail
