export const defaultStateVal = 'انتخاب نشده';
export const serverLoginCount = 5;
export const serverDeactivateLogintime = 3;

export const MemberMenuArrList = [
    {title:'انتخاب تانکر ', color:'from-red-500 to-orange-500', href:'/member/tanks'},
    {title:'صفحه اصلی', color:'from-red-500 to-orange-500', href:'/member/home'},
    {title:'ثبت تست تانک', color:'from-red-500 to-orange-500', href:'/member/recordTankTest'},
    {title:'لیست تست تانک ها', color:'from-amber-300 to-yellow-600', href:'/member/tankTestList'},
    {title:'ثبت گزارش تحویل مخزن', color:'from-emerald-500 to-emerald-900', href:'/member/deliveryReport'},
    {title:'لیست گزارشات تحویل', color:'from-orange-400 to-rose-500', href:'/member/deliveryReportDetail'},
    {title:'ثبت عکس های تانک', color:'from-amber-300 to-yellow-600', href:'/member/addTankerPhotos'},
    {title:'نمایش عکس های تانک', href:'/member/tankerPhotosList'},
    {title:'ثبت تعمیرات تانک', color:'from-violet-500 to-pink-500', href:'/member/repairForm'},
    {title:'لیست گزارشات تعمیرات تانک', color:'from-emerald-400 to-cyan-400', href:'/member/repairFormList'},
    {title:'ثبت گزارش بارگیری پالایشگاه', color:'from-pink-500 to-violet-500', href:'/member/refineryLoadingForm'},
    {title:'لیست گزارشات بارگیری از پالایشگاه', color:'from-cyan-800 to-emerald-800', href:'/member/refineryLoadingList'},
    {title:'لیست راننده ها', color:'from-orange-400 to-rose-500', href:'/member/driversList'},
    {title:'خروج از حساب کاربری', href:'/member'},
]

export const AdminMenuArrList = [
    {title:'صفحه اصلی', href:'/admin/home'},
    {title:'لیست تانکر ها', href:'/admin/tankersList', color:'from-orange-400 to-rose-500'},
    {title:'لیست راننده ها', href:'/admin/driversList', color:'from-orange-400 to-rose-500'},
    {title:'لیست تست تانک ها', href:'/admin/tankTestList', color:'from-orange-400 to-rose-500'},
    {title:'لیست گزارشات تحویل', href:'/admin/deliveryReport', color:'from-orange-400 to-rose-500'},
    {title:'عکس های تانک', href:'/admin/tankerPhotosList', color:'from-orange-400 to-rose-500'},
    {title:'لیست تعمیرات تانک', href:'/admin/repairFormList', color:'from-orange-400 to-rose-500'},
    {title:'لیست گزارشات بارگیری از پالایشگاه', href:'/admin/refineryLoadingList', color:'from-orange-400 to-rose-500'},
    {title:'کارکنان', href:'/admin/userAccountsList', color:'from-orange-400 to-rose-500'},
    {title:'گزارش ورود به سیستم', href:'/admin/loginReport', color:'from-orange-400 to-rose-500'},
    {title:'پشتیبانی', href:'/admin/contactUs', color:'from-orange-400 to-rose-500'},
    {title:'خروج از حساب کاربری', href:'/admin'},
]

export const AdminMenuArrListWithoutAddUser = [
    {title:'صفحه اصلی', href:'/admin/home'},
    {title:'لیست تانکر ها', href:'/admin/tankersList', color:'from-orange-400 to-rose-500'},
    {title:'لیست راننده ها', href:'/admin/driversList', color:'from-orange-400 to-rose-500'},
    {title:'لیست تست تانک ها', href:'/admin/tankTestList', color:'from-orange-400 to-rose-500'},
    {title:'لیست گزارشات تحویل', href:'/admin/deliveryReport', color:'from-orange-400 to-rose-500'},
    {title:'عکس های تانک', href:'/admin/tankerPhotosList', color:'from-orange-400 to-rose-500'},
    {title:'لیست تعمیرات تانک', href:'/admin/repairFormList', color:'from-orange-400 to-rose-500'},
    {title:'لیست گزارشات بارگیری از پالایشگاه', href:'/admin/refineryLoadingList', color:'from-orange-400 to-rose-500'},
    {title:'گزارش ورود به سیستم', href:'/admin/loginReport', color:'from-orange-400 to-rose-500'},
    {title:'پشتیبانی', href:'/admin/contactUs', color:'from-orange-400 to-rose-500'},
    {title:'خروج از حساب کاربری', href:'/admin'},
]

export const MemberHomeArrList = [
    {title:'ثبت تاریخ تست تانک', color:'from-pink-500 to-rose-500', href:'/member/recordTankTest'},
    {title:'لیست تست تانک', color:'from-amber-500 to-pink-500', href:'/member/tankTestList'},
    {title:'ثبت گزارش تحویل مخزن', color:'from-amber-300 to-yellow-600', href:'/member/deliveryReport'},
    {title:'لیست گزارشات تحویل', color:'from-orange-700 to-rose-300', href:'/member/deliveryReportDetail'},
    {title:'ثبت عکس های تانکر', color:'from-cyan-500 to-blue-500', href:'/member/addTankerPhotos'},
    {title:'نمایش عکس های تانکر', color:'from-fuchsia-500 to-pink-500', href:'/member/tankerPhotosList'},
    {title:'ثبت تعمیرات تانک', color:'from-emerald-400 to-cyan-400', href:'/member/repairForm'},
    {title:'لیست گزارشات تعمیرات تانک', color:'from-amber-300 to-yellow-600', href:'/member/repairFormList'},
    {title:'ثبت گزارش بارگیری پالایشگاه', color:'from-pink-500 to-violet-500', href:'/member/refineryLoadingForm'},
    {title:'لیست گزارشات بارگیری از پالایشگاه', color:'from-blue-800 to-emerald-300', href:'/member/RefineryLoadingList'},
    {title:'لیست راننده ها', color:'from-orange-300 to-rose-600', href:'/member/driversList'},

]


export const LoginListArray = [
    {
        "name": "امیر",
        "family": "حیاتی",
        "number": "09339098855",
        "loginDate": "25/03/1402",
        "loginDay": "دوشنبه",
        "loginHours": "12:34"
    },
    {
        "name": "مهدی",
        "family": "جمالی",
        "number": "0945453454",
        "loginDate": "01/12/1401",
        "loginDay": "چهار شنبه",
        "loginHours": "10:34"
    },
]

export const TankTestArray = [
    {tanker:'t2', createDate:'01/12/1399',creatorName:'حیاتی امیر حسین', img:''},
    {tanker:'t22', createDate:'10/10/1399',creatorName:'حیاتی عارف', img:''},
    {tanker:'t1', createDate:'01/04/1400',creatorName:'عراقی حسن', img:''},
    {tanker:'t4', createDate:'03/05/1402',creatorName:'محمد دوست علی', img:''},
    {tanker:'t2', createDate:'20/02/1395',creatorName:'بیجاری حسن', img:''},
    {tanker:'t2', createDate:'29/10/1390',creatorName:'خامسان داوود', img:''},
]

// export const TankersListArray = [
//     {title:"نام", eName:'n'}, 
//     {title:"نام خانوادگی", eName:'f'}, 
//     {title:"شماره تلفن", eName:'number'}, 
//     {title:"کد تانکر", eName:'tankCode'}, 
//     {title:"شماره ماشین", eName:'carNumber'}
// ]

export const simpleDate = [
    {
        "Status": "1",
        "Message": "3 records found.",
        "Data": [
            {
                "name": "Angioplasty",
                "family": "IujyQXg8KZg8asLvK/FS7g==",
                "sig": "",
                "address": "abdsjh dsgjhgdd gsjjkdkds dddsjkhdj djdjkdh dshjdkhkd dhdhdk sjkghdjkdhkhd dhkdkhdkjhd hkjhkdhd kdjkdjdkjd dhkdkjhdjh ddkdkhd dhdhhd ",
                "number": "",
                "ghardadHa": "p6r4bAAI4ybdJySoV+PqGQ==",
                "carNumber": "p6r4bAAI4ybdJySoV+PqGQ==",
                "tankNumber": "p6r4bAAI4ybdJySoV+PqGQ==",
                "hesabNumber": "p6r4bAAI4ybdJySoV+PqGQ==",
            },
            {
                "name": "Angioplasty",
                "family": "IujyQXg8KZg8asLvK/FS7g==",
                "sig": "",
                "address": "abdsjh dsgjhgdd gsjjkdkds dddsjkhdj djdjkdh dshjdkhkd dhdhdk sjkghdjkdhkhd dhkdkhdkjhd hkjhkdhd kdjkdjdkjd dhkdkjhdjh ddkdkhd dhdhhd ",
                "number": "",
                "ghardadHa": "p6r4bAAI4ybdJySoV+PqGQ==",
                "carNumber": "p6r4bAAI4ybdJySoV+PqGQ==",
                "tankNumber": "p6r4bAAI4ybdJySoV+PqGQ==",
                "hesabNumber": "p6r4bAAI4ybdJySoV+PqGQ==",
            },
        ]
    }
]

export const loginText = 'لطفا مجددا لاگین کنید.'
export const uploadError = 'خطا در ارسال فرم. لطفا وضعیت اینترنت خود را چک کنید. در صورتی که مشکل برطرف نشد با پشتیبانی تماس حاصل فرمایید'

export const cityArray = [
    {title:'تهران', eName:'tehran'},
    {title:'اراک', eName:'arak'},
    {title:'آبادان', eName:'abadan'},
    {title:'عسلویه', eName:'asaluyeh'},
    {title:'اصفهان', eName:'esfahan'},
  ]
  
export const partArray = [
    {title:'یکم', eName:'one'},
    {title:'دوم', eName:'two'},
    {title:'سوم', eName:'three'},
    {title:'چهارم', eName:'four'},
    {title:'پنجم', eName:'five'},
    {title:'ششم', eName:'six'},
    {title:'هفتم', eName:'seven'},
    {title:'هشتم', eName:'eight'},
    {title:'نهم', eName:'nine'},
    {title:'دهم', eName:'ten'},
    {title:'یازدهم', eName:'eleven'},
    {title:'دوازدهم', eName:'Twelve'},
]

export const selecedTonnageArray = [
    {title:300, eName:'300'},
    {title:500, eName:'500'},
    {title:1000, eName:'1000'},
    {title:1500, eName:'1500'},
]