import React,{ useState, useEffect } from 'react';
import Admin_Header from './header.tsx'
import { AdminTableList } from '../../components/adminTableList.tsx';
import axios from 'axios';
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText } from '../../array/array.ts';
import { ConvertPersianNumToEnglishNum } from '../../components/common.tsx';
import moment from 'moment-jalaali'

const tableHead = [
  {pName:"کد تانک", eName:"tankCode", type:"string/number", sorted:true},
  {pName:"تاریخ ثبت تست", eName:"testDate", type:"date", sorted:true},
  {pName:"تاریخ پایان تست", eName:"expireDate", type:"date", sorted:true},
]

const Admin_SystemWarning = () => {
  const navigate = useNavigate();
  const presentDate = ConvertPersianNumToEnglishNum(new Date().toLocaleDateString('fa-IR'));
  // ----- 20 day -----
  const nextWeekDate = ConvertPersianNumToEnglishNum(new Date(new Date().getTime() + 20 * 24 * 60 * 60 * 1000).toLocaleDateString('fa-IR'));

  const [loader, setLoader] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])
  
  useEffect(() => {
    setLoader(true);
    const fetchData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_ADDRESS}users/tankTestExireList`, 
            {
              presentDate:moment(presentDate).format('YYYY/MM/DD'),
              nextWeekDate:moment(nextWeekDate).format('YYYY/MM/DD')
            },
            {
                headers: {
                    'auth-token': Cookies.get('token'),
                    'auth-id': Cookies.get('id')
                }
            });
            setData(response.data);
            setLoader(false);
        } catch (error) {
            // console.error('Error fetching tanks:', error);
            setLoader(false); // Hide loader after fetching data
            alert(loginText)
            navigate('/member')
        }
    };

    fetchData();
  }, []);

  return (
    <div>
      {loader && <FullLoader />}
      <Admin_Header title='پیغام های اضطراری' desc='لیست تانک هایی ک تاریخ پایان تست آن ها نزدیک است'/>

      <div className='m-r-nav px-4 mb-8 sm:px-8'>
        
        <AdminTableList
          TableList={data} 
          tableHeadParams={tableHead} 
        />
      </div>
    </div>
  )
}

export default Admin_SystemWarning
