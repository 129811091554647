import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AdminTableList } from '../components/adminTableList.tsx';
import { FaArrowLeft } from 'react-icons/fa';
import { HandlePrint } from '../components/common.tsx';

const PrintList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const DataList = location.state.params.array;
    const DataListHead = location.state.params.listHead;
    const pageTitle = location.state.params.pageHeader;
    const pageDescription = location.state.params.pageDescription;
    const date = new Date().toLocaleDateString('fa-IR');

    return (
        !location.state.params ?
            navigate(-1)
        :
            <section id='printList'>

                <a onClick={()=>navigate(-1)} className='p-0 sm:p-0 cursor-pointer hover:underline underline-offset-4 flex flex-row gap-2 '>
                    <FaArrowLeft className=' text-zinc-800 '/>
                    <p className='text-sm text-zinc-800 '>برگشت به صفحه قبل</p>
                </a>

                <div id={'printFinalTank'} className={'w-[1000px]  border-2 border-black rounded-md p-4 space-y-2'}>
                    <div className=' flex flex-row items-start justify-between '>
                        <button onClick={HandlePrint} className={"w-1/8 text-sm px-4 py-2 text-white bg-green-700 hover:bg-green-600 rounded hover:scale-[1.02] "}>
                            چاپ فرم
                        </button>
                        <div className='w-6/8 flex flex-col'>
                            <p className='text-center text-2xl font-black'>{pageTitle}</p>
                            {
                                pageDescription &&
                                    <p className='text-center py-2'>{pageDescription}</p>
                            }
                        </div>
                        <p className='text-xs w-1/8 pt-2'>{date} :تاریخ</p>
                    </div>

                    <AdminTableList 
                        TableList={DataList} 
                        tableHeadParams={DataListHead}
                        itemsPerPageCount={DataList.length+1}
                        printPage
                    />
                </div>
            </section>
    );
    
}

export default PrintList
