import React, { useEffect, useState} from 'react'
import Member_Header from './header.tsx'
import { SuccessBtn } from '../../components/btn.tsx'
import { DatePicketComponent, HandlerChangeDigitToEn, CustomInput, CustomTextArea, HandlerNavigateTankCode, DropDownDefualtValue, ConvertPersianNumToEnglishNum } from '../../components/common.tsx'
import { Modal } from '../../components/modal.tsx'
import axios from 'axios'
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText, uploadError } from '../../array/array.ts';
import persianJs from 'persianjs'

const RepairForm = () => {
  const navigate = useNavigate();
  const tankCode = localStorage.getItem("tankCode");
  const tankId = localStorage.getItem("tankId");

  const [loader, setLoader] = useState<boolean>(false)

  const [openSubmitModal, setOpenSubmitModal] = useState<boolean>(false)

  const [selectedTypeList, setSelectedTypeList] = useState<string[]>([])
  const [selectedType, setSelectedType] = useState<string>('')
  const [selectedTypeId, setSelectedTypeId] = useState<number>()
  const [selectedTypeShowBrand, setSelectedTypeShowBrand] = useState<number>(0)
  const [brand, setBrand] = useState<string>('')
  const [replacementDate, setReplacementDate] = useState<string>()
  const [validityDate, setValidityDate] = useState<string>()
  const [price, setPrice] = useState<number>()
  const [factorNumber, setFactorNumber] = useState<number>()
  const [storeName, setStoreName] = useState<string>('')
  const [sellerName, setSellerName] = useState<string>('')
  const [sellerFamily, setSellerFamily] = useState<string>('')
  const [description, setDescription] = useState<string>('')


  useEffect(() => {
    setLoader(true); // Show loader while fetching data
    
    const fetchData = async () => {
      axios.get(`${process.env.REACT_APP_API_ADDRESS}users/repairFormTypes`,
      {
        headers: {
            'auth-token': Cookies.get('token'),
            'auth-id': Cookies.get('id')
        }
      })
      .then(response => {
        setLoader(false); // Hide loader after fetching data
        setSelectedTypeList(response.data)
        // console.log(response.data)
      })
      .catch(error => {
        // console.error('Error:', error);
        alert(loginText)
        navigate('/member')
        setLoader(false); // Hide loader in case of an error
      });
    };

    fetchData();
  }, []);
  
  useEffect(() => {
    if(selectedType){
      const findRepairTypeId = selectedTypeList.find((item)=>item.title === selectedType)
      setSelectedTypeId(findRepairTypeId ? Number(findRepairTypeId.repairType_id) : 0)
      setSelectedTypeShowBrand(findRepairTypeId.showBrand == 1 ? 1 : 0)
    }
  }, [selectedType]);
  
  function handlerSubmitModal() {
    if(!selectedTypeId){
      alert('لطفا نوع تعمیرات را انتخاب کنید')
    }else{
      if(replacementDate && factorNumber && storeName && sellerName && sellerFamily && price){
        setOpenSubmitModal(!openSubmitModal)
      }else{
        alert('لطفا فیلد های تاریخ تعویض, شماره فاکتور, نام فروشگاه, فروشنده و مبلغ را وارد کنید.')
      }
    }
  }
  
  function handlerSubmitBtn() {
    setOpenSubmitModal(false)
    setLoader(true)
    
    axios.post(`${process.env.REACT_APP_API_ADDRESS}users/insertRepairForm`, 
      {
        tank_id: tankId,
        user_id: Cookies.get('id'),
        repairType_id: selectedTypeId,
        brand: brand?brand:null,
        replacementDate: HandlerChangeDigitToEn(replacementDate),
        validityDate: HandlerChangeDigitToEn(validityDate),
        factorNumber: ConvertPersianNumToEnglishNum(factorNumber),
        storeName: storeName,
        sellerName: sellerName,
        sellerFamily: sellerFamily,
        price: ConvertPersianNumToEnglishNum(price),
        description: description
      },
      {
        headers: {
            'auth-token': Cookies.get('token'),
            'auth-id': Cookies.get('id')
        }
      })
      .then(response => {
          // console.log('Response:', response.data);
          setLoader(false)
          alert('فرم به درستی ثبت شد . برای نمایش لیست ثبت شده به صفحه لیست گزارش تعمیرات تانک مراجعه کنید .')
          setSelectedType('')
          setSelectedTypeId(undefined)
          setBrand('')
          setReplacementDate(undefined)
          setValidityDate(undefined)
          setPrice(undefined)
          setFactorNumber(undefined)
          setStoreName('')
          setSellerName('')
          setSellerFamily('')
          setDescription('')
      })
      .catch(error => {
          // console.error('Error:', error);
          // alert(loginText)
          // navigate('/member')
          alert(uploadError)
          setLoader(false); // Hide loader in case of an error
      }
    );
  }
  
  return (
    <div className='m-r-nav'>
      {loader && <FullLoader />}
      <Member_Header tankName={tankCode+' ثبت تعمیرات '}/>

      {
        tankCode ?
          <div className='px-8 pb-10 flex flex-row-reverse items-center justify-center flex-wrap gap-4 sm:gap-8 w-full'>
            <div className='w-full center' dir='rtl'>
              <DropDownDefualtValue state={selectedType} setState={setSelectedType} dropTitle={selectedType ? undefined :'لطفا نوع تعمیرات را انتخاب کنید'} list={selectedTypeList}/>
            </div>
            {
              selectedTypeShowBrand == 1 ?
                CustomInput({state:brand, setState:setBrand, label:`برند ${selectedType}`, width:30, length:50})
              :
                null
            }
            <DatePicketComponent title="تاریخ تعویض" state={replacementDate} setState={setReplacementDate} />
            <DatePicketComponent title="تاریخ اعتبار" state={validityDate} setState={setValidityDate} />
            {CustomInput({state:factorNumber, setState:setFactorNumber, label:'شماره فاکتور', width:15, length:15})}
            {CustomInput({state:storeName, setState:setStoreName, label:'نام فروشگاه', width:25, length:50})}
            {CustomInput({state:sellerName, setState:setSellerName, label:'نام فروشنده', width:25, length:50})}
            {CustomInput({state:sellerFamily, setState:setSellerFamily, label:'فامیل فروشنده', width:25, length:50})}
            {CustomInput({state:price, setState:setPrice, label:`قیمت ${price ? persianJs(ConvertPersianNumToEnglishNum(price)).digitsToWords().toString() : 'به'} تومان`, width:35, length:10})}
            {CustomTextArea({state:description, setState:setDescription, label:'توضیحات', width:50, length:1000, height:6})}
            
            <div className='w-full center'>
              <SuccessBtn onClick={handlerSubmitModal}>
                ثبت اطلاعات
              </SuccessBtn>
            </div>
          </div>
        :
          <HandlerNavigateTankCode/>
      }   
      
      <Modal 
        icon="success"
        title="ثبت نهایی فرم"
        body="آیا از صحت اطلاعات فوق اطمینان دارید."
        btnCancel={setOpenSubmitModal}
        btnSubmit={handlerSubmitBtn}
        onOpen={openSubmitModal}
      />
    </div>
  )
}

export default RepairForm
