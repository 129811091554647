import React, { useRef, useState, useEffect} from 'react'
import { Modal } from '../../components/modal.tsx'
import { SuccessBtn } from '../../components/btn.tsx'
import { ItemContainer, DropDownThreeStatus, DropDownDefualtValue, DropDownTwoStatus, DropDownPercentStatus, DatePicketComponent, CustomInput, CustomTextArea, ConvertPersianNumToEnglishNum, HandlerChangeDigitToEn } from '../../components/common.tsx'
import { defaultStateVal, uploadError } from '../../array/array.ts'
import axios from 'axios'
import { FullLoader } from '../../components/loader.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText } from '../../array/array.ts';
import Admin_Header from './header.tsx';

const defaultFenderValue = [
    {title:'ندارد'},
    {title:'دارد و سالم'},
    {title:'دارد و یک گلگیر معیوب'},
    {title:'دارد و دو گلگیر معیوب'},
    {title:'دارد و سه گلگیر معیوب'}
]

const defaultBoltAndNutValue = [
    {title:'کامل'},
    {title:'ناقص - یکی ندارد'},
    {title:'ناقص - دوتا ندارد'},
    {title:'ناقص - سه تا ندارد'},
]

const defaultrimValue = [
    {title:'سالم'},
    {title:'معیوب - یک رینگ شکسته'},
    {title:'معیوب - دو رینگ شکسته'},
    {title:'معیوب - سه رینگ شکسته'},
    {title:'معیوب - چهار رینگ شکسته'},
    {title:'معیوب - پنج رینگ شکسته'},
    {title:'معیوب - شیش رینگ شکسته'},
]

const defaultFaucetCapValue = [
    {title:'ندارد'}, 
    {title:'دارد و ۱ عدد'}, 
    {title:'دارد و ۲ عدد'}, 
    {title:'دارد و ۳ عدد'}
]

const tableHead = [
    {pName:"تلفن", eName:"number", type:"number"},
    {pName:"نام خانوادگی", eName:"family", type:"string", sorted:true},
    {pName:"نام", eName:"name", type:"string", sorted:true},
]

const Admin_EditDeliveryReport = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const delivery_id = location.state.delivery_id;
    const tankCode = localStorage.getItem('tankCode')

    const [loader, setLoader] = useState<boolean>(false)
  
    const [companyName, setCompanyName] = useState<string>('نام کمپانی')
    const [chassisNumber, setChassisNumber] = useState<string>('شماره شاسی')
    const [deliveryDate, setDeliveryDate] = useState<string>()
    const [contractNumber, setContractNumber] = useState<string>('contractNumber')
    const [fName, setFName] = useState<string>('fName')
    const [lName, setLName] = useState<string>('lName')
    const [driverNumber, setDriverNumber] = useState<number>(1)
    const [nationalCode, setNationalCode] = useState<number>(2)
    const [fromState, setFromState] = useState<string>('fromState')

    const [tankStatus, setTankStatus] = useState<string>('tankStatus')
    const [valveBox, setValveBox] = useState<string>('valveBox')
    const [rightFender, setRightFender] = useState<string>('rightFender')
    const [leftFender, setLeftFender] = useState<string>('leftFender')
    const [rearSideLights, setRearSideLights] = useState<string>('rearSideLights')
    const [powerPlug, setPowerPlug] = useState<string>('powerPlug')
    const [spreadMud, setSpreadMud] = useState<string>('spreadMud')

    const [spareStrap, setSpareStrap] = useState<string>('spareStrap')
    const [faucetConversion, setFaucetConversion] = useState<string>('faucetConversion')
    const [faucetCap, setFaucetCap] = useState<string>('faucetCap')

    const [safetyValve, setSafetyValve] = useState<string>('safetyValve')
    const [ladder, setLadder] = useState<string>('ladder')
    const [tankerJack, setTankerJack] = useState<string>('tankerJack')
    const [brakeRatchets, setBrakeRatchets] = useState<string>('brakeRatchets')
    const [rearBumper, setRearBumper] = useState<string>('rearBumper')

    const [machine, setMachine] = useState<string>('machine')
    const [rim, setRim] = useState<string>('rim')
    const [boltAndNut, setBoltAndNut] = useState<string>('boltAndNut')
    const [earthChain, setEarthChain] = useState<string>('earthChain')
    const [spare, setSpare] = useState<string>('spare')
    const [waterTank, setWaterTank] = useState<string>('waterTank')
    const [fireBox, setFireBox] = useState<string>('fireBox')
    const [fireCapsule, setFireCapsule] = useState<string>('fireCapsule')
    const [fifthGear, setFifthGear] = useState<string>('fifthGear')
    const [barometer, setBarometer] = useState<string>('barometer')
    const [thermometer, setThermometer] = useState<string>('thermometer')
    const [rotorGage, setRotorGage] = useState<string>('rotorGage')
    const [rotorGageDisc, setRotorGageDisc] = useState<string>('rotorGageDisc')
    const [valvePercentageGauge, setValvePercentageGauge] = useState<string>('valvePercentageGauge')
    const [fastClosingSystem, setFastClosingSystem] = useState<string>('fastClosingSystem')
    const [fullDesc, setFullDesc] = useState<string>('fullDesc')

    const [brakePad, setBrakePad] = useState<number>(50)
    const [imageSignature, setImageSignature] = useState<string>(); 
    const [openSubmitModal, setOpenSubmitModal] = useState<boolean>(false)

    const [wheelPercent1, setWheelPercent1] = useState<number>(50)
    const [wheelPercent2, setWheelPercent2] = useState<number>(50)
    const [wheelPercent3, setWheelPercent3] = useState<number>(50)
    const [wheelPercent4, setWheelPercent4] = useState<number>(50)
    const [wheelPercent5, setWheelPercent5] = useState<number>(50)
    const [wheelPercent6, setWheelPercent6] = useState<number>(50)

    const [wheelName1, setWheelName1] = useState<string>('wheelName1')
    const [wheelName2, setWheelName2] = useState<string>('wheelName2')
    const [wheelName3, setWheelName3] = useState<string>('wheelName3')
    const [wheelName4, setWheelName4] = useState<string>('wheelName4')
    const [wheelName5, setWheelName5] = useState<string>('wheelName5')
    const [wheelName6, setWheelName6] = useState<string>('wheelName6')
    const sigCanvas = useRef({});

    function handlerSubmitModal() {
        if(companyName === '' 
            || chassisNumber === '' || !deliveryDate || !contractNumber || fName === '' 
            || lName === '' || !driverNumber || !nationalCode
            || fromState === '' || tankStatus === '' || valveBox === defaultStateVal 
            || rightFender === defaultStateVal || leftFender === defaultStateVal || rearSideLights === defaultStateVal ||
            powerPlug === defaultStateVal || spareStrap === defaultStateVal || faucetConversion === defaultStateVal ||
            faucetCap === defaultStateVal || safetyValve === defaultStateVal || ladder === defaultStateVal ||
            tankerJack === defaultStateVal || brakeRatchets === defaultStateVal || rearBumper === defaultStateVal
            || machine === defaultStateVal || rim === defaultStateVal || boltAndNut === defaultStateVal ||
            earthChain === defaultStateVal || spare === defaultStateVal || waterTank === defaultStateVal ||
            fireBox === defaultStateVal || fireCapsule === defaultStateVal || fifthGear === defaultStateVal || barometer === defaultStateVal ||
            thermometer === defaultStateVal || rotorGageDisc === defaultStateVal || valvePercentageGauge === defaultStateVal ||
            fastClosingSystem === defaultStateVal || imageSignature === undefined || wheelName1 === '' || wheelName2 === '' || wheelName3 === '' || wheelName4 === '' || wheelName5 === '' || wheelName6 === ''
        ){
            alert('لطفا تمامی فیلد هارا کامل کنید.')
        }else{
            setOpenSubmitModal(!openSubmitModal)
        }
    }
    
    useEffect(() => {
        setLoader(true);

        const fetchDeliveryData = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/deliveryList`, 
                    {
                        delivery_id: delivery_id
                    } 
                    ,{
                        headers: {
                            'auth-token': Cookies.get('token'),
                            'auth-id': Cookies.get('id')
                        }
                    });
                    const data = response.data[0];
                    
                    setCompanyName(data.companyName);
                    setChassisNumber(data.chassisNumber);
                    setDeliveryDate(data.deliveryDate);
                    setContractNumber(data.contractNumber);
                    setFromState(data.fromState);
                    setFullDesc(data.fullDesc);
                    setFName(data.driverName);
                    setLName(data.driverFamily);
                    setImageSignature(data.driverSigImg);  
                    setTankStatus(data.tankStatus);
                    setValveBox(data.valveBox);
                    setRightFender(data.rightFender);
                    setLeftFender(data.leftFender);
                    setRearSideLights(data.rearSideLights);
                    setPowerPlug(data.powerPlug);
                    setSpreadMud(data.spreadMud);
                    setSpareStrap(data.spareStrap);
                    setFaucetConversion(data.faucetConversion);
                    setFaucetCap(data.faucetCap);
                    setSafetyValve(data.safetyValve);
                    setLadder(data.ladder);
                    setTankerJack(data.tankerJack);
                    setBrakeRatchets(data.brakeRatchets);
                    setRearBumper(data.rearBumper);
                    setMachine(data.machine);
                    setRim(data.rim);
                    setBoltAndNut(data.boltAndNut);
                    setEarthChain(data.earthChain);
                    setSpare(data.spare);
                    setWaterTank(data.waterTank);
                    setFireBox(data.fireBox);
                    setFifthGear(data.fifthGear);
                    setFireCapsule(data.fireCapsule);
                    setBarometer(data.barometer);
                    setThermometer(data.thermometer);
                    setRotorGage(data.rotorGage);
                    setRotorGageDisc(data.rotorGageDisc);
                    setValvePercentageGauge(data.valvePercentageGauge);
                    setFastClosingSystem(data.fastClosingSystem);
                    setBrakePad(data.brakePad);
                    setWheelPercent3(data.wheelPercent3);
                    setWheelName3(data.wheelName3);
                    setWheelPercent2(data.wheelPercent2);
                    setWheelName2(data.wheelName2);
                    setWheelPercent1(data.wheelPercent1);
                    setWheelName1(data.wheelName1);
                    setWheelPercent6(data.wheelPercent6);
                    setWheelName6(data.wheelName6);
                    setWheelPercent5(data.wheelPercent5);
                    setWheelName5(data.wheelName5);
                    setWheelPercent4(data.wheelPercent4);
                    setWheelName4(data.wheelName4);

                    setLoader(false);
            } catch (error) {
                // console.error('Error fetching tanks:', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate('/admin')
            }
        };
    
        fetchDeliveryData();
    }, []);
    
    const handlereditBtn = async () => {
        setOpenSubmitModal(false)
        setLoader(true)

        axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/updateDeliveryItem`, 
            {
                delivery_id: delivery_id,
                companyName: companyName,
                chassisNumber: ConvertPersianNumToEnglishNum(chassisNumber),
                deliveryDate: HandlerChangeDigitToEn(deliveryDate),
                contractNumber: ConvertPersianNumToEnglishNum(contractNumber),
                driverName: fName,
                driverFamily: lName,
                fromState: fromState,
                tankStatus: tankStatus,
                valveBox: valveBox,
                rightFender: rightFender,
                leftFender: leftFender,
                rearSideLights: rearSideLights,
                powerPlug: powerPlug,
                spreadMud: spreadMud,
                spareStrap: spareStrap,
                faucetConversion: faucetConversion,
                faucetCap: faucetCap,
                safetyValve: safetyValve,
                ladder: ladder,
                tankerJack: tankerJack,
                brakeRatchets: brakeRatchets,
                rearBumper: rearBumper,
                machine: machine,
                rim: rim,
                boltAndNut: boltAndNut,
                earthChain: earthChain,
                spare: spare,
                waterTank: waterTank,
                fireBox: fireBox,
                fireCapsule: fireCapsule,
                fifthGear: fifthGear,
                barometer: barometer,
                thermometer: thermometer,
                rotorGage: rotorGage,
                rotorGageDisc: rotorGageDisc,
                valvePercentageGauge: valvePercentageGauge,
                fastClosingSystem: fastClosingSystem,
                fullDesc: fullDesc,
                brakePad: brakePad,
                wheelPercent1: wheelPercent1,
                wheelPercent2: wheelPercent2,
                wheelPercent3: wheelPercent3,
                wheelPercent4: wheelPercent4,
                wheelPercent5: wheelPercent5,
                wheelPercent6: wheelPercent6,
                wheelName1: wheelName1,
                wheelName2: wheelName2,
                wheelName3: wheelName3,
                wheelName4: wheelName4,
                wheelName5: wheelName5,
                wheelName6: wheelName6,
            },
            {
                headers: {
                    'auth-token': Cookies.get('token'),
                    'auth-id': Cookies.get('id')
                }
            })
            .then(response => {
                // console.log('Response:', response.data);
                setLoader(false)
                alert('فرم به درستی ویرایش شد.')
                // window.open(`/member/printableDeliveryReport?data=${response.data}`, '_blank');
                navigate(-1)
            })
            .catch(error => {
                // console.error('Error:', error);
                setLoader(false)
                alert(uploadError)
            }
        );
    };

    return (
        <div className='m-r-nav'>
            {loader && <FullLoader />}
            <Admin_Header title={tankCode+'ویرایش گزارش تحویل مخزن تانک '} backTo={'/admin/deliveryReport/'+tankCode}/>
            
            <div className='mx-4 sm:p-2 -mt-10'>
                <p className='text-sm text-gray-600 p-4 pt-6 pb-2' dir='rtl'>
                    <span className='text-gray-800 pt-8'> نکته : </span>
                    در این صفحه فیلد های مربوط به راننده (فیلد های نام - نام خانوادگی - شماره همراه و کد ملی) ویرایش نمیشوند. اگر اطلاعات راننده مشکل دارد به صفحه لیست راننده ها مراجعه و اطلاعات مربوط به راننده را ویرایش کنید.
                </p>

                <ItemContainer>
                    {CustomInput({state:companyName, setState:setCompanyName, label:'ساخت کارخانه', width:30, length:50})}
                    {CustomInput({state:chassisNumber, setState:setChassisNumber, label:'شماره شاسی', width:15, length:15})}
                    <DatePicketComponent title="در تاریخ" state={deliveryDate} setState={setDeliveryDate} />
                    {CustomInput({state:contractNumber, setState:setContractNumber, label:'شماره قرارداد ', width:15, length:10})}
                </ItemContainer>

                {/* <p className='text-center p-6 pt-0'>
                    <span>برای انتخاب راننده ابتدا بر روی دکمه انتخاب راننده کلیک کرده و از لیست باز شده راننده مورد نظر را انتخاب کنید</span>
                    <br/>
                    <span>در صورتی که راننده در لیست یافت نشد میتوانید در فیلد های نام و نام خانوادگی و شماره تلفن بطور دستی اطلاعات راننده را وارد کنید</span>
                </p>
                <div className='w-full center'>
                    <PrimaryBtn onClick={handlerSelectDriver} cssClass={'px-10 py-2'}>
                        <p className='text-sm font-bold'>انتخاب راننده از لیست</p>
                    </PrimaryBtn>
                </div> */}

                <ItemContainer>
                    {CustomInput({state:fName, setState:setFName, label:'نام راننده', width:30, length:50, disable:true})}
                    {CustomInput({state:lName, setState:setLName, label:'نام خانوادگی راننده', width:30, length:50, disable:true})}
                    {CustomInput({state:driverNumber, setState:setDriverNumber, label:'شماره تلفن راننده', width:11, length:11, disable:true})}
                    {CustomInput({state:nationalCode, setState:setNationalCode, label:'کد ملی راننده', width:11, length:10, disable:true})}
                    {CustomTextArea({state:fromState, setState:setFromState, label:'اعزامی از', width:25, length:100})}
                </ItemContainer>

                <ItemContainer>
                    <DropDownTwoStatus val={'healthyFaulty'} state={tankStatus} setState={setTankStatus} dropTitle='وضعیت مخزن'/>
                    <DropDownThreeStatus state={valveBox} setState={setValveBox} dropTitle='جعبه شیرآلات'/>
                    <DropDownDefualtValue state={rightFender} setState={setRightFender} dropTitle='گلگیر سه طرف سمت شاگرد' list={defaultFenderValue}/>
                    <DropDownDefualtValue state={leftFender} setState={setLeftFender} dropTitle='گلگیر سه طرف سمت راننده' list={defaultFenderValue}/>
                    <DropDownThreeStatus state={rearSideLights} setState={setRearSideLights} dropTitle='چراغ های عقب بغل'/>
                    <DropDownThreeStatus state={powerPlug} setState={setPowerPlug} dropTitle='فیش برق و کفگرد'/>
                    <DropDownThreeStatus state={spreadMud} setState={setSpreadMud} dropTitle='گل پخش کن'/>
                </ItemContainer>

                <ItemContainer>
                    <DropDownTwoStatus val={'healthyFaulty'} state={spareStrap} setState={setSpareStrap} dropTitle='زاپاس بند'/>
                    <DropDownTwoStatus val={'yesNo'} state={faucetConversion} setState={setFaucetConversion} dropTitle='تبدیل شیرآلات'/>
                    <DropDownDefualtValue state={faucetCap} setState={setFaucetCap} dropTitle='درپوش شیرآلات' list={defaultFaucetCapValue}/>
                </ItemContainer>

                <ItemContainer>
                    <DropDownThreeStatus state={safetyValve} setState={setSafetyValve} dropTitle='سوپاپ های اطمینان'/>
                    <DropDownThreeStatus state={ladder} setState={setLadder} dropTitle='نردبان'/>
                    <DropDownThreeStatus state={tankerJack} setState={setTankerJack} dropTitle='جک تانکر با دسته'/>
                    <DropDownThreeStatus state={brakeRatchets} setState={setBrakeRatchets} dropTitle='جغجغه های ترمز'/>
                    <DropDownThreeStatus state={rearBumper} setState={setRearBumper} dropTitle='سپر عقب'/>
                </ItemContainer>

                <ItemContainer>
                    <DropDownTwoStatus val={'healthyFaulty'} state={machine} setState={setMachine} dropTitle='دستگاه'/>
                    <DropDownDefualtValue state={rim} setState={setRim} dropTitle='رینگ' list={defaultrimValue}/>
                    <DropDownDefualtValue state={boltAndNut} setState={setBoltAndNut} dropTitle='پیچ و مهره' list={defaultBoltAndNutValue}/>
                    <DropDownTwoStatus val={'yesNo'} state={earthChain} setState={setEarthChain} dropTitle='زنجیر ارت'/>
                    <DropDownTwoStatus val={'yesNo'} state={spare} setState={setSpare} dropTitle='زاپاس'/>
                    <DropDownTwoStatus val={'yesNo'} state={waterTank} setState={setWaterTank} dropTitle='تانک آب'/>
                    <DropDownTwoStatus val={'yesNo'} state={fireBox} setState={setFireBox} dropTitle='جعبه آتش نشانی'/>
                    <DropDownTwoStatus val={'yesNo'} state={fifthGear} setState={setFifthGear} dropTitle='دنده پنج'/>
                    <DropDownTwoStatus val={'yesNo'} state={fireCapsule} setState={setFireCapsule} dropTitle='کپسول آتش نشانی'/>
                    <DropDownTwoStatus val={'yesNo'} state={barometer} setState={setBarometer} dropTitle='فشار سنج'/>
                    <DropDownTwoStatus val={'yesNo'} state={thermometer} setState={setThermometer} dropTitle='ترمومتر'/>
                    <DropDownTwoStatus val={'yesNo'} state={rotorGage} setState={setRotorGage} dropTitle='روتور گیج'/>
                    <DropDownTwoStatus val={'yesNo'} state={rotorGageDisc} setState={setRotorGageDisc} dropTitle='صفحه روتور گیج'/>
                    <DropDownTwoStatus val={'yesNo'} state={valvePercentageGauge} setState={setValvePercentageGauge} dropTitle='شیر درصد سنج'/>
                    <DropDownTwoStatus val={'yesNo'} state={fastClosingSystem} setState={setFastClosingSystem} dropTitle='سیستم زود بند'/>
                </ItemContainer>

                <ItemContainer>
                    <DropDownPercentStatus state={wheelPercent1} setState={setWheelPercent1} dropTitle='درصد لاستیک محور عقب سمت راننده'/>
                    {CustomInput({state:wheelName1, setState:setWheelName1, label:'نام لاستیک محور عقب سمت راننده', width:30, length:50, cssClass:'pt-4'})}
                    <DropDownPercentStatus state={wheelPercent2} setState={setWheelPercent2} dropTitle='درصد لاستیک محور وسط سمت راننده'/>
                    {CustomInput({state:wheelName2, setState:setWheelName2, label:'نام لاستیک محور وسط سمت راننده', width:30, length:50, cssClass:'pt-4'})}
                    <DropDownPercentStatus state={wheelPercent3} setState={setWheelPercent3} dropTitle='درصد لاستیک محور جلو سمت راننده'/>
                    {CustomInput({state:wheelName3, setState:setWheelName3, label:'نام لاستیک محور جلو سمت راننده', width:30, length:50, cssClass:'pt-4'})}
                    <DropDownPercentStatus state={wheelPercent4} setState={setWheelPercent4} dropTitle='درصد لاستیک محور عقب سمت شاگرد'/>
                    {CustomInput({state:wheelName4, setState:setWheelName4, label:'نام لاستیک محور عقب سمت شاگرد', width:30, length:50, cssClass:'pt-4'})}
                    <DropDownPercentStatus state={wheelPercent5} setState={setWheelPercent5} dropTitle='درصد لاستیک محور وسط سمت شاگرد'/>
                    {CustomInput({state:wheelName5, setState:setWheelName5, label:'نام لاستیک محور وسط سمت شاگرد', width:30, length:50, cssClass:'pt-4'})}
                    <DropDownPercentStatus state={wheelPercent6} setState={setWheelPercent6} dropTitle='درصد لاستیک محور جلو سمت شاگرد'/>
                    {CustomInput({state:wheelName6, setState:setWheelName6, label:'نام لاستیک محور جلو سمت شاگرد', width:30, length:50, cssClass:'pt-4'})}
                </ItemContainer>

                <ItemContainer>
                    <div dir={'rtl'} className='w-full sm:w-[22rem]'>
                        <label htmlFor="brakePad" className="text-sm font-medium text-gray-900">
                        درصد لنت ترمز   % 
                        </label>
                        <label className="text-md font-medium text-gray-500">
                            {brakePad}
                        </label>
                        <input
                            type="range"
                            className="transparent h-[4px] w-full cursor-pointer appearance-none border-transparent bg-neutral-200 dark:bg-neutral-600"
                            min="0"
                            max="100"
                            step="5"
                            id="brakePad"
                            value={brakePad}
                            onChange={e=>setBrakePad(Number(e.target.value))}
                        />
                    </div>
                    {CustomTextArea({state:fullDesc, setState:setFullDesc, label:'توضیحات', width:25, length:1000, height:6})}
                </ItemContainer>
                
                <ItemContainer>
                    <SuccessBtn onClick={handlerSubmitModal}>
                        ویرایش فرم
                    </SuccessBtn>
                </ItemContainer>

                <Modal 
                    icon="success"
                    title="ویرایش فرم"
                    body="آیا از صحت اطلاعات فوق اطمینان دارید."
                    btnCancel={setOpenSubmitModal}
                    btnSubmit={handlereditBtn}
                    onOpen={openSubmitModal}
                />

            </div>
        </div>
    )
}

export default Admin_EditDeliveryReport
