import React, { useState, useEffect } from 'react'
import { DangerBtn } from '../components/btn.tsx'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { FullLoader } from '../components/loader.tsx'
import { Modal } from '../components/modal.tsx'
import { ConvertPersianNumToEnglishNum } from '../components/common.tsx'
import { FaArrowLeft } from "react-icons/fa";
import { serverDeactivateLogintime, serverLoginCount } from '../array/array.ts'

const ForgetPassword = () => {
    const navigate = useNavigate()
    const [number, setNumber] = useState<string>('');
    const [nationalCode, setNationalCode] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [userName, setuserName] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [loginCount, setLoginCount] = useState<number>(serverLoginCount)

    useEffect(()=>{
        if(loginCount <= 0){setLoginCount(serverLoginCount)}
    },[loginCount])

    function handlerLogin() {
        if(number && nationalCode){
            setLoader(true)
            axios.post(`${process.env.REACT_APP_API_ADDRESS}forgetPassword`, 
            {
                number: ConvertPersianNumToEnglishNum(number),
                nationalCode: ConvertPersianNumToEnglishNum(nationalCode),
            },
            {
                headers: {
                    'auth-token': process.env.REACT_APP_SECRETKEY,
                }
            })
            .then(response => {
                setShowSuccessModal(true)
                setLoader(false)
                setPassword(response.data.password)
                setuserName(response.data.userName)
            })
            .catch(error => {
                setNationalCode('')
                setNumber('')
                if(error.response.status === 429){
                    alert(`کاربر گرامی شما بیش تر از ${serverLoginCount} دفعه ورود نامعتبر داشتید. حساب کاربری شما به مدت ${serverDeactivateLogintime} دقیقه مسدود خواهد بود`);
                } else {
                    setLoginCount(loginCount-1)
                    alert(` شماره همراه یا کد ملی در سامانه ثبت نشده است. تنها ${loginCount} فرصت دیگر باقی مانده.`);
                }
                setLoader(false);
            });
        }
        else if(!number){alert('لطفا شماره همراه خود را وارد کنید')}
        else if(!nationalCode){alert('لطفا کد ملی خود را وارد کنید')}
    }

    function handlerNumber(e) {
        if(e.target.value.length <= 11){
            setNumber(e.target.value)
        }
    }

    function handlerNationalCode(e) {
        if(e.target.value.length <= 10){
            setNationalCode(e.target.value)
        }
    }

    function handlerCancelBtn() {
        navigate(-1)
    }

    return (
        <section className="bg-gradient-to-r from-slate-500 to-slate-900 w-screen h-screen">
            {loader && <FullLoader />}
            <div className='fixed top-1 left-1'>
                <a onClick={()=>navigate('/')} className='p-4 cursor-pointer hover:underline underline-offset-4 text-zinc-100 flex flex-row gap-2 '>
                    <FaArrowLeft className=' text-zinc-100 '/>
                    <p className='text-sm text-zinc-100 '>برگشت به صفحه اصلی</p>
                </a>
            </div>

            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto w-full h-full lg:py-0">
                {/* <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img className="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo"/>
                    Flowbite    
                </a> */}
                <div className="w-full h-fit bg-white rounded-lg md:mt-0 sm:max-w-md xl:p-0  shadow-xl">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <p className="text-xl font-bold text-gray-900 md:text-2xl text-center select-none">
                            فراموشی پسورد
                        </p>
                        <p className="text-xs font-bold text-gray-900 md:text-xs text-center select-none">
                            برای ویرایش پسورد لطفا شماره همراه و کد ملی خود را که در سامانه قرار گرفته وارد کنید
                        </p>
                        <div className="space-y-4 md:space-y-6">
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 text-right">شماره همراه</label>
                                <input 
                                    dir='rtl' 
                                    type="number" 
                                    name="number" 
                                    id="number"                                     
                                    placeholder="شماره همراه"
                                    onChange={handlerNumber}
                                    value={number}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-pink-600 focus:border-pink-600 block w-full p-2.5" 
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 text-right">کد ملی</label>
                                <input 
                                    dir='rtl' 
                                    type="number" 
                                    name="nationalCode" 
                                    id="nationalCode" 
                                    placeholder="کد ملی" 
                                    onChange={handlerNationalCode}
                                    value={nationalCode}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 block w-full p-2.5"
                                />
                            </div>
                            <div className='flex justify-end w-full pt-3'>
                                <DangerBtn onClick={handlerLogin}>
                                    ویرایش پسورد
                                </DangerBtn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal 
                icon="success"
                title={`${userName} پسورد شما تغییر کرد`}
                body={`پسورد جدید شما ${password} (شماره همراه + کد ملی)`}
                btnCancel={handlerCancelBtn}
                onOpen={showSuccessModal}
            />
        </section>
    )
}

export default ForgetPassword
