import React, { useEffect, useState} from 'react'
import Admin_Header from './header.tsx'
import { DropDownSearchStatus, HandlerChangeDigitToEn, SerachInput, ConvertPersianNumToEnglishNum, ConvertNumberToText, DatePicketComponent, ConvertNumberSeparate } from '../../components/common.tsx'
import { Modal } from '../../components/modal.tsx'
import axios from 'axios'
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText, uploadError } from '../../array/array.ts';
import { AdminTableList } from '../../components/adminTableList.tsx';
import { PrimaryBtn, TransparentLink } from '../../components/btn.tsx';

interface ShowModalDataTypes {
  tankCode:string,
  driverName:string, 
  driverNumber:number, 
  driverPlateNumber:string,
  repairType:string,
  brand:string, 
  replacementDate:string, 
  validityDate:string, 
  factorNumber:number, 
  price:number, 
  storeName:string, 
  sellerName:string, 
  sellerFamily:string, 
  description:string
}

interface UpdateModalDataTypes {
  repair_id:number,
  tankCode:string,
  driverName:string, 
  driverNumber:number, 
  driverPlateNumber:string,
  repairType:string,
  brand:string, 
  replacementDate:string, 
  validityDate:string, 
  factorNumber:number, 
  price:number, 
  storeName:string, 
  sellerName:string, 
  sellerFamily:string, 
  description:string
}

interface deleteModalDataTypes {
  repair_id:number, 
  name:string, 
  family?:string,
}

const tableHead = [
  {pName:"کد تانکر", eName:"tankCode", type:"string/number", sorted:true, showAllData:true},
  {pName:"نوع تعمیرات", eName:"repairType", type:"string", sorted:true, showAllData:true},
  {pName:"برند", eName:"brand", type:"string", sorted:true, showAllData:true},
  {pName:"تاریخ تعویض", eName:"replacementDate", type:"date", sorted:true, showAllData:true},
  // {pName:"تاریخ اعتبار", eName:"validityDate", type:"date", sorted:true, showAllData:true},
  {pName:"مبلغ", eName:"price", type:"number", sorted:true, showAllData:true, numberSeparate:true},
]

const tableHeadParams = [
  {pName:"کد تانکر", eName:"tankCode", type:"string/number"},
  {pName:"نام راننده", eName:"driverName", type:"string"},
  // {pName:"شماره راننده", eName:"driverNumber", type:"number"},
  // {pName:"پلاک", eName:"driverPlateNumber", type:"string/number"},
  {pName:"نوع تعمیرات", eName:"repairType", type:"string"},
  {pName:"برند", eName:"brand", type:"string", star:true},
  {pName:"تاریخ تعویض", eName:"replacementDate", type:"date", star:true,},
  {pName:"تاریخ اعتبار", eName:"validityDate", type:"date", star:true},
  {pName:"شماره فاکتور", eName:"factorNumber", type:"number", star:true},
  {pName:"مبلغ", eName:"price", type:"number", numberSeparate:true, star:true},
  {pName:"نام فروشگاه", eName:"storeName", type:"string", star:true},
  {pName:"نام فروشنده", eName:"sellerName", type:"string", star:true},
  {pName:"فامیل فروشنده", eName:"sellerFamily", type:"string", star:true},
  {pName:"توضیحات", eName:"description", type:"string", star:true},
]

const tableHeadSearchParams = [
  {pName:"کد تانک", eName:"tankCode", type:"string/number"},
  {pName:"نام راننده", eName:"driverName", type:"string"},
  // {pName:"شماره راننده", eName:"driverNumber", type:"number"},
  {pName:"نوع تعمیرات", eName:"repairType", type:"string"},
  {pName:"برند", eName:"brand", type:"string",},
  {pName:"شماره فاکتور", eName:"factorNumber", type:"number"},
  {pName:"نام فروشگاه", eName:"storeName", type:"string"},
  {pName:"نام فروشنده", eName:"sellerName", type:"string"},
  {pName:"فامیل فروشنده", eName:"sellerFamily", type:"string"},
]

const Admin_RepairFormList = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState<boolean>(false)
  const [refreshPage, setRefreshPage] = useState<number>(0)
  const [searchDropDownParam, setSearchDropDownParam] = useState<string>(tableHeadSearchParams[0].pName)
  const [data, setData] = useState<any[]>([])
  const [filteredArray, setFilteredArray] = useState<any[]>([])
  const [searchItem, setSearchItem] = useState<string>('')
  const [totalPrice, setTotalPrice] = useState<number>()
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const [searchStatus, setSearchStatus] = useState<'replacementDate'|'validityDate'>('replacementDate')
  const [openShowModal, setOpenShowModal] = useState<boolean>(false)
  const [showModalData, setShowModalData] = useState<{}>({})
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [deleteModalData, setDeleteModalData] = useState<deleteModalDataTypes>({repair_id :0, name:'', family:''})
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false)
  const [updateModalData, setUpdateModalData] = useState<UpdateModalDataTypes>({repair_id:0,tankCode:'',driverName:'',driverNumber:0,driverPlateNumber:'',repairType:'',brand:'',replacementDate:'',validityDate:'',factorNumber:0, price:0, storeName:'', sellerName:'', sellerFamily: '', description:''})
  // const [openInsertModal, setOpenInsertModal] = useState<boolean>(false)
  // const [insertModalData, setInsertModalData] = useState<{}>({})

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}admin/repairFormList`, {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
        });
        setFilteredArray([]);
        setData([]);

        // ----- show custom tanks for custom users ------
        if(localStorage.getItem('tank22to46')){
          response.data.filter((item)=>{
            const newTankCode = item.tankCode.slice(1,3)
            const newItem = newTankCode >= 22;
            if(newItem){
              setFilteredArray(prev=>[...prev,{
                repair_id:item.repair_id, 
                repairType:item.title,
                brand:item.brand, 
                replacementDate:item.replacementDate, 
                validityDate:item.validityDate, 
                factorNumber:item.factorNumber, 
                storeName:item.storeName, 
                sellerName:item.sellerName, 
                sellerFamily:item.sellerFamily, 
                price:item.price, 
                description:item.description, 
                tank_id:item.tank_id, 
                tankCode:item.tankCode, 
                tankNumber:item.tankNumber, 
                chassisNumber:item.chassisNumber, 
                driverName:item.family+' '+item.name, 
                driverNumber:item.number, 
                driverPlateNumber:item.plateNumber
              }]);
              setData(prev=>[...prev,{
                repair_id:item.repair_id, 
                repairType:item.title,
                brand:item.brand, 
                replacementDate:item.replacementDate, 
                validityDate:item.validityDate, 
                factorNumber:item.factorNumber, 
                storeName:item.storeName, 
                sellerName:item.sellerName, 
                sellerFamily:item.sellerFamily, 
                price:item.price, 
                description:item.description, 
                tank_id:item.tank_id, 
                tankCode:item.tankCode, 
                tankNumber:item.tankNumber, 
                chassisNumber:item.chassisNumber, 
                driverName:item.family+' '+item.name, 
                driverNumber:item.number, 
                driverPlateNumber:item.plateNumber
              }])
            }
          })
        }else{
          response.data.map((item)=>(
            // ---- add price sum of all resive data -----
            setFilteredArray(prev=>[...prev,{
              repair_id:item.repair_id, 
              repairType:item.title,
              brand:item.brand, 
              replacementDate:item.replacementDate, 
              validityDate:item.validityDate, 
              factorNumber:item.factorNumber, 
              storeName:item.storeName, 
              sellerName:item.sellerName, 
              sellerFamily:item.sellerFamily, 
              price:item.price, 
              description:item.description, 
              tank_id:item.tank_id, 
              tankCode:item.tankCode, 
              tankNumber:item.tankNumber, 
              chassisNumber:item.chassisNumber, 
              driverName:item.family+' '+item.name, 
              driverNumber:item.number, 
              driverPlateNumber:item.plateNumber
            }]),
            setData(prev=>[...prev,{
              repair_id:item.repair_id, 
              repairType:item.title,
              brand:item.brand, 
              replacementDate:item.replacementDate, 
              validityDate:item.validityDate, 
              factorNumber:item.factorNumber, 
              storeName:item.storeName, 
              sellerName:item.sellerName, 
              sellerFamily:item.sellerFamily, 
              price:item.price, 
              description:item.description, 
              tank_id:item.tank_id, 
              tankCode:item.tankCode, 
              tankNumber:item.tankNumber, 
              chassisNumber:item.chassisNumber, 
              driverName:item.family+' '+item.name, 
              driverNumber:item.number, 
              driverPlateNumber:item.plateNumber
            }])
          ))
        }

        setLoader(false);
      } catch (error) {
        setFilteredArray([])
        setData([])
        // console.error('Error fetching tanks:', error);
        setLoader(false); // Hide loader after fetching data
        alert(loginText)
        navigate('/admin')
      }
    };

    fetchData();
  }, [!endDate && refreshPage]);

  // ----- update totalPrice after filter
  useEffect(()=>{
    setTotalPrice(0)
    filteredArray.map((item)=>{
      setTotalPrice(prevTotalPrice => prevTotalPrice + item.price);
    })
  },[filteredArray])

  const handlerShowFunc = (item:ShowModalDataTypes) => {
    setOpenShowModal(true)
    setShowModalData({
      tankCode:item.tankCode,
      driverName:item.driverName, 
      driverNumber:item.driverNumber, 
      driverPlateNumber:item.driverPlateNumber,
      repairType:item.repairType,
      brand:item.brand, 
      replacementDate:item.replacementDate, 
      validityDate:item.validityDate, 
      factorNumber:item.factorNumber, 
      price:item.price, 
      storeName:item.storeName, 
      sellerFamily:item.sellerFamily, 
      sellerName: item.sellerName,
      description:item.description
    })
  }

  const handlerDeleteFunc = (item: deleteModalDataTypes) => {
    setOpenDeleteModal(true)
    setDeleteModalData({repair_id:item.repair_id, name:item.repairType, family:item.brand})
  } 
  
  const handlerUpdateFunc = (item: UpdateModalDataTypes) => {
    setOpenUpdateModal(true)
    setUpdateModalData({
      repair_id: item.repair_id,
      tankCode:item.tankCode,
      driverName:item.driverName, 
      driverNumber:item.driverNumber, 
      driverPlateNumber:item.driverPlateNumber,
      repairType:item.repairType,
      brand:item.brand,
      replacementDate:item.replacementDate, 
      validityDate:item.validityDate, 
      factorNumber:item.factorNumber,
      price:item.price,
      storeName:item.storeName, 
      sellerName:item.sellerName, 
      sellerFamily:item.sellerFamily, 
      description:item.description, 
    })
  }

  const handlerUpdateQuery = async () => {
    if(updateModalData.replacementDate, updateModalData.validityDate, updateModalData.factorNumber, updateModalData.price, updateModalData.storeName, updateModalData.sellerFamily){
      setLoader(true)
      await axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/upadateRepairForm`, 
        {
            repair_id: updateModalData.repair_id,
            user_id: Cookies.get('id'),
            brand: updateModalData.brand,
            replacementDate:  HandlerChangeDigitToEn(updateModalData.replacementDate),
            validityDate:  HandlerChangeDigitToEn(updateModalData.validityDate),
            factorNumber: updateModalData.factorNumber,
            storeName: updateModalData.storeName,
            sellerName: updateModalData.sellerName,
            sellerFamily: updateModalData.sellerFamily,
            price:  ConvertPersianNumToEnglishNum(String(updateModalData.price)),
            description: updateModalData.description
        },
        {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
        })
        .then(response => {
            // console.log('Response:', response.data);
            setLoader(false)
            // alert('ردیف انتخابی به درستی ویرایش شد')
            setRefreshPage((prev) => prev + 1)
            if(startDate && endDate){handlerShowQuery(searchStatus)}
            setOpenUpdateModal(false)
        })
        .catch(error => {
            // console.error('Error:', error);
            setLoader(false); // Hide loader after fetching data
            alert(uploadError)
            // alert(loginText)
            // navigate('/admin')
        }
      )
    }else{
      alert('برای ویرایش فیلد های ستاره دار باید کامل باشند .')
    }
  };

  const handlerDeleteQuery = async () => {
    setLoader(true)
    axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/deleteRepairForm`, 
        {
            user_id: Cookies.get('id'),
            repair_id: deleteModalData.repair_id
        },
        {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
        })
        .then(response => {
            // console.log('Response:', response.data);
            setLoader(false)
            setRefreshPage((prev) => prev + 1)
            if(startDate && endDate){handlerShowQuery(searchStatus)}
            setOpenDeleteModal(false)
        })
        .catch(error => {
            // console.error('Error:', error);
            setLoader(false); // Hide loader after fetching data
            alert(uploadError)
            // alert(loginText)
            // navigate('/admin')       
     })
  };

  async function handlerShowQuery(status:'replacementDate'|'validityDate') {
    setSearchStatus(status)
    if (startDate && endDate) {
      setLoader(true)
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/repairFormListSearchDate`, 
          {
            searchFor: status,
            startDate:HandlerChangeDigitToEn(startDate),
            endDate: HandlerChangeDigitToEn(endDate),
          },  
          {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
          }
        );
        setFilteredArray([]);
        setData([]);

        // ----- show custom tanks for custom users ------
        if(localStorage.getItem('tank22to46')){
          response.data.filter((item)=>{
            const newTankCode = item.tankCode.slice(1,3)
            const newItem = newTankCode >= 22;
            if(newItem){
              setFilteredArray(prev=>[...prev,{
                repair_id:item.repair_id, 
                repairType:item.title,
                brand:item.brand, 
                replacementDate:item.replacementDate, 
                validityDate:item.validityDate, 
                factorNumber:item.factorNumber, 
                storeName:item.storeName, 
                sellerName:item.sellerName, 
                sellerFamily:item.sellerFamily, 
                price:item.price, 
                description:item.description, 
                tank_id:item.tank_id, 
                tankCode:item.tankCode, 
                tankNumber:item.tankNumber, 
                chassisNumber:item.chassisNumber, 
                driverName:item.family+' '+item.name, 
                driverNumber:item.number, 
                driverPlateNumber:item.plateNumber
              }]);
              setData(prev=>[...prev,{
                repair_id:item.repair_id, 
                repairType:item.title,
                brand:item.brand, 
                replacementDate:item.replacementDate, 
                validityDate:item.validityDate, 
                factorNumber:item.factorNumber, 
                storeName:item.storeName, 
                sellerName:item.sellerName, 
                sellerFamily:item.sellerFamily, 
                price:item.price, 
                description:item.description, 
                tank_id:item.tank_id, 
                tankCode:item.tankCode, 
                tankNumber:item.tankNumber, 
                chassisNumber:item.chassisNumber, 
                driverName:item.family+' '+item.name, 
                driverNumber:item.number, 
                driverPlateNumber:item.plateNumber
              }])
            }
          })
        }else{
          response.data.map((item)=>(
            // ---- add price sum of all resive data -----
            setFilteredArray(prev=>[...prev,{
              repair_id:item.repair_id, 
              repairType:item.title,
              brand:item.brand, 
              replacementDate:item.replacementDate, 
              validityDate:item.validityDate, 
              factorNumber:item.factorNumber, 
              storeName:item.storeName, 
              sellerName:item.sellerName, 
              sellerFamily:item.sellerFamily, 
              price:item.price, 
              description:item.description, 
              tank_id:item.tank_id, 
              tankCode:item.tankCode, 
              tankNumber:item.tankNumber, 
              chassisNumber:item.chassisNumber, 
              driverName:item.family+' '+item.name, 
              driverNumber:item.number, 
              driverPlateNumber:item.plateNumber
            }]),
            setData(prev=>[...prev,{
              repair_id:item.repair_id, 
              repairType:item.title,
              brand:item.brand, 
              replacementDate:item.replacementDate, 
              validityDate:item.validityDate, 
              factorNumber:item.factorNumber, 
              storeName:item.storeName, 
              sellerName:item.sellerName, 
              sellerFamily:item.sellerFamily, 
              price:item.price, 
              description:item.description, 
              tank_id:item.tank_id, 
              tankCode:item.tankCode, 
              tankNumber:item.tankNumber, 
              chassisNumber:item.chassisNumber, 
              driverName:item.family+' '+item.name, 
              driverNumber:item.number, 
              driverPlateNumber:item.plateNumber
            }])
          ))
        }
        
        setLoader(false);
      } catch (error) {
        // console.error('Error Fetching Repair List:', error);
        setLoader(false); // Hide loader after fetching data
        alert(uploadError)        
        // alert(loginText)
        // navigate('/admin')
      }
    }else{
     alert('لطفا تاریخ شروع و تاریخ پایان را انتخاب کنید')
    }
  }
  
  function SearchDate() {
    return (
      <div className='w-full'>
        {/* <p className='text-xs w-full text-right'>: جستجو بر اساس تاریخ تعویض </p> */}
        <div className='flex flex-row-reverse flex-wrap center sm:items-center sm:justify-start gap-3 my-4 px-4'>
          <div className='w-35'>
            <DatePicketComponent title="از تاریخ" state={startDate} setState={setStartDate} type='simple'/>
          </div>
          <div className='w-35'>
            <DatePicketComponent title="تا تاریخ" state={endDate} setState={setEndDate} type='simple'/>
          </div>
            <PrimaryBtn onClick={()=>handlerShowQuery('replacementDate')}>
              <p>جستجو براساس تاریخ تعویض</p>
            </PrimaryBtn>
            <PrimaryBtn onClick={()=>handlerShowQuery('validityDate')}>
              <p>جستجو براساس تاریخ اعتبار</p>
            </PrimaryBtn>
        </div>
      </div>
    );
  }
  
  function SearchTableParams() {
    const handleInputSearch = (e) => { 
      const searchTerm = ConvertPersianNumToEnglishNum(e.target.value);
      setSearchItem(searchTerm);
      const filteredItems = data.filter((item) =>
        (searchDropDownParam === tableHeadSearchParams[0].pName && item.tankCode.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase())) ||
        (searchDropDownParam === tableHeadSearchParams[1].pName && item.driverName && item.driverName.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase())) ||
        (searchDropDownParam === tableHeadSearchParams[2].pName && item.driverNumber && item.driverNumber.includes(searchTerm)) ||
        (searchDropDownParam === tableHeadSearchParams[3].pName && item.repairType && item.repairType.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase())) ||
        (searchDropDownParam === tableHeadSearchParams[4].pName && item.brand && item.brand.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase())) ||
        (searchDropDownParam === tableHeadSearchParams[5].pName && item.factorNumber && item.factorNumber.includes(searchTerm)) ||
        (searchDropDownParam === tableHeadSearchParams[6].pName && item.storeName && item.storeName.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase())) ||
        (searchDropDownParam === tableHeadSearchParams[7].pName && item.sellerName && item.sellerName.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase())) ||
        (searchDropDownParam === tableHeadSearchParams[8].pName && item.sellerFamily && item.sellerFamily.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase()))
      );
    
      setFilteredArray(filteredItems);
    }
    return(
      <>
        <div className='w-full flex flex-row center md:items-center md:justify-end gap-3 p-4'>
          <SerachInput title={"جستجوی " + searchDropDownParam} state={searchItem} handler={handleInputSearch}/>
          <DropDownSearchStatus list={tableHeadSearchParams} state={searchDropDownParam} setState={setSearchDropDownParam}/>
        </div>
      </>
    )
  }

  const showModalBody = () => {
    return(
      <ul className='w-[20rem] sm:w-[25rem] h-fit space-y-2'>
        {
          tableHeadParams.map((item,key)=>(
            <li key={key} className='flex flex-row items-center justify-start'>
              <span className='text-sm w-2/5'>
                {item.pName}:
              </span>
                {
                  item.eName === 'description'?
                    <textarea
                      disabled={true}
                      id={item.eName}
                      className="p-2 w-full min-h-20 sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " 
                      placeholder={item.pName}
                      value={showModalData[item.eName]}
                    />
                  :
                    <input
                      type="text" 
                      disabled={true}
                      id={item.eName}
                      className="p-2 w-full sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " 
                      placeholder={item.pName}
                      value={showModalData[item.eName]}
                    />
                }
            </li>
          ))
        }
      </ul>
    )
  }
  
  const updateModalBody = () => {
    return(
      <ul className='w-[20rem] sm:w-[25rem] h-fit space-y-2'>
        {
          tableHeadParams.map((item,key)=>(
            <li key={key} className='flex flex-row items-center justify-start'>
              <span className='text-sm w-2/5'>
                {item.pName}:
                {item.star && <span className='text-red-500'>*</span> }
              </span>
                {
                  item.type === 'date'?
                  <DatePicketComponent title={updateModalData[item.pName]} state={updateModalData[item.eName]} setState={e=>setUpdateModalData(prev=>({...prev, [item.eName]:e}))} />
                  :
                    item.eName === 'description'?
                      <textarea
                        id={item.eName}
                        className="p-2 w-full min-h-20 sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " 
                        placeholder={item.pName}
                        value={updateModalData[item.eName]}
                        onChange={e=>setUpdateModalData(prev=>({...prev, [item.eName]:e.target.value}))}
                      />
                    :
                      <input
                        type="text" 
                        disabled={item.star ? false : true}
                        id={item.eName}
                        className="p-2 w-full sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " 
                        placeholder={item.pName}
                        value={updateModalData[item.eName]}
                        onChange={e=>setUpdateModalData(prev=>({...prev, [item.eName]:e.target.value}))}
                      />
                }
            </li>
          ))
        }
      </ul>
    )
  }

  return (
    <div className='m-r-nav'>
      {loader && <FullLoader />}
      <Admin_Header title={'لیست گزارشات تعمیرات'}/>

      <div className='w-full text-justify sm:text-right px-12 flex flex-col gap-4'>
        <p className='text-sm text-gray-600' dir='rtl'>
          <span className='text-gray-800'> نکته یک : </span>
          بطور پیش فرض ۱۰ گزارش آخر نمایش داده میشود . 
        </p>
        <p className='text-sm text-gray-600' dir='rtl'>
          <span className='text-gray-800'> نکته دو : </span>
          برای نمایش لیست  تاریخ مد نظر را انتخاب کرده سپس دکمه جستجو را کلیک کنید .
        </p>
      </div>

      <div className='px-4 mb-8 sm:px-8'>
        {SearchDate()}
        {SearchTableParams()}

        <TransparentLink 
          to={'/admin/printList/'} 
          params={{
            array:filteredArray, 
            listHead:tableHeadParams , 
            pageHeader:startDate && endDate ? ' خرج کرد کپسول ها از تاریخ '+HandlerChangeDigitToEn(startDate)+' تا '+HandlerChangeDigitToEn(endDate): ' خرج کرد کپسول ها (۱۰ مورد آخر)'
          }} 
          cssClass={'center mt-8 mb-4 sm:mt-0 sm:mb-0'}
        >
          فرم چاپی
        </TransparentLink>

        <p className='w-full p-4 py-8 sm:py-4 text-center sm:text-right text-sm leading-6'>
          <span> مجموع مبالغ ردیف های نمایش داده شده به عدد </span>
          <span className='font-black underline underline-offset-8'> {ConvertNumberSeparate(totalPrice)} </span>
          <span> و به حروف </span>
          <span className='font-black underline underline-offset-8'> {ConvertNumberToText(totalPrice)} </span>
          <span> (تومان) </span>
        </p>

        <AdminTableList
          TableList={filteredArray} 
          tableHeadParams={tableHead} 
          handlerShowFunc={handlerShowFunc}
          handlerDeleteFunc={handlerDeleteFunc}
          handlerUpdateFunc={handlerUpdateFunc}
        />

      </div>
      
      <Modal 
        title={'جزئیات تعمیرات'}
        body={showModalBody()}
        onOpen={openShowModal}
        btnCancel={setOpenShowModal}
      />

      <Modal 
        title='ویرایش ردیف'
        body={updateModalBody()}
        onOpen={openUpdateModal}
        btnCancel={setOpenUpdateModal}
        btnSubmit={handlerUpdateQuery}
      />

      <Modal 
        title='حذف ردیف'
        body={"میخوای تعمیرات " + deleteModalData.name + " حذف بشه؟ "}
        icon='warning'
        onOpen={openDeleteModal}
        btnCancel={setOpenDeleteModal}
        btnDelete={handlerDeleteQuery}
      />
    </div>
  )
}

export default Admin_RepairFormList
