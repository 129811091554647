import React, { useState, useEffect } from 'react';
import Member_Header from './header.tsx';
import { Link } from 'react-router-dom';
import { MemberHomeArrList, loginText } from '../../array/array.ts';
import { HandlerNavigateTankCode } from '../../components/common.tsx';
import axios from 'axios';
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function List() {
    return(
        MemberHomeArrList.map((item, key) => (
            <Link 
                key={key}
                className={'w-full sm:w-[20rem] py-14 sm:py-16 mt-0 sm:mt-4 shadow-lg rounded-md text-center text-white hover:scale-[1.02] cursor-pointer bg-gradient-to-tr '+ item.color}
                to={item.href ? item.href : ''}
            >
                {item.title}
            </Link>
        ))
    )
}

const Member_Home = () => {
    const navigate = useNavigate();
    const tankCode = localStorage.getItem("tankCode");
    const [loader, setLoader] = useState<boolean>(true)

    useEffect(() => {
        setLoader(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}userCheckAuth`, {
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                setLoader(false);
            } catch (error) {
                // console.error('Error conecting server', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate('/member')
            }
        };
    
        fetchData();
    }, []);

    return (
        loader ? <FullLoader /> :
            <div className='m-r-nav '>
                <Member_Header tankName={tankCode ? tankCode : ''}/>

                {
                    tankCode ?
                        <div className='flex flex-col sm:flex-row-reverse p-8 pt-0 flex-wrap justify-center items-center gap-8'>
                            <List />
                        </div>
                    :
                        <HandlerNavigateTankCode />
                }
                
            </div>
    )
}

export default Member_Home
