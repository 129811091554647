import React,{ useState, useEffect } from 'react'
import Admin_Header from './header.tsx'
import { AdminTableList } from '../../components/adminTableList.tsx'
import { PrimaryBtn, TransparentLink } from '../../components/btn.tsx'
import { DatePicketComponent, ConvertDateToJalali, ConvertDateToTime, GetDayNameFromDate, ConvertJalaliToGregorian } from '../../components/common.tsx';
import axios from 'axios';
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText } from '../../array/array.ts';

const tableHead = [
  {pName:"نام", eName:"name", type:"string", sorted:true},
  {pName:"نام خانوادگی", eName:"family", type:"string", sorted:true},
  {pName:"شماره", eName:"number", type:"number", sorted:true},
  {pName:"تاریخ ورود", eName:"loginDate", type:"date", sorted:true},
  {pName:"روز ورود", eName:"loginDay", type:"string", sorted:true},
  {pName:"ساعت ورود", eName:"loginHours", type:"date", sorted:true},
]

const Admin_LoginReport = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])
  const [filteredArray, setFilteredArray] = useState<any[]>([])
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const headerTitle = 'لیست ورود به سیستم';

  useEffect(() => {
    setLoader(true);
    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}admin/loginList`, {
                headers: {
                    'auth-token': Cookies.get('token'),
                    'auth-id': Cookies.get('id')
                }
            });
            setFilteredArray([]);
            setData([]);
            const myData = response.data;
            myData.map((item)=>{
              setFilteredArray(prev=>[...prev, {name:item.name, family:item.family, number:item.number, loginDate:ConvertDateToJalali(item.login_time), loginDay:GetDayNameFromDate(item.login_time), loginHours:ConvertDateToTime(item.login_time) }])
              setData(prev=>[...prev, {name:item.name, family:item.family, number:item.number, loginDate:ConvertDateToJalali(item.login_time), loginDay:GetDayNameFromDate(item.login_time), loginHours:ConvertDateToTime(item.login_time) }])
            })
            setLoader(false);
        } catch (error) {
            // console.error('Error fetching tanks:', error);
            setLoader(false); // Hide loader after fetching data
            alert(loginText)
            navigate('/admin')
        }
    };
    fetchData();
  }, []);
  
  async function handlerShowQuery() {
    if (startDate && endDate) {
      setLoader(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/loginListSearchDate`, 
          {
            startDate:ConvertJalaliToGregorian(startDate),
            endDatet: ConvertJalaliToGregorian(endDate),
          },  
          {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
          }
        );
        setFilteredArray([]);
        setData([]);
        const myData = response.data;
        myData.map((item)=>{
          setFilteredArray(prev=>[...prev, {name:item.name, family:item.family, number:item.number, loginDate:ConvertDateToJalali(item.login_time), loginDay:GetDayNameFromDate(item.login_time), loginHours:ConvertDateToTime(item.login_time) }])
          setData(prev=>[...prev, {name:item.name, family:item.family, number:item.number, loginDate:ConvertDateToJalali(item.login_time), loginDay:GetDayNameFromDate(item.login_time), loginHours:ConvertDateToTime(item.login_time) }])
        })
        setLoader(false);
    } catch (error) {
      // console.error('Error Fetching Repair List:', error);
      setLoader(false); // Hide loader after fetching data
      // alert(loginText)
      // navigate('/admin')
    }
    }else{
     alert('لطفا تاریخ شروع و تاریخ پایان را انتخاب کنید')
    }
  }
  
  function SearchTableData() {
    // const handleInputSearch = () => { 
    //   const filteredItems = data.filter((item) => {
    //     const itemStartDate = item.loginDate;
    //     if (startDate && endDate) {
    //       // Assuming the date properties in your data object are named startDate and endDate
    //       return itemStartDate >= HandlerChangeDigitToEn(startDate) && itemStartDate <= HandlerChangeDigitToEn(endDate);
    //     }
    //     else if(startDate) {
    //       return itemStartDate == HandlerChangeDigitToEn(startDate);
    //     }
    //     else{
    //       alert('برای جستجو لطفا تاریخ شروع یا تاریخ شروع و پایان را انتخاب کنید')
    //     }
    //     return true; // If no dates are selected, include all items
    //   });
  
    //   setFilteredArray(filteredItems);
    // }
  
    return (
      <div className='w-full pt-6'>
        <p className='text-xs w-full text-right px-6'>: جستجو بر اساس تاریخ </p>
        <div className='flex flex-row-reverse flex-wrap justify-center sm:justify-start items-center gap-3 my-4 px-4'>
          <DatePicketComponent title="از تاریخ" state={startDate} setState={setStartDate} type='pureDate'/>
          <DatePicketComponent title="تا تاریخ" state={endDate} setState={setEndDate} type='pureDate'/>
          <PrimaryBtn onClick={handlerShowQuery} cssClass={'ml-2 mr-2'}>
            <p>جستجو</p>
          </PrimaryBtn>
        </div>
      </div>
    );
  }
  
  return (
    <>
      {loader && <FullLoader />}
      <Admin_Header title={headerTitle}/>
      <div className='m-r-nav px-1 mb-4 sm:px-6 '>

        <div className='w-full text-justify sm:text-right px-6 flex flex-col gap-4'>
          <p className='text-sm text-gray-600' dir='rtl'>
            <span className='text-gray-800'> نکته : </span>
            بطور پیش فرض ۱۰۰ ورود آخر نمایش داده میشود. برای نمایش لیست بیشتر تاریخ مد نظر را انتخاب سپس دکمه جستجو را کلیک کنید .
          </p>
        </div>

        <SearchTableData />
        <div className='w-full flex justify-start items-center px-6 pb-6'>
          <TransparentLink to={'/admin/printList/'} params={{array:filteredArray, listHead: tableHead, pageHeader:headerTitle}}>
            فرم چاپی
          </TransparentLink>
        </div>
        {/* <p className='text-sm text-gray-600 text-right py-3' dir='rtl'>
          <span className='text-gray-800'> نکته : </span>
            بطور پیش فرض ۵۰ گزارش آخر نمایش داده میشود. برای نمایش تمام گزارشات تاریخ مد نظر را انتخاب کرده و دکمه جستجو را کلیک کنید .
        </p> */}
        <AdminTableList 
          TableList={filteredArray} 
          tableHeadParams={tableHead}
        />
      </div>
    </>
  )
}

export default Admin_LoginReport
