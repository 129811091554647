import React, { useState, useEffect } from 'react';
import Member_Header from './header.tsx';
import { useNavigate } from 'react-router-dom';
import { TransparentLink } from '../../components/btn.tsx';
import axios from 'axios';
import { FullLoader } from '../../components/loader.tsx';
import Cookies from 'js-cookie';
import { loginText } from '../../array/array.ts';
import {ShowProtectedImage} from '../../components/showProtectedImage.tsx';

interface dataType {
    companyName:string;
    chassisNumber:string;
    deliveryDate:string;
    contractNumber:string;
    fromState:string;
    fullDesc:string;
    driverName:string;
    driverFamily:string;
    driverSigImg:string;
    userName:string;
    userFamily:string;
    userSigImg:string;
}

interface listType {
    name: string;
    state: string;
}

const DeliveryReportDetail = () => {
    const navigate = useNavigate();
    const tankCode = localStorage.getItem("tankCode");
    const tankId = localStorage.getItem("tankId");

    const [selectedDate, setSelectedDate] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false)  
    const [data, setData] = useState<dataType>({companyName:'',chassisNumber:'',deliveryDate:'',contractNumber:'',fromState:'',fullDesc:'',driverName:'',driverFamily:'',driverSigImg:'',userName:'',userFamily:'',userSigImg:'',})
    const [list, setList] = useState<listType[]>([])
    const [deliveryDate, setDeliveryDate] = useState<any[]>([])

    useEffect(() => {
        setLoader(true);
        const fetchData = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_ADDRESS}users/deliveryDatesList`,
                {
                    tank_id: tankId
                } 
                ,{
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                setDeliveryDate(response.data);
                setLoader(false);
            } catch (error) {
                console.error('Error fetching tanks:', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate('/member')
            }
        };
    
        fetchData();
    }, []);

    useEffect(() => {
        setLoader(true);
        const fetchData = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_ADDRESS}users/deliveryList`,
                {
                    delivery_id: selectedDate
                } 
                ,{
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                const data = response.data[0];
                setData({
                    companyName:data.companyName,
                    chassisNumber:data.chassisNumber,
                    deliveryDate:data.deliveryDate,
                    contractNumber:data.contractNumber,
                    fromState:data.fromState,
                    fullDesc:data.fullDesc,
                    driverName:data.driverName,
                    driverFamily:data.driverFamily,
                    driverSigImg:data.driverSigImg,
                    userName:data.userName,
                    userFamily:data.userFamily,
                    userSigImg:data.userSigImg,
                });
                setList([
                    {name:'وضعیت مخزن',state:data.tankStatus},
                    {name:'جعبه شیر آلات',state:data.valveBox},
                    {name:'گلگیر سه طرف سمت شاگرد',state:data.rightFender},
                    {name:'گلگیر سه طرف سمت راننده',state:data.leftFender},
                    {name:'چراغهای عقب و بغل',state:data.rearSideLights},
                    {name:'فیش برق و کفگرد',state:data.powerPlug},
                    {name:'گل پخش کن',state:data.spreadMud},
                    {name:'زاپاس بند',state:data.spareStrap},
                    {name:'تبدیل شیرآلات',state:data.faucetConversion},
                    {name:'درپوش شیرآلات',state:data.faucetCap},
                    {name:'سوپاپهای اطمینان',state:data.safetyValve},
                    {name:'نردبان',state:data.ladder},
                    {name:'جک تانکر با دسته',state:data.tankerJack},
                    {name:'جغجغه های ترمز',state:data.brakeRatchets},
                    {name:'سپر عقب',state:data.rearBumper},
                    {name:'دستگاه',state:data.machine},
                    {name:'رینگ',state:data.rim},
                    {name:'پیچ و مهره',state:data.boltAndNut},
                    {name:'زنجیر ارت',state:data.earthChain},
                    {name:'زاپاس',state:data.spare},
                    {name:'تانک آب',state:data.waterTank},
                    {name:'جعبه آتش نشانی',state:data.fireBox},
                    {name:'دنده پنج',state:data.fifthGear},
                    {name:'کپسول آتش نشانی',state:data.fireCapsule},
                    {name:'فشار سنج',state:data.barometer},
                    {name:'ترمومتر',state:data.thermometer},
                    {name:'روتور گیج',state:data.rotorGage},
                    {name:'صفحه روتور گیج',state:data.rotorGageDisc},
                    {name:'شیر درصد سنج',state:data.valvePercentageGauge},
                    {name:'سیستم زود بند',state:data.fastClosingSystem},
                    {name:'درصد لنت ترمز',state:'%'+data.brakePad},
                    {name:'درصد لاستیک اکسل جلو سمت راننده', state:'%'+data.wheelPercent3},
                    {name:'نام لاستیک اکسل جلو سمت راننده', state:data.wheelName3},
                    {name:'درصد لاستیک اکسل وسط سمت راننده', state:'%'+data.wheelPercent2},
                    {name:'نام لاستیک اکسل وسط سمت راننده', state:data.wheelName2},
                    {name:'درصد لاستیک اکسل عقب سمت راننده', state:'%'+data.wheelPercent1},
                    {name:'نام لاستیک اکسل عقب سمت راننده', state:data.wheelName1},
                    {name:'درصد لاستیک اکسل جلو سمت شاگرد', state:'%'+data.wheelPercent6},
                    {name:'نام لاستیک اکسل جلو سمت شاگرد', state:data.wheelName6},
                    {name:'درصد لاستیک اکسل وسط سمت شاگرد', state:'%'+data.wheelPercent5},
                    {name:'نام لاستیک اکسل وسط سمت شاگرد', state:data.wheelName5},
                    {name:'درصد لاستیک اکسل عقب سمت شاگرد', state:'%'+data.wheelPercent4},
                    {name:'نام لاستیک اکسل عقب سمت شاگرد', state:data.wheelName4},
                ])
                setLoader(false);
            } catch (error) {
                // console.error('Error fetching tanks:', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate('/member')
            }
        };
        
        if(selectedDate){
            fetchData();
        }
    }, [selectedDate]);
    
    const TankerDeliveryList = () => {
        function BoldParagraphText({children}:{children:React.ReactNode}) {
            return(
                <span className=' underline underline-offset-4 text-black font-bold px-1'>
                    {" "+children+" "}
                </span>
            )
        }
        function TankParams() {
            return(
                <div className='w-full grid grid-cols-1 sm:grid-cols-2 sm:grid-rows-[repeat(22,minmax(0,1fr))] grid-flow-row sm:grid-flow-col' dir='rtl'>
                {/* <div className='w-full grid grid-cols-1 grid-flow-row' dir='rtl'> */}
                    {
                        list.map((item, key)=>(
                            <div key={key} className={`flex flex-row items-start px-4 py-2 border-b-2 ${key < 22 && 'sm:border-l-2'}`}>
                                <p className='w-2/3 text-sm'>
                                    <span>{key+1}- </span>
                                    <span>{item.name}</span>
                                </p>
                                <p className='w-1/3 text-sm text-center'>
                                    {item.state}
                                </p>
                            </div>
                        ))
                    }
                </div>
            )
        }
        // function handlerPrint() {
        //     const dataToSend = encodeURIComponent(JSON.stringify(selectedDate));
        //     window.open(`/admin/printableDeliveryReport?data=${dataToSend}`,"_self");
        //     // window.open(`/admin/printableDeliveryReport?data=${dataToSend}`, '_blank');
        // }
        return(
            <div className=''>
                <div className='w-full flex justify-center py-4' >
                    <TransparentLink to='/member/printableDeliveryReport' params={{data,list}}>چاپ فرم</TransparentLink>
                </div>
                <div id={'printFinalTank'} className='w-full border-2 border-black rounded-md p-4 space-y-2'>
                    <p className='w-full text-center text-lg font-black'>فرم گزارش تحویل مخزن گاز مایع به راننده</p>
                    
                    <p className='w-full text-justify text-gray-700 leading-loose text-sm' dir={'rtl'}>
                        بدینوسیله یک مخزن حمل گاز مایع ساخت کارخانه
                        <BoldParagraphText>{data.companyName}</BoldParagraphText>
                        به شماره شاسی
                        <BoldParagraphText>{data.chassisNumber}</BoldParagraphText>
                        در تاریخ
                        <BoldParagraphText>{data.deliveryDate}</BoldParagraphText>
                        طبق قرارداد شماره
                        <BoldParagraphText>{data.contractNumber}</BoldParagraphText>
                        به آقای
                        <BoldParagraphText>{data.driverName +' '+ data.driverFamily}</BoldParagraphText>
                        اعزامی اعزامی
                        <BoldParagraphText>{data.fromState}</BoldParagraphText>
                        به شرح اقلام مشروحه ذیل تحویل گردید
                        <>:</>
                    </p>
                    
                    <div className='pb-10'>
                        <TankParams/>
                    </div>

                    <div className='space-y-1 px-3 min-h-[5rem] h-3' dir='rtl'>
                        <p className='text-sm font-black'> توضیحات : </p>
                        <p className='text-xs font-thin leading-loose'>
                            {data.fullDesc}
                        </p>
                    </div>

                    <div className='w-full p-4 pt-24 flex flex-row items-center justify-around flex-wrap-reverse gap-4'>
                        <div className='center flex-row gap-4'>
                            <ShowProtectedImage
                                imageRoute='signaturePhotos'
                                imageName={data.driverSigImg}
                                imageAlt={'عکس امضا ثبت نشده است'} 
                                imageClass={'w-44 max-h-44 object-contain text-center'}
                            />
                            {/* <img
                                className='w-44 max-h-44 object-contain text-center'
                                src={process.env.REACT_APP_API_ADDRESS + 'signaturePhotos' + data.driverSigImg} 
                                alt={'عکس امضا ثبت نشده است'}
                            />   */}
                            <p className='text-sm text-center'>
                                <p>:تحویل گیرنده </p>
                                <p>{data.driverName +' '+ data.driverFamily}</p>
                            </p>
                        </div>

                        <div className='center flex-row gap-4'>
                            <ShowProtectedImage
                                imageRoute={'signaturePhotos'} 
                                imageName={data.userSigImg} 
                                imageAlt={'عکس امضا ثبت نشده است'} 
                                imageClass={'w-44 max-h-44 object-contain text-center'}
                            />
                            {/* <img 
                                className='w-44 max-h-44 object-contain text-center'
                                src={process.env.REACT_APP_API_ADDRESS + 'signaturePhotos' + data.userSigImg} 
                                alt={'عکس امضا ثبت نشده است'}
                            />   */}
                            <p className='text-sm text-center'>
                                <p>:تحویل دهنده </p>
                                <p>{data.userName +' '+ data.userFamily}</p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const RegisteredDate = () => {
        return(
            deliveryDate.map((item, key)=>(
                <p 
                    key={key}
                    onClick={()=>setSelectedDate(item.delivery_id)}
                    className={`${selectedDate === item.delivery_id ? 'border-green-600 text-green-900 ': 'border-zinc-400 '} px-8 py-2 text-sm rounded-lg border-2 bg-transparent hover:shadow-md hover:bg-zinc-50 text-right cursor-pointer hover:scale-[1.02]`}
                    dir='rtl'
                >
                    { item.deliveryDate + ' _ ' + item.name + ' ' + item.family }
                </p>
            ))
        )
    }

    return (
        <div>
            <div className='m-r-nav'>
                {loader && <FullLoader />}
                <Member_Header tankName={tankCode+' لیست گزارشات تحویل ' } desc='برای نمایش فرم گزارش تحویل ابتدا تاریخ مورد نظر را انتخاب کنید'/>

                <div className='center flex-wrap flex-row-reverse p-4 gap-10'>
                    <div className='w-full flex items-end justify-center sm:justify-start flex-row-reverse flex-wrap gap-4' >
                        <RegisteredDate />
                    </div>
                    {
                        selectedDate ?
                            <TankerDeliveryList />
                        :
                            !deliveryDate[0] ? <p>تست تانکی ثبت نشده است</p> : <p>لطفا تاریخ مد نظر را انتخاب کنید</p> 
                    }
                </div>
            </div>
        </div>
    )
}

export default DeliveryReportDetail
