import React, { useState, useEffect } from 'react';
import Admin_Header from './header.tsx';
import { useParams, useNavigate } from 'react-router-dom';
import { SuccessBtn, TransparentLink } from '../../components/btn.tsx';
import axios from 'axios';
import { FullLoader } from '../../components/loader.tsx';
import Cookies from 'js-cookie';
import { loginText, uploadError } from '../../array/array.ts';
import { Modal } from '../../components/modal.tsx';
import { DangerBtn } from '../../components/btn.tsx';
import {ShowProtectedImage} from '../../components/showProtectedImage.tsx';

interface dataType {
    companyName:string;
    chassisNumber:string;
    deliveryDate:string;
    contractNumber:string;
    fromState:string;
    fullDesc:string;
    driverName:string;
    driverFamily:string;
    driverSigImg:string;
    userName:string;
    userFamily:string;
    userSigImg:string;
}

interface listType {
    name: string;
    state: string;
}

const Admin_DeliveryReportDetail = () => {
    const param = useParams();
    const navigate = useNavigate();
    const tank_id = localStorage.getItem('tankId')

    const [loader, setLoader] = useState<boolean>(false)  
    const [refreshPage, setRefreshPage] = useState<number>(0)
    const [selectedDateId, setSelectedDateId] = useState<string>('');
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [data, setData] = useState<dataType>({companyName:'',chassisNumber:'',deliveryDate:'',contractNumber:'',fromState:'',fullDesc:'',driverName:'',driverFamily:'',driverSigImg:'',userName:'',userFamily:'',userSigImg:'',})
    const [list, setList] = useState<listType[]>([])
    const [deliveryDate, setDeliveryDate] = useState<any[]>([])
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    
    useEffect(() => {
        setLoader(true);
        const fetchData = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/deliveryDatesList`,
                {
                    tank_id: tank_id
                } 
                ,{
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                setDeliveryDate(response.data);
                setLoader(false);
            } catch (error) {
                // console.error('Error fetching tanks:', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate('/admin')
            }
        };
    
        fetchData();
    }, [refreshPage]);
    
    useEffect(() => {
        const fetchData = async () => {
            setLoader(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/deliveryList`,
                {
                    delivery_id: selectedDateId
                } 
                ,{
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                const data = response.data[0];
                setData({
                    companyName:data.companyName,
                    chassisNumber:data.chassisNumber,
                    deliveryDate:data.deliveryDate,
                    contractNumber:data.contractNumber,
                    fromState:data.fromState,
                    fullDesc:data.fullDesc,
                    driverName:data.driverName,
                    driverFamily:data.driverFamily,
                    driverSigImg:data.driverSigImg,
                    userName:data.userName,
                    userFamily:data.userFamily,
                    userSigImg:data.userSigImg,
                });
                setList([
                    {name:'وضعیت مخزن',state:data.tankStatus},
                    {name:'جعبه شیر آلات',state:data.valveBox},
                    {name:'گلگیر سه طرف سمت شاگرد',state:data.rightFender},
                    {name:'گلگیر سه طرف سمت راننده',state:data.leftFender},
                    {name:'چراغهای عقب و بغل',state:data.rearSideLights},
                    {name:'فیش برق و کفگرد',state:data.powerPlug},
                    {name:'گل پخش کن',state:data.spreadMud},
                    {name:'زاپاس بند',state:data.spareStrap},
                    {name:'تبدیل شیرآلات',state:data.faucetConversion},
                    {name:'درپوش شیرآلات',state:data.faucetCap},
                    {name:'سوپاپهای اطمینان',state:data.safetyValve},
                    {name:'نردبان',state:data.ladder},
                    {name:'جک تانکر با دسته',state:data.tankerJack},
                    {name:'جغجغه های ترمز',state:data.brakeRatchets},
                    {name:'سپر عقب',state:data.rearBumper},
                    {name:'دستگاه',state:data.machine},
                    {name:'رینگ',state:data.rim},
                    {name:'پیچ و مهره',state:data.boltAndNut},
                    {name:'زنجیر ارت',state:data.earthChain},
                    {name:'زاپاس',state:data.spare},
                    {name:'تانک آب',state:data.waterTank},
                    {name:'جعبه آتش نشانی',state:data.fireBox},
                    {name:'دنده پنج',state:data.fifthGear},
                    {name:'کپسول آتش نشانی',state:data.fireCapsule},
                    {name:'فشار سنج',state:data.barometer},
                    {name:'ترمومتر',state:data.thermometer},
                    {name:'روتور گیج',state:data.rotorGage},
                    {name:'صفحه روتور گیج',state:data.rotorGageDisc},
                    {name:'شیر درصد سنج',state:data.valvePercentageGauge},
                    {name:'سیستم زود بند',state:data.fastClosingSystem},
                    {name:'درصد لنت ترمز',state:'%'+data.brakePad},
                    {name:'درصد لاستیک اکسل جلو سمت راننده', state:'%'+data.wheelPercent3},
                    {name:'نام لاستیک اکسل جلو سمت راننده', state:data.wheelName3},
                    {name:'درصد لاستیک اکسل وسط سمت راننده', state:'%'+data.wheelPercent2},
                    {name:'نام لاستیک اکسل وسط سمت راننده', state:data.wheelName2},
                    {name:'درصد لاستیک اکسل عقب سمت راننده', state:'%'+data.wheelPercent1},
                    {name:'نام لاستیک اکسل عقب سمت راننده', state:data.wheelName1},
                    {name:'درصد لاستیک اکسل جلو سمت شاگرد', state:'%'+data.wheelPercent6},
                    {name:'نام لاستیک اکسل جلو سمت شاگرد', state:data.wheelName6},
                    {name:'درصد لاستیک اکسل وسط سمت شاگرد', state:'%'+data.wheelPercent5},
                    {name:'نام لاستیک اکسل وسط سمت شاگرد', state:data.wheelName5},
                    {name:'درصد لاستیک اکسل عقب سمت شاگرد', state:'%'+data.wheelPercent4},
                    {name:'نام لاستیک اکسل عقب سمت شاگرد', state:data.wheelName4},
                ])
                setLoader(false);
            } catch (error) {
                // console.error('Error fetching tanks:', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate('/admin')
            }
        };
        
        if(selectedDateId){
            fetchData();
        }
    }, [selectedDateId]);
    
    const handlerDeleteQuery = async () => {
        setLoader(true)
        axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/deleteDeliveryListDates`, 
            {
                delivery_id: selectedDateId,
                // ------ if select last date - replace tanl driver to prevos driver else update last -----
                lastDelivery_id: deliveryDate.length === 1 ? 1 : selectedDateId === deliveryDate[0].delivery_id ? deliveryDate[1].driver_id : deliveryDate[0].driver_id,
                tank_id: tank_id
            },
            {
                headers: {
                    'auth-token': Cookies.get('token'),
                    'auth-id': Cookies.get('id')
                }
            })
            .then(response => {
                // console.log('Response:', response.data);
                // alert(` گزارش تحویل ${selectedDate} حذف شد`)
                setLoader(false)
                setSelectedDateId('')
                setSelectedDate('')
                setRefreshPage((prev) => prev + 1)
                setOpenDeleteModal(false)
            })
            .catch(error => {
                console.error('Error:', error);
                setOpenDeleteModal(false)
                // alert(loginText)
                alert(uploadError)
                // navigate('/admin')
                setLoader(false); // Hide loader in case of an error
            }
        )
    };

    const TankerDeliveryList = () => {
        function BoldParagraphText({children}:{children:React.ReactNode}) {
            return(
                <span className=' underline underline-offset-4 text-black font-bold px-1'>
                    {" "+children+" "}
                </span>
            )
        }
        function TankParams() {
            return(
                <div className='w-full grid grid-cols-1 sm:grid-cols-2 sm:grid-rows-[repeat(22,minmax(0,1fr))] grid-flow-row sm:grid-flow-col' dir='rtl'>
                {/* <div className='w-full grid grid-cols-1 grid-flow-row' dir='rtl'> */}
                    {
                        list.map((item, key)=>(
                            <div key={key} className={`flex flex-row items-start px-4 py-2 border-b-2 ${key < 22 && 'sm:border-l-2'}`}>
                                <p className='w-2/3 text-sm'>
                                    <span>{key+1}- </span>
                                    <span>{item.name}</span>
                                </p>
                                <p className='w-1/3 text-sm text-center'>
                                    {item.state}
                                </p>
                            </div>
                        ))
                    }
                </div>
            )
        }
        // function handlerPrint() {
        //     const dataToSend = encodeURIComponent(JSON.stringify(selectedDateId));
        //     window.open(`/admin/printableDeliveryReport?data=${dataToSend}`,"_self");
        //     // window.open(`/admin/printableDeliveryReport?data=${dataToSend}`, '_blank');
        // }
        return(
            <div className=''>
                <div className='w-full flex justify-center py-4' >
                    <TransparentLink to='/admin/printableDeliveryReport' params={{data,list}}>چاپ فرم</TransparentLink>
                </div>
                <div id={'printFinalTank'} className='w-full border-2 border-black rounded-md p-4 space-y-2'>
                    <p className='w-full text-center text-lg font-black'>فرم گزارش تحویل مخزن گاز مایع به راننده</p>
                    
                    <p className='w-full text-justify text-gray-700 leading-loose text-sm' dir={'rtl'}>
                        بدینوسیله یک مخزن حمل گاز مایع ساخت کارخانه
                        <BoldParagraphText>{data.companyName}</BoldParagraphText>
                        به شماره شاسی
                        <BoldParagraphText>{data.chassisNumber}</BoldParagraphText>
                        در تاریخ
                        <BoldParagraphText>{data.deliveryDate}</BoldParagraphText>
                        طبق قرارداد شماره
                        <BoldParagraphText>{data.contractNumber}</BoldParagraphText>
                        به آقای
                        <BoldParagraphText>{data.driverName +' '+ data.driverFamily}</BoldParagraphText>
                        اعزامی اعزامی
                        <BoldParagraphText>{data.fromState}</BoldParagraphText>
                        به شرح اقلام مشروحه ذیل تحویل گردید
                        <>:</>
                    </p>
                    
                    <div className='pb-10'>
                        <TankParams/>
                    </div>

                    <div className='space-y-1 px-3 min-h-[5rem] h-3' dir='rtl'>
                        <p className='text-sm font-black'> توضیحات : </p>
                        <p className='text-xs font-thin leading-loose'>
                            {data.fullDesc}
                        </p>
                    </div>

                    <div className='w-full p-4 pt-24 flex flex-row items-center justify-around flex-wrap-reverse gap-4'>
                        <div className='center flex-row gap-4'>
                            <ShowProtectedImage
                                imageRoute='signaturePhotos'
                                imageName={data.driverSigImg}
                                imageAlt={'عکس امضا ثبت نشده است'} 
                                imageClass={'w-44 max-h-44 object-contain text-center'}
                            />
                            {/* <img
                                className='w-44 max-h-44 object-contain text-center'
                                src={process.env.REACT_APP_API_ADDRESS + 'signaturePhotos' + data.driverSigImg} 
                                alt={'عکس امضا ثبت نشده است'}
                            />   */}
                            <p className='text-sm text-center'>
                                <p>:تحویل گیرنده </p>
                                <p>{data.driverName +' '+ data.driverFamily}</p>
                            </p>
                        </div>

                        <div className='center flex-row gap-4'>
                            <ShowProtectedImage 
                                imageRoute={'signaturePhotos'} 
                                imageName={data.userSigImg} 
                                imageAlt={'عکس امضا ثبت نشده است'} 
                                imageClass={'w-44 max-h-44 object-contain text-center'}
                            />
                            {/* <img 
                                className='w-44 max-h-44 object-contain text-center'
                                src={process.env.REACT_APP_API_ADDRESS + 'signaturePhotos' + data.userSigImg} 
                                alt={'عکس امضا ثبت نشده است'}
                            />   */}
                            <p className='text-sm text-center'>
                                <p>:تحویل دهنده </p>
                                <p>{data.userName +' '+ data.userFamily}</p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    const RegisteredDate = () => {
        function handlerRegisteredDate(item) {
            setSelectedDateId(item.delivery_id)
            setSelectedDate(item.deliveryDate)
        }
        return(
            deliveryDate.map((item, key)=>(
                <p 
                    key={key}
                    onClick={()=>handlerRegisteredDate(item)}
                    className={`${selectedDateId === item.delivery_id ? 'border-green-600 text-green-900 ': 'border-zinc-400 '} px-8 py-2 text-sm rounded-lg border-2 bg-transparent hover:shadow-md hover:bg-zinc-50 text-right cursor-pointer hover:scale-[1.02]`}
                    dir='rtl'
                >
                    { item.deliveryDate + ' _ ' + item.name + ' ' + item.family }
                </p>
            ))
        )
    }

    return (
        <div>
            {loader && <FullLoader />}
            <Admin_Header title={param.name+' گزارشات تحویل تانک'} desc='برای نمایش فرم گزارش تحویل ابتدا تاریخ مورد نظر را انتخاب کنید' backTo={'/admin/deliveryReport'}/>

            <div className='m-r-nav'>
                <p className='text-sm text-gray-600 p-4 pt-6 pb-2' dir='rtl'>
                    <span className='text-gray-800 pt-8'> نکته : </span>
                    برای ویرایش یا حذف گزارش انتخابی به پایین صفحه مراجعه کنید.
                </p>

                <div className='center flex-wrap flex-row-reverse p-4 gap-10'>
                    <div className='w-full flex items-end justify-center sm:justify-start flex-row-reverse flex-wrap gap-4' >
                        <RegisteredDate />
                    </div>
                    {
                        selectedDateId && data?
                            <>
                                <TankerDeliveryList />

                                {
                                    localStorage.getItem('tank22to46') !== 'مدیر تانک 22 تا 46' ?
                                        <div className='w-full center flex-row flex-wrap-reverse gap-6 mb-6'>
                                            <DangerBtn onClick={()=>setOpenDeleteModal(true)} cssClass={'mt-0'}>
                                                <p className='px-4 py-1'>حذف گزارش تحویل تاریخ {selectedDate}</p>
                                            </DangerBtn>
                                            <SuccessBtn onClick={()=>navigate('/admin/deliveryReport/editDeliveryReport/'+localStorage.getItem('tankCode'),{state:{delivery_id: selectedDateId}})} cssClass={'mt-0'}>
                                                <p className='px-4 py-1'>ویرایش گزارش تحویل تاریخ {selectedDate}</p>
                                            </SuccessBtn>
                                        </div>
                                    :
                                        null
                                }
                                
                            </>
                        :
                            !deliveryDate[0] ? <p> گزارش تحویل ثبت نشده است</p> : <p>لطفا تاریخ مد نظر را انتخاب کنید</p> 
                    }
                </div>
            </div>

            <Modal 
                title='حذف گزارش'
                body={"میخوای گزارش تاریخ " + selectedDate + " حذف بشه؟ "}
                icon='warning'
                onOpen={openDeleteModal}
                btnCancel={setOpenDeleteModal}
                btnDelete={handlerDeleteQuery}
            />
        </div>
    )
}

export default Admin_DeliveryReportDetail
