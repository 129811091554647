import React, { useState, useEffect } from 'react';
import Member_Header from './header.tsx';
import { AdminTableList } from '../../components/adminTableList.tsx';
import { Modal } from '../../components/modal.tsx';
import axios from 'axios';
import { SerachInput, ConvertPersianNumToEnglishNum, handlerGetUsersTanks } from '../../components/common.tsx';
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText, uploadError } from '../../array/array.ts';
import { TransparentLink } from '../../components/btn.tsx';

const defaultArrayValue = {
    tank_id:0, 
    tankCode:'', 
    tankNumber:undefined, 
    chassisNumber:undefined
  }

const tableHead = [
    {pName:"کد تانکر", eName:"tankCode", type:"string/number", sorted:true, showAllData:true},
    {pName:"شماره مخزن", eName:"tankNumber", type:"number", sorted:true, showAllData:true},
    {pName:"شماره شاسی", eName:"chassisNumber", type:"number", sorted:true, showAllData:true},
]
  
const tableHeadFull = [
    {pName:"نام راننده", eName:"name", type:"string",},
    {pName:"نام خانوادگی راننده", eName:"family", type:"string",},
    {pName:"شماره تماس راننده", eName:"number", type:"number",},
    {pName:"شماره حساب راننده", eName:"cartNumber", type:"number",},
    {pName:"شماره پلاک", eName:"plateNumber", type:"string/number",},
    {pName:"کد تانکر", eName:"tankCode", type:"string/number",},
    {pName:"شماره شاسی", eName:"chassisNumber", type:"string/number",},
    {pName:"شماره مخزن", eName:"tankNumber", type:"string/number",},
]

interface showModalDataTypes {
    id:number, 
    tankCode:string, 
    tankNumber?:number, 
    chassisNumber?:number,
    name?:string,
    family?:string,
    number?:number,
    plateNumber?:string,
}

interface updateModalDataTypes {
    tank_id:number, 
    tankCode:string, 
    tankNumber?:number, 
    chassisNumber?:number,
}

export default function Tanks() {
    const navigate = useNavigate();

    const [data, setData] = useState<any[]>([])
    const [filteredArray, setFilteredArray] = useState<any[]>([])
    const [loader, setLoader] = useState<boolean>(false)
    const [refreshPage, setRefreshPage] = useState<number>(0)
    const [searchItem, setSearchItem] = useState<string>('')
    const [openShowModal, setOpenShowModal] = useState<boolean>(false)
    const [showModalData, setShowModalData] = useState<showModalDataTypes>({id:0, tankCode:'', tankNumber:undefined, chassisNumber:undefined, name:'', family:'', number:undefined, plateNumber:''})
    const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false)
    const [updateModalData, setUpdateModalData] = useState<updateModalDataTypes>(defaultArrayValue)
  
    useEffect(() => {
        setLoader(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}users/tanksList`, {
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                handlerGetUsersTanks(setData, setFilteredArray, response.data)
                setLoader(false);
            } catch (error) {
                // console.error('Error fetching tanks:', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate('/member')
            }
        };
    
        fetchData();
    }, [refreshPage]);
    
    const SearchTableData = () => { 
        const handleInputSearch = (e) => { 
            const searchTerm = ConvertPersianNumToEnglishNum(e.target.value);
            setSearchItem(searchTerm)
        
            const filteredItems = data.filter((item) =>
                item.tankCode && item.tankCode.toUpperCase().includes(searchTerm.toUpperCase())
                // item.tankNumber && item.tankNumber.toUpperCase().includes(searchTerm.toUpperCase()) ||
                // item.tankChassis&& item.tankChassis.toUpperCase().includes(searchTerm.toUpperCase()) 
            );

            setFilteredArray(filteredItems);
        }
        return (
            <div className='w-full flex flex-row flex-wrap-reverse gap-2'>
                <TransparentLink to={'/member/printList/'} params={{array:filteredArray, listHead:tableHeadFull , pageHeader:'LPG لیست کپسول های گاز'}}>
                    فرم چاپی (لیست تانک ها و راننده ها)
                </TransparentLink>
                <SerachInput title="جستجوی تانک" state={searchItem} handler={handleInputSearch}/>
            </div>
        )
    }
    
    const ShowModalBody = () => {
        return(
          <ul className='w-[20rem] sm:w-[25rem] space-y-3 sm:space-y-2'>
            {
              tableHeadFull.map((item,key)=>(
                <li key={key} className='flex flex-row items-center'>
                  <span className='text-sm w-2/5'>{item.pName}:</span>
                    <input
                      type="text" 
                      disabled
                      id={item.eName}
                      className="p-2 w-full sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " 
                      placeholder={item.pName}
                      value={showModalData[item.eName]} 
                    />
                </li>
              ))
            }
          </ul>
        )
    }
    
    const handlerShowFunc = (item: showModalDataTypes) => {
        setOpenShowModal(true)
        setShowModalData({id:item.id, tankCode:item.tankCode, tankNumber:item.tankNumber, chassisNumber:item.chassisNumber, name:item.name, family:item.family, number:item.number, plateNumber:item.plateNumber })
    }

    const handlerUpdateFunc = (item: updateModalDataTypes) => {
        setOpenUpdateModal(true)
        setUpdateModalData({tank_id:item.tank_id, tankCode:item.tankCode, tankNumber:item.tankNumber, chassisNumber:item.chassisNumber })
    }

    const handlerUpdateQuery = async () => {
        setLoader(true)
        axios.post(`${process.env.REACT_APP_API_ADDRESS}users/updateTank`, 
            {
                user_id: Cookies.get('id'),
                tankCode: ConvertPersianNumToEnglishNum(updateModalData.tankCode.toLocaleUpperCase()),
                tankNumber: ConvertPersianNumToEnglishNum(updateModalData.tankNumber),
                chassisNumber: ConvertPersianNumToEnglishNum(updateModalData.chassisNumber),
                tank_id: updateModalData.tank_id
            },
            {
                headers: {
                    'auth-token': Cookies.get('token'),
                    'auth-id': Cookies.get('id')
                }
            })
            .then(response => {
                // console.log('Response:', response.data);
                setLoader(false)
                // alert('تانک به درستی ویرایش شد')
                setRefreshPage((prev) => prev + 1)
                setOpenUpdateModal(false)
            })
            .catch(error => {
                // console.error('Error:', error);
                setLoader(false); // Hide loader after fetching data
                alert(uploadError)
                // alert(loginText)
                // navigate('/member')
            }
        )
    };

    const UpdateModalBody = () => {
        return(
          <div className='w-[20rem] sm:w-[25rem] space-y-2'>
            {
              tableHead.map((item,key)=>(
                <div key={key} className='flex flex-row items-center'>
                  <span className='text-sm w-2/5'>{item.pName}:</span>
                    <input
                      type="text" 
                      id={item.pName}
                      className="p-2 w-full sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " 
                      placeholder={item.pName}
                      value={updateModalData[item.eName]} 
                      onChange={e => setUpdateModalData(prevState => ({...prevState,[item.eName]: e.target.value}))}
                    />
                </div>
              ))
            }
          </div>
        )
    }

    return (
        loader ? <FullLoader /> :
            <>
                <Member_Header hideDrawer title='لیست تانک ها'/>

                <p className='font-bold p-6 pt-0 w-full text-center'>
                    توضیحات: ابتدا تانک مورد نظر را انتخاب کرده. سپس دکمه انتخاب را کلیک کنید تا وارد صفحه ی گزارشات شوید
                </p>

                {
                    loader ?
                        <></>
                    :
                        <div className='px-4 mb-4 sm:px-12'>
                            {SearchTableData()}
                            <AdminTableList 
                                TableList={filteredArray} 
                                tableHeadParams={tableHead} 
                                handlerShowFunc={handlerShowFunc}
                                handlerUpdateFunc={handlerUpdateFunc}
                                linkTo={'/member/home'}
                            />
                        </div>
                }

                <Modal 
                    title={'جزئیات تانکر'}
                    body={<ShowModalBody/>}
                    onOpen={openShowModal}
                    btnCancel={setOpenShowModal}
                />

                <Modal 
                    title='ویرایش تانکر'
                    body={UpdateModalBody()}
                    onOpen={openUpdateModal}
                    btnCancel={setOpenUpdateModal}
                    btnSubmit={handlerUpdateQuery}
                />
            </>
    );
}