import React, { useState, useEffect } from 'react'
import { CustomBtn } from '../../components/btn.tsx'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { FullLoader } from '../../components/loader.tsx'
import Cookies from 'js-cookie';
import { ConvertPersianNumToEnglishNum } from '../../components/common.tsx'
import { Link } from 'react-router-dom'
import { FaArrowLeft } from "react-icons/fa";
import { serverDeactivateLogintime, serverLoginCount } from '../../array/array.ts'

const Admin_Login = () => {
    const navigate = useNavigate()
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);
    const [loginCount, setLoginCount] = useState<number>(serverLoginCount)

    function handlerUsername(e) {
        if(e.target.value.length <= 10){
            setUsername(e.target.value)
        }
    }

    useEffect(()=>{
        if(loginCount <= 0){setLoginCount(serverLoginCount)}
    },[loginCount])

    function handlerLogin() {
        if(username && password){
            setLoader(true)
            axios.post(`${process.env.REACT_APP_API_ADDRESS}userLogin`, 
            {
                username: ConvertPersianNumToEnglishNum(username),
                password: ConvertPersianNumToEnglishNum(password),
                position: 'admin'
            },
            {
                headers: {
                    'auth-token': process.env.REACT_APP_SECRETKEY,
                }
            })
            .then(response => {
                Cookies.set('token', response.data.token, { expires: 0.5, secure: true });
                Cookies.set('id', response.data.id, { expires: 0.5, secure: true });
                // ---- for show costom tanker for costom user ----
                if(response.data.userPosition === 'مدیر تانک 22 تا 46'){
                    localStorage.setItem('tank22to46', response.data.userPosition);
                }else{
                    localStorage.removeItem('tank22to46')
                }
                localStorage.setItem('userInfo', response.data.userInfo)
                navigate('/admin/home')
                // console.log(response.data)
            })
            .catch(error => {
                setUsername('')
                setPassword('')
                if(error.response.status === 429){
                    alert(`کاربر گرامی شما بیش تر از ${serverLoginCount} دفعه ورود نامعتبر داشتید. حساب کاربری شما به مدت ${serverDeactivateLogintime} دقیقه مسدود خواهد بود`);
                } else {
                    setLoginCount(loginCount-1)
                    alert(`نام کاربری یا رمز عبور اشتباه است. تنها ${loginCount} فرصت دیگر باقی مانده.`);
                }
                setLoader(false);
            });
        }
        else if(!username){alert('لطفا کد ملی خود را وارد کنید')}
        else if(!password){alert('لطفا پسورد خود را وارد کنید')}
    }

    function handlerSubmit() {
        if(username.length <=0){
            alert('نام کاربری الزامی است.');
        }
        else if(username.length >= 12){
            alert('نام کاربری نامعتبر');
        }
        else if(password.length <= 0){
            alert('پسورد الزامی است.');
        }
        else if(password.length >= 21){
            alert('پسورد نامعتبر');
        }
        else{
            handlerLogin()
        }
    }

    return (
        <section className="bg-gradient-to-r from-emerald-400 to-cyan-400 w-screen h-screen">
            {loader && <FullLoader />}
            <div className='fixed top-1 left-1'>
                <a onClick={()=>navigate('/')} className='p-4 cursor-pointer hover:underline underline-offset-4 text-zinc-100 flex flex-row gap-2 '>
                    <FaArrowLeft className=' text-zinc-100 '/>
                    <p className='text-sm text-zinc-100 '>برگشت به صفحه اصلی</p>
                </a>
            </div>

            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto w-full h-full lg:py-0">
                {/* <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img className="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo"/>
                    Flowbite    
                </a> */}
                <div className="w-full h-fit bg-white rounded-lg md:mt-0 sm:max-w-md xl:p-0 shadow-xl">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <p className="text-xl font-bold text-gray-900 md:text-2xl text-center select-none">
                            ورود به پنل ادمین
                        </p>
                        <div className="space-y-4 md:space-y-6">
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 text-right">کد ملی</label>
                                <input 
                                    dir='rtl' 
                                    type="text" 
                                    name="AdminUsername" 
                                    id="AdminUsername"                                     
                                    placeholder="کد ملی"
                                    onChange={handlerUsername}
                                    value={username}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-pink-600 focus:border-pink-600 block w-full p-2.5" 
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 text-right">پسورد</label>
                                <input 
                                    dir='rtl' 
                                    type="password" 
                                    name="AdminPassword" 
                                    id="AdminPassword" 
                                    placeholder="••••••••" 
                                    onChange={e=>setPassword(e.target.value)}
                                    value={password}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 block w-full p-2.5"
                                />
                            </div>
                            {/* <div className="flex items-center justify-end">
                                <Link to={'/forgetPassword'} className="text-sm font-medium text-slate-600 hover:underline cursor-pointer">فراموشی رمز عبور یا نام کاربری؟</Link>
                            </div> */}
                            <div className='w-full text-right'>
                                <CustomBtn onClick={handlerSubmit} cssClass={'bg-emerald-500 hover:bg-emerald-600'}>
                                    ورود
                                </CustomBtn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Admin_Login
