import React,{useState, useEffect} from 'react'
import Cookies from 'js-cookie'
import { json } from 'react-router-dom';

interface ShowProtectedImageType {
    imageFullRoute?:string;
    imageRoute?: string;
    imageName?: string;
    imageAlt?: string;
    imageClass?: string;
}

export function ShowProtectedImage ({imageFullRoute, imageRoute, imageName, imageAlt, imageClass}:ShowProtectedImageType) {
    const [imageData, setImageData] = useState(null);

    useEffect(() => {
      const fetchImage = async () => {
        const imageUrl = imageFullRoute ? imageFullRoute : process.env.REACT_APP_API_ADDRESS + imageRoute + imageName;
        const headers = {
          'auth-token': Cookies.get('token'),
          'auth-id': Cookies.get('id')
        };

        const response = await fetch(imageUrl, { headers });
        const blob = await response.blob();
        setImageData(URL.createObjectURL(blob));
      };

      fetchImage();
    }, [imageName]);

    return imageData && <img src={imageData} alt={imageAlt} className={imageClass}/>
}
