import React,{useEffect, useState} from 'react'
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { defaultStateVal } from '../array/array.ts'
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { Link } from 'react-router-dom';
import { IoSearch } from "react-icons/io5";
// import { PrimaryBtn, SuccessBtn } from './btn.tsx';
import { HiOutlineCloudUpload } from "react-icons/hi";
import Resizer from 'react-image-file-resizer';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-jalaali';
import persianJs from 'persianjs'
import { ShowProtectedImage } from './showProtectedImage.tsx';

interface ParamStatusTypes {
    val?: 'yesNo' | 'completeIncomplete' | 'healthyFaulty' | 'wheelsPercent' | 'yesNoNumber' 
    state: string | number | undefined;
    setState: React.Dispatch<React.SetStateAction<string>> | React.Dispatch<React.SetStateAction<number>> ;
    dropTitle?: string;
    list?:any
    top?: boolean,
    wFit?:boolean,
    cssClass?:string
}

interface DatePickerTypes {
    state: string | undefined;
    setState?: React.Dispatch<React.SetStateAction<string>> | any ;
    title: string;
    type?: 'simple' | 'custom' | 'pureDate',
    disable?:boolean
}

interface imageType {
    url: string;
    size: number | undefined;
    type: string;
    base64: string;
}

interface imageCardType {
    title:string;
    state:imageType;
    setState:React.Dispatch<React.SetStateAction<imageType>>;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    disable?:boolean
}

interface CustomInputType {
    state: string | number | undefined;
    setState?: React.Dispatch<React.SetStateAction<string>> | React.Dispatch<React.SetStateAction<number | undefined>> | undefined ;
    label: string;
    length: number;
    width?: number;
    height?: number;
    disable?: boolean;
    type?: string;
    cssClass?:string;
}

interface SerachInputType {
    title:string;
    state:string;
    handler:React.ChangeEventHandler<HTMLInputElement> | undefined;
    customWidth?:string
}

interface HomeBigBtnType {
    title:string;
    color:string;
    link:string
}

export function ItemContainer({children}) {
    return(
        <div className='my-10 flex flex-row-reverse items-center justify-center flex-wrap gap-4 sm:gap-8 w-full'>
            {children}
        </div>
    )
}

// -------- شامل ۳ مقدار ندارد و دارد سالم و دارد معیوب -------
export function DropDownThreeStatus({state, setState, dropTitle}:ParamStatusTypes) {

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const paramsStateArray = [{title:'ندارد'},{title:'دارد و سالم'},{title:'دارد و معیوب',}]

    function handlerDropBox() {
        setIsOpen(!isOpen)
    }

    function handlerDropItem(data:any) {
        setIsOpen(false);
        setState(data);
    }
    
    return(
        <div className="relative inline-block text-left mb-10">
            <div>
                <button 
                    type="button" 
                    onClick={handlerDropBox} 
                    className={`inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-2 ring-inset hover:bg-gray-50 min-w-64 focus:ring-blue-500 ${ state === defaultStateVal ? " ring-gray-300 ":" ring-green-500 "}`}
                    id="menu-button" 
                    aria-expanded="true" 
                    aria-haspopup="true"
                >
                    <span className='text-neutral-600'>{dropTitle} ({state})</span>
                    {
                        isOpen?<SlArrowUp size={'.8rem'}/>:<SlArrowDown size={'.8rem'}/>
                    }
                </button>
            </div>

            {/* ----- for close modol while click enywhere ----- */}
            <div 
                className={isOpen?"fixed z-10 w-screen h-screen inset-0":""}
                onClick={()=> isOpen && setIsOpen(false)}
            />

            <div className={`${isOpen?"block":"hidden"} absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-gray-50 shadow-lg ring-2 ring-blue ring-opacity-5 focus:outline-none`} >
                <div className="divide-y-2" role="none">
                    {
                        paramsStateArray.map((item, key)=>(
                            <p 
                                key={key} 
                                onClick={()=>handlerDropItem(item.title)}
                                className={`${state == item.title && 'bg-blue-100'} text-center text-sm text-neutral-800 p-4 cursor-pointer hover:bg-blue-50 `}
                            >
                                {item.title}
                            </p>
                        ))
                    }
                </div>
            </div>
            
        </div>
    )
}

export function DropDownTwoStatus({val, state, setState, dropTitle, top}:ParamStatusTypes) {

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const YesNo = [{title:'دارد'}, {title:'ندارد'}]
    const HealthyFaulty = [{title:'سالم'}, {title: 'معیوب'}]
    const CompleteIncomplete = [{title: 'کامل'}, {title: 'ناقص'}]
    const paramsStateArray = val === 'yesNo' ? YesNo : val === 'healthyFaulty' ? HealthyFaulty : CompleteIncomplete  ;

    function handlerDropBox() {
        setIsOpen(!isOpen)
    }

    function handlerDropItem(data:any) {
        setIsOpen(false);
        setState(data);
    }
    
    return(
        <div className="relative inline-block text-left mb-10">
            <div>
                <button 
                    type="button" 
                    onClick={handlerDropBox} 
                    className={`inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-2 ring-inset hover:bg-gray-50 min-w-64 focus:ring-blue-500 ${ state === defaultStateVal ? " ring-gray-300 ":" ring-green-500 "}`}
                    id="menu-button" 
                    aria-expanded="true" 
                    aria-haspopup="true"
                >
                    <span className='text-neutral-600'>{dropTitle} ({state})</span>
                    {
                        isOpen?<SlArrowUp size={'.8rem'}/>:<SlArrowDown size={'.8rem'}/>
                    }
                </button>
            </div>

            {/* ----- for close modol while click enywhere ----- */}
            <div 
                className={isOpen?"fixed z-10 w-screen h-screen inset-0":""}
                onClick={()=> isOpen && setIsOpen(false)}
            />

            {/* <div className={`${isOpen?"block":"hidden"} absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-gray-50 shadow-lg ring-2 ring-blue ring-opacity-5 focus:outline-none`} > */}
            <div className={`${isOpen?"block":"hidden"} ${top ? 'bottom-10' : 'mt-2'} absolute right-0 z-20 w-64 origin-top-right rounded-md bg-gray-50 shadow-lg ring-2 ring-blue ring-opacity-5 focus:outline-none`} >
                <div className="divide-y-2" role="none">
                    {
                        paramsStateArray.map((item, key)=>(
                            <p 
                                key={key} 
                                onClick={()=>handlerDropItem(item.title)}
                                className={`${state == item.title && 'bg-blue-100'} text-center text-sm text-neutral-800 p-4 cursor-pointer hover:bg-blue-50 `}
                            >
                                {item.title}
                            </p>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export function DropDownDefualtValue({state, setState, dropTitle, list, wFit, cssClass}:ParamStatusTypes) {

    const [isOpen, setIsOpen] = useState<boolean>(false)

    function handlerDropBox() {
        setIsOpen(!isOpen)
    }

    function handlerDropItem(data:any) {
        setIsOpen(false);
        setState(data);
    }
    
    return(
        <div className={`${!cssClass && 'mb-10'} relative inline-block text-left `}>
            <div>
                <button 
                    type="button" 
                    onClick={handlerDropBox} 
                    className={`${wFit ? ' w-fit px-4 ' : ' min-w-64 '} inline-flex justify-center items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-2 ring-inset hover:bg-gray-50  focus:ring-blue-500 ${ state === defaultStateVal ? " ring-gray-300 ":" ring-green-500 "}`}
                    id="menu-button" 
                    aria-expanded="true" 
                    aria-haspopup="true"
                >
                    <span className='text-neutral-600'>{dropTitle} ({state})</span>
                    {
                        isOpen?<SlArrowUp size={'.8rem'}/>:<SlArrowDown size={'.8rem'}/>
                    }
                </button>
            </div>

            {/* ----- for close modol while click enywhere ----- */}
            <div 
                className={isOpen?"fixed z-10 w-screen h-screen inset-0":""}
                onClick={()=> isOpen && setIsOpen(false)}
            />

            <div className={`${isOpen?"block":"hidden"} absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-gray-50 shadow-lg ring-2 ring-blue ring-opacity-5 focus:outline-none`} >
                <div className="divide-y-2" role="none">
                    {
                        list.map((item, key)=>(
                            <p 
                                key={key} 
                                onClick={()=>handlerDropItem(item.title)}
                                className={`${state == item.title && 'bg-blue-100'} text-center text-sm text-neutral-800 p-4 cursor-pointer hover:bg-blue-50 `}
                            >
                                {item.title}
                            </p>
                        ))
                    }
                </div>
            </div>
            
        </div>
    )
}

export function DropDownPercentStatus({val, state, setState, dropTitle, top}:ParamStatusTypes) {

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const wheelsPercent: { percent: number|string }[] = [{percent: 'ندارد'}];
    for (let i = 5; i <= 100; i += 5) {
        wheelsPercent.push({ percent: i });
    }

    function handlerDropBox() {
        setIsOpen(!isOpen)
    }

    function handlerDropItem(data:any) {
        setIsOpen(false);
        setState(data);
    }
    
    return(
        <div className="relative inline-block text-left">
            <div>
                <button 
                    type="button" 
                    onClick={handlerDropBox} 
                    className={`inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-2 ring-inset hover:bg-gray-50 min-w-64 focus:ring-blue-500 ${ state === defaultStateVal ? " ring-gray-300 ":" ring-green-500 "}`}
                    id="menu-button" 
                    aria-expanded="true" 
                    aria-haspopup="true"
                >
                    <span className='text-neutral-600'>{dropTitle} ({state})</span>
                    {
                        isOpen?<SlArrowUp size={'.8rem'}/>:<SlArrowDown size={'.8rem'}/>
                    }
                </button>
            </div>

            {/* ----- for close modol while click enywhere ----- */}
            <div 
                className={isOpen?"fixed z-10 w-screen h-screen inset-0":""}
                onClick={()=> isOpen && setIsOpen(false)}
            />

            {/* <div className={`${isOpen?"block":"hidden"} absolute right-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-gray-50 shadow-lg ring-2 ring-blue ring-opacity-5 focus:outline-none`} > */}
            <div className={`${isOpen?"block":"hidden"} ${top ? 'bottom-10' : 'mt-2'} absolute right-0 z-10 w-64 origin-top-right rounded-md bg-gray-50 shadow-lg ring-2 ring-blue ring-opacity-5 focus:outline-none`} >
                <div className="divide-y-2 divide-x-2 grid grid-cols-4" role="none">
                    {
                        wheelsPercent.map((item, key)=>(
                            key === 0 ?
                                <p 
                                    key={key} 
                                    onClick={()=>handlerDropItem(item.percent)}
                                    className='col-span-4 text-center text-sm text-neutral-800 p-4 cursor-pointer hover:bg-blue-100'
                                >
                                    {item.percent}
                                </p>
                            :
                                <p 
                                    key={key} 
                                    onClick={()=>handlerDropItem(item.percent)}
                                    className={`${state == item.percent && 'bg-blue-100'} text-center text-sm text-neutral-800 p-4 cursor-pointer hover:bg-blue-50 `}
                                    >
                                    ٪ {item.percent}
                                </p>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export function DropDownSearchStatus({state, setState, list, top }:ParamStatusTypes) {

    const [isOpen, setIsOpen] = useState<boolean>(false)

    function handlerDropBox() {
        setIsOpen(!isOpen)
    }

    function handlerDropItem(data:any) {
        setIsOpen(false);
        setState(data);
    }
    
    return(
        <div className="relative inline-block">
            <div>
                <button 
                    type="button" 
                    onClick={handlerDropBox} 
                    className={`inline-flex justify-center min-w-40 items-center gap-x-1.5 rounded-lg bg-white h-9 px-2 text-xs text-gray-900 shadow-sm ring-2 ring-inset hover:bg-gray-50 focus:ring-blue-500 ring-gray-300 `}
                    id="menu-button" 
                    aria-expanded="true" 
                    aria-haspopup="true"
                >
                    <span className='text-neutral-600 text-xs'> جستجو بر اساس {state}</span>
                    {
                        isOpen?<SlArrowUp size={'.8rem'}/>:<SlArrowDown size={'.8rem'}/>
                    }
                </button>
            </div>

            {/* ----- for close modol while click enywhere ----- */}
            <div 
                className={isOpen?"fixed z-10 w-screen h-screen inset-0":""}
                onClick={()=> isOpen && setIsOpen(false)}
            />

            {/* <div className={`${isOpen?"block":"hidden"} absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-gray-50 shadow-lg ring-2 ring-blue ring-opacity-5 focus:outline-none`} > */}
            <div className={`${isOpen?"block":"hidden"} ${top ? 'bottom-10' : 'mt-2'} absolute right-0 z-10 w-64 origin-top-right rounded-md bg-gray-50 shadow-lg ring-2 ring-blue ring-opacity-5 focus:outline-none`} >
                <div className="divide-y-2" role="none">
                    {
                        list.map((item, key)=>(
                            <p 
                                key={key} 
                                onClick={()=>handlerDropItem(item.pName)}
                                className={`${state == item.pName && 'bg-blue-100'} text-center text-sm text-neutral-800 p-4 cursor-pointer hover:bg-blue-50 `}
                            >
                                {item.pName}
                            </p>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export function DropDownStatus({state, setState, list, top }:ParamStatusTypes) {

    const [isOpen, setIsOpen] = useState<boolean>(false)

    function handlerDropBox() {
        setIsOpen(!isOpen)
    }

    function handlerDropItem(data:any) {
        setIsOpen(false);
        setState(data);
    }
    
    return(
        <div className="relative inline-block w-full">
            <div>
                <button 
                    type="button" 
                    onClick={handlerDropBox} 
                    className={`inline-flex w-full justify-center items-center gap-x-1.5 rounded-lg bg-white h-9 px-2 text-xs text-gray-900 shadow-sm ring-2 ring-inset hover:bg-gray-50 focus:ring-blue-500 ring-gray-300 `}
                    id="menu-button" 
                    aria-expanded="true" 
                    aria-haspopup="true"
                >
                    <span className='text-neutral-600 text-xs'>{state}</span>
                    {
                        isOpen?<SlArrowUp size={'.8rem'}/>:<SlArrowDown size={'.8rem'}/>
                    }
                </button>
            </div>
            
            {/* ----- for close modol while click enywhere ----- */}
            <div 
                className={isOpen?"fixed z-10 w-screen h-screen inset-0":""}
                onClick={()=> isOpen && setIsOpen(false)}
            />

            <div className={`${isOpen?"block":"hidden"} ${top ? 'bottom-10' : 'mt-2'} absolute right-0 z-10 w-64 origin-top-right rounded-md bg-gray-50 shadow-lg ring-2 ring-blue ring-opacity-5 focus:outline-none`} >
                <div className="divide-y-2" role="none">
                    {
                        list.map((item, key)=>(
                            <p 
                                key={key} 
                                onClick={()=>handlerDropItem(item.pName)}
                                className={`${state == item.pName && 'bg-blue-100'} text-center text-sm text-neutral-800 p-4 cursor-pointer hover:bg-blue-50 `}
                            >
                                {item.pName}
                            </p>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export function DatePicketComponent({title, state, setState, type, disable}:DatePickerTypes) {
    // ------ on datePicker open, disable keyboard and use this to style(pointer-events-none select-none)      
//   ----- to get data use ( data.toLocaleDateString() or use ( e.format('YYYY/MM/DD') )
//   <DatePicketComponent 
//     title='تاریخ پایان قرارداد' 
//     state={openUpdateModalData[item.eName]} 
//     setState={handlerConventionEndDate} 
//     type='simple'
//   />
    return(
        type === 'simple' ?
            <div className='w-full sm:max-w-80'>
                <DatePicker 
                    value={state}
                    onChange={e=>setState(e.format('YYYY-MM-DD'))}
                    id={title}
                    calendar={persian}
                    locale={persian_fa}
                    placeholder={title}
                    disabled={disable?disable:false}
                    onOpenPickNewDate={false}
                    inputClass={`p-2 w-full text-xs text-gray-900 border rounded-lg bg-gray-50 text-right cursor-pointer`}
                    
                    inputMode='none'
                    scrollSensitive={false}
                    fixMainPosition={true}
                    portal={false}
                />
            </div>
        :
        type === 'custom' ?
            <DatePicker 
                value={state}
                onChange={e=>setState(e.format('YYYY-MM-DD'))}
                id={title}
                calendar={persian}
                locale={persian_fa}
                placeholder={title}
                disabled={disable?disable:false}
                // portal
                inputClass='shadow-blue-700 rounded-full text-center ring-2 text-blue-900 placeholder-blue-300 cursor-pointer'

                inputMode='none'
                scrollSensitive={false}
                fixMainPosition={true}
                portal={false}
                onOpenPickNewDate={false}
            />
        :
            type === 'pureDate'?
                <DatePicker 
                    value={state}
                    onChange={e=>setState(e.format('YYYY-MM-DD'))}
                    id={title}
                    placeholder={title}
                    calendar={persian}
                    locale={persian_fa}
                    disabled={disable?disable:false}
                    // portal
                    inputClass={`p-2 w-full text-xs text-gray-900 border rounded-lg bg-gray-50 text-right cursor-pointer`}

                    inputMode='none'
                    scrollSensitive={false}
                    fixMainPosition={true}
                    portal={false}
                    onOpenPickNewDate={false}
                />
            :
                <div className={'relative mb-3 max-w-full'} style={{width:10+'rem'}}> 
                    <DatePicker 
                        value={state}
                        onChange={e=>setState(e.format('YYYY-MM-DD'))}
                        id={title}
                        calendar={persian}
                        locale={persian_fa}
                        placeholder={title}
                        disabled={disable?disable:false}
                        // portal
                        inputClass={`peer text-center bg-transparent h-9 rounded-lg text-gray-700 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-sky-600 focus:outline-none focus:border-rose-600 w-full cursor-pointer ${ state ? " ring-green-500 " : " ring-gray-300 " }`}

                        inputMode='none'
                        scrollSensitive={false}
                        fixMainPosition={true}
                        portal={false}
                        onOpenPickNewDate={false}
                    />
                    <label htmlFor={'dateWheelsVisit'} className="absolute cursor-text right-0 -top-3 text-xs text-gray-500 bg-inherit mx-1 px-1 peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600 peer-focus:text-xs transition-all bg-white">
                        {title}
                    </label>
                </div>
    )
}

export function HomeBigBtn({link, title, color}:HomeBigBtnType) {
    return(
        <Link
            className={'relative center flex-col gap-2 rounded w-full sm:w-80 h-16 sm:h-24 py-10 sm:py-16 shadow-lg hover:scale-[1.01] cursor-pointer overflow-hidden bg-gradient-to-tr '+ color}
            to={link}
        >
            <div className='absolute p-20 rounded-full bg-white opacity-30 -top-10 -right-20' />
            <div className='absolute p-20 rounded-full bg-white opacity-20 -bottom-28 -right-4' />
            <p className='text-white'>
                {title}
            </p>
        </Link>
    )
}

export function ImageCard({title, state, setState, size, disable}:imageCardType) {

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        // Resize the selected image
        Resizer.imageFileResizer(
          selectedImage,
          600, // Set the desired width
          600, // Set the desired height
          'JPEG', // Set the format of the resized image
          95, // Set the quality of the resized image
          0, // Set the Rotation of the image
          (blob) => {
            const reader = new FileReader();
            reader.onload = () => {
              const imageData = reader.result.split(',')[1]; // Extract base64 data
              setState({
                url: URL.createObjectURL(blob),
                size: blob.size,
                type: blob.type,
                base64: imageData // Add base64 data to image object
              });
            };
            reader.readAsDataURL(blob);
          },
          "blob",
        );
    };

    return (
        <div className={`${size === 'xs' ? 'w-48 h-32' : size === 'sm' ? 'w-36 h-32': size === 'md' ? 'w-72 h-60 pb-9' : 'w-56 h-60 pb-9' } flex items-center justify-center relative overflow-hidden border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 hover:border-gray-400`}>
            <label htmlFor={title} className="flex flex-col items-center justify-center cursor-pointer w-full h-full text-center">
                {state ?
                    state.url?
                        <img src={state.url} alt={title} className='w-full h-full object-contain'/>
                    :
                        <ShowProtectedImage
                            imageFullRoute={state} 
                            imageAlt={'عکس امضا'} 
                            imageClass={'sm:max-w-80 h-36 object-contain'}
                        />
                    :
                    <div className="flex flex-col items-center flex-wrap justify-center cursor-pointer">
                        <HiOutlineCloudUpload className='text-gray-400' size={'1.8rem'}/>
                        <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">برای آپلود عکس اینجا کلیک کنید</span></p>
                    </div>
                }
                <input disabled={disable === true ? true : false} onChange={handleImageChange} id={title} type="file" className="hidden cursor-pointer" />
            </label>

            {
                size === 'xs' && 'sm' ?
                    null
                :
                    <p className='absolute w-full h-fit p-2 text-gray-100 text-sm text-center bottom-0 rounded-b-lg bg-gradient-to-r from-indigo-500 to-blue-500 cursor-pointer'>{title}</p>  
            }
        </div>
    );
}

export function CustomInput({state, setState, label, length, width, disable, type, cssClass}:CustomInputType) {
    return(
        <div className={cssClass + ' relative mb-3 max-w-full '} style={{width:width+'rem'}}> 
            <div className={'bg-white p-4 rounded-lg'}>
                <div className={'relative bg-inherit '}>
                    <input 
                        type={type ? type : 'text'} 
                        id={label}
                        name={label}
                        placeholder={label}
                        value={state}
                        onChange={(e)=>setState && setState(e.target.value)}
                        maxLength={length}
                        disabled={disable?disable:false}
                        className={
                            `peer text-right bg-transparent h-9 rounded-lg text-gray-700 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-sky-600 focus:outline-none focus:border-rose-600 w-full 
                            ${ !state ? " ring-gray-300 ":" ring-green-500 "}
                            `
                        }
                    />
                    <label htmlFor={label} className="line-clamp-1 absolute cursor-text right-0 -top-3 text-xs text-gray-500 bg-inherit mx-1 px-1 peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600 peer-focus:text-xs transition-all text-right">
                        {label}
                    </label>
                </div>
                <div dir='ltr' className="absolute pl-3 text-md text-neutral-500 peer-focus:text-blue-800" data-te-input-helper-ref>
                    {state?state.length:0}/{length}
                </div>
            </div>
        </div>
    )
}

export function CustomTextArea({state, setState, label, length, width, disable, height}:CustomInputType) {
    return(
        <div className={'relative mb-3 max-w-full'} style={{width:width ? width+'rem' : '100%',}}> 
          <div className={'bg-white p-4 rounded-lg'}>
            <div className={'relative bg-inherit '}>
                <textarea 
                    id={label}
                    name={label}
                    placeholder={label}
                    value={state}
                    onChange={(e)=>setState(e.target.value)}
                    maxLength={length}
                    disabled={disable?disable:false}
                    style={{height:height+'rem'}}
                    className={
                        `peer text-right bg-transparent p-2 rounded-lg text-gray-700 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-sky-600 focus:outline-none focus:border-rose-600 w-full 
                        ${ state === '' ? " ring-gray-300 ":" ring-green-500 "}`
                    }
                />
                <label htmlFor={label} className="absolute cursor-text right-0 -top-3 text-xs text-gray-500 bg-inherit mx-1 px-1 peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600 peer-focus:text-xs transition-all">
                    {label}
                </label>
            </div>
            <div dir='ltr' className="absolute pl-3 text-md text-neutral-500 peer-focus:text-blue-800" data-te-input-helper-ref>
                {state?state.length:0}/{length}
            </div>
          </div>
        </div>
    )
}

export function SerachInput({title, state, handler, customWidth}:SerachInputType) {
    return (
        // <div className="p-4 bg-white">
            <div className="relative w-full" dir='rtl'>
                <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                    <IoSearch size={'1.3rem'} color='gray'/>
                </div>
                <input 
                    type="search" 
                    className={`${customWidth ? customWidth : ' min-w-fit ' } p-2 ps-10 text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-gray-800` }
                    placeholder={title}
                    value={state}
                    onChange={handler}
                />
            </div>
        // </div>
    )
}

export function PageTitle({title, desc}:{title?:string, desc?:string}) {
    return(
        <>
            <p className='font-bold text-2xl py-4 pb-0 w-full text-center'>
              {title}
            </p>
            <p className='font-bold text-base py-4 w-full text-center text-zinc-800'>
                {desc}
            </p>
        </>
    )
}

export function HandlerNavigateTankCode () {
    const navigate = useNavigate();
    useEffect(()=>{
        navigate('/member/tanks')
    },[])
    return <>{alert("ابتدا تانک مورد نظر را انتخاب کنید.")}</>
}

export function handlerGetUsersTanks(setData, setFilteredArray, response) {
    if(localStorage.getItem('tank22to46')){
        setData([]);
        setFilteredArray([]);
        response.filter((item)=>{
            const newTankCode = item.tankCode.slice(1,3)
            const newItem = newTankCode >= 22;
            if(newItem){
                setData(prev=>[...prev,item]);
                setFilteredArray(prev=>[...prev,item]);
            }
        })
    }else{
        setFilteredArray(response);
        setData(response);
    }
}

// ------ change persioan to en (۱۴۰۲-۱۲-۰۲) => (1402/12/02) --------
export function HandlerChangeDigitToEn(item: string | undefined) {
    if (item !== undefined) {
        return item.replace(/[\u06f0-\u06f9]/g, function (c) {
            return String.fromCharCode(c.charCodeAt(0) - 0x06f0 + 48);
        }).replace(/[\u0660-\u0669]/g, function (c) {
            return String.fromCharCode(c.charCodeAt(0) - 0x0660 + 48);
        }).replace(/-/g, '/');
    } else {
        return '';
    }
}

// ------ change (1402-12-16T3453453454) to (1402/12/16) -------
export function HandlerReplaceDate(item: string | undefined) {
    if (item !== undefined) {
        const [year, month, day] = item.split('T')[0].split('-');
        const formattedDate = `${year}/${month}/${day}`;
        return formattedDate;
    } else {
        return undefined;
    }
}

// ------ convert (2024-03-25T06:06:59.000Z) to (1402/02/02) -------
export function ConvertDateToJalali(dateString) {
    const date = moment(HandlerReplaceDate(dateString), 'YYYY/MM/DD').format('jYYYY/jMM/jDD')
    return date;
}

// ------ convert (2024-03-25T06:06:59.000Z) to (06:06:59) -------
export function ConvertDateToTime(dateString) {
    const date = new Date(dateString);
    
    // Get the hours, minutes, and seconds from the date object
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
  
    // Format the time components as HH:MM:SS
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  
    return formattedTime;
}

// ------ convert (2024-03-25T06:06:59.000Z) to (دوشنبه) -------
export function GetDayNameFromDate(dateString) {
    const daysOfWeek = ['یک شنبه', 'دوشنبه', 'سه شنبه', 'چهارشنبه', 'پنج شنبه', 'جمعه', 'شنبه'];
    const date = new Date(dateString);
    
    // Get the numeric representation of the day (0 for Sunday, 1 for Monday, etc.)
    const dayOfWeek = date.getDay();
  
    // Get the name of the day from the daysOfWeek array
    const dayName = daysOfWeek[dayOfWeek];
  
    return dayName;
}

// ------ convert (۱۲۳۴5678) to (12345678) -------
const persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
const englishNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
export function ConvertPersianNumToEnglishNum(input) {
    if(typeof input !== 'string'){
        return input;
    }
    
    let result = input;
    
    for (let i = 0; i < 10; i++) {
        const regex = new RegExp(persianNumbers[i], 'g');
        result = result.replace(regex, englishNumbers[i]);
    }

    return result;
}


// ------ convert (123) to (صد و بیست سه) -------
export function ConvertNumberToText(input) {
    if(input){
        return  persianJs(input).digitsToWords().toString()
    }
}

// ------ convert (123) to (صد و بیست سه) -------
export function ConvertNumberSeparate(number) {
    number+= '';
    number= number.replace(',', '');
    const x = number.split('.');
    let y = x[0];
    const z= x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(y))
    y = y.replace(rgx, '$1' + ',' + '$2');
    return y + z;
}
  
export function openImageInNewTab (item:string)  {
    window.open(item, '_blank');
};

export function ConvertJalaliToGregorian(params) {
    return moment(ConvertPersianNumToEnglishNum(params), 'jYYYY-jMM-jDD').format('YYYY-MM-DD')
}

export const HandlePrint = () => {
    window.print();
};

export const SaveImage = ({imageUrl, imageName}) => {
    fetch(imageUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', imageName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
};

