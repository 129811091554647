import React,{ useState, useEffect } from 'react'
import Admin_Header from './header.tsx'
import { AdminTableList } from '../../components/adminTableList.tsx'
import { PrimaryBtn, TransparentLink } from '../../components/btn.tsx';
import { DropDownSearchStatus, SerachInput, ConvertPersianNumToEnglishNum, handlerGetUsersTanks } from '../../components/common.tsx';
import { Modal } from '../../components/modal.tsx';
import axios from 'axios';
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText, uploadError } from '../../array/array.ts';

const tableHead = [
  {pName:"کد تانکر", eName:"tankCode", type:"string/number", sorted:true, showAllData:true},
  {pName:"شماره مخزن", eName:"tankNumber", type:"string/number", sorted:true, showAllData:true},
  {pName:"شماره شاسی", eName:"chassisNumber", type:"number", sorted:true, showAllData:true},
]

const tableHeadFull = [
  {pName:"کد تانکر", eName:"tankCode", type:"string/number",},
  {pName:"شماره مخزن", eName:"tankNumber", type:"string",},
  {pName:"شماره شاسی", eName:"chassisNumber", type:"number",},
]

const defaultArrayValue = {
  tank_id:0, 
  tankCode:'', 
  tankNumber:undefined, 
  chassisNumber:undefined
}

interface deleteModalDataTypes {
  tank_id:number, 
  name:string, 
  family?:string,
}

interface updateModalDataTypes {
  tank_id:number, 
  tankCode:string, 
  tankNumber?:number, 
  chassisNumber?:number,
}

interface handlerDeleteFuncTypes {
  tank_id:number, 
  name:string,
  tankCode:string, 
}


const Admin_TankersList = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState<boolean>(false)
  const [refreshPage, setRefreshPage] = useState<number>(0)
  const [searchDropDownParam, setSearchDropDownParam] = useState<string>(tableHead[0].pName)
  const [data, setData] = useState<any[]>([])
  const [filteredArray, setFilteredArray] = useState<any[]>([])
  const [searchItem, setSearchItem] = useState<string>('')
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [deleteModalData, setDeleteModalData] = useState<deleteModalDataTypes>({tank_id:0, name:'', family:''})
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false)
  const [updateModalData, setUpdateModalData] = useState<updateModalDataTypes>(defaultArrayValue)
  const [openInsertModal, setOpenInsertModal] = useState<boolean>(false)
  const [insertModalData, setInsertModalData] = useState<updateModalDataTypes>(defaultArrayValue)
  const [openShowModal, setOpenShowModal] = useState<boolean>(false)
  const [showModalData, setShowModalData] = useState<updateModalDataTypes>(defaultArrayValue)
  const headerTitle = 'لیست تانکر ها';
  
  useEffect(() => {
    setLoader(true);
    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}admin/tanksList`, {
                headers: {
                    'auth-token': Cookies.get('token'),
                    'auth-id': Cookies.get('id')
                }
            });
            handlerGetUsersTanks(setData, setFilteredArray, response.data)
            setLoader(false);
        } catch (error) {
            // console.error('Error fetching tanks:', error);
            setLoader(false); // Hide loader after fetching data
            alert(loginText)
            navigate('/admin')
        }
    };

    fetchData();
  }, [refreshPage]);

  const handlerInsertQuery = async () => {
    if(insertModalData.tankCode && insertModalData.tankNumber && insertModalData.chassisNumber){
      setLoader(true)
      axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/insertTank`, 
          {
              user_id: Cookies.get('id'),
              tankCode: ConvertPersianNumToEnglishNum(insertModalData.tankCode.toLocaleUpperCase()),
              tankNumber: ConvertPersianNumToEnglishNum(insertModalData.tankNumber),
              chassisNumber: ConvertPersianNumToEnglishNum(insertModalData.chassisNumber),
          },
          {
              headers: {
                  'auth-token': Cookies.get('token'),
                  'auth-id': Cookies.get('id')
              }
          })
          .then(response => {
              // console.log('Response:', response.data);
              setLoader(false)
              alert('تانک به درستی اضافه شد')
              setRefreshPage((prev) => prev + 1)
              setOpenInsertModal(false)
              setInsertModalData(defaultArrayValue)
          })
          .catch(error => {
              // console.error('Error:', error);
              setLoader(false); // Hide loader after fetching data
              alert(uploadError)
              // alert(loginText)
              // navigate('/admin')
          }
      )
    }else{
      alert('لطفا تمام فیلد ها را کامل کنید.')
    }
  };
  
  const handlerUpdateQuery = async () => {
    setLoader(true)
    axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/updateTank`, 
        {
            user_id: Cookies.get('id'),
            tankCode: ConvertPersianNumToEnglishNum(updateModalData.tankCode.toLocaleUpperCase()),
            tankNumber: ConvertPersianNumToEnglishNum(updateModalData.tankNumber),
            chassisNumber: ConvertPersianNumToEnglishNum(updateModalData.chassisNumber),
            tank_id: updateModalData.tank_id
        },
        {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
        })
        .then(response => {
            // console.log('Response:', response.data);
            setLoader(false)
            // alert('تانک به درستی ویرایش شد')
            setRefreshPage((prev) => prev + 1)
            setOpenUpdateModal(false)
        })
        .catch(error => {
            // console.error('Error:', error);
            setLoader(false); // Hide loader after fetching data
            alert(uploadError)
            // alert(loginText)
            // navigate('/admin')
        }
    )
  };

  const handlerDeleteQuery = async () => {
    setLoader(true)
    axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/deleteTank`, 
        {
            user_id: Cookies.get('id'),
            tank_id: deleteModalData.tank_id
        },
        {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
        })
        .then(response => {
            // console.log('Response:', response.data);
            setLoader(false)
            setRefreshPage((prev) => prev + 1)
            setOpenDeleteModal(false)
        })
        .catch(error => {
            // console.error('Error:', error);
            setLoader(false); // Hide loader after fetching data
            alert(uploadError)
            // alert(loginText)
            // navigate('/admin')        
          }
    )
  };

  function SearchTableData() {
    const handleInputSearch = (e) => { 
      const searchTerm = ConvertPersianNumToEnglishNum(e.target.value);
      setSearchItem(searchTerm);
      const filteredItems = data.filter((item) =>
          searchDropDownParam === tableHead[0].pName ?
            item.tankCode && item.tankCode.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase()) :
          searchDropDownParam === tableHead[1].pName ?
            item.tankNumber && item.tankNumber.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase()) :
          searchDropDownParam === tableHead[2].pName ?
            item.chassisNumber && item.chassisNumber.includes(searchTerm) :
          null
      );
    
    setFilteredArray(filteredItems);
  }
  return(
    <div className='w-full flex flex-row flex-wrap-reverse center md:items-center md:justify-between gap-3 p-4'>
      <div className='w-full sm:w-fit flex flex-row flex-wrap-reverse gap-2'>
        <PrimaryBtn onClick={()=>setOpenInsertModal(true)}>
            افزودن مخزن جدید
        </PrimaryBtn>
        <TransparentLink to={'/admin/printList/'} params={{array:filteredArray, listHead:tableHeadFull , pageHeader:headerTitle}}>
            فرم چاپی
        </TransparentLink>
      </div>
      <div className='w-fit flex items-start justify-start flex-row gap-3'>
        <SerachInput title={"جستجوی " + searchDropDownParam} state={searchItem} handler={handleInputSearch}/>
        <DropDownSearchStatus list={tableHead} state={searchDropDownParam} setState={setSearchDropDownParam}/>
      </div>
    </div>
  )
  }

  const handlerDeleteFunc = (item: handlerDeleteFuncTypes) => {
    setOpenDeleteModal(true)
    setDeleteModalData({tank_id:item.tank_id, name:item.tankCode})
  } 
  
  const handlerUpdateFunc = (item: updateModalDataTypes) => {
    setOpenUpdateModal(true)
    setUpdateModalData({tank_id:item.tank_id, tankCode:item.tankCode, tankNumber:item.tankNumber, chassisNumber:item.chassisNumber })
  }

  const handlerShowFunc = (item: updateModalDataTypes) => {
    setOpenShowModal(true)
    setShowModalData({tank_id:item.tank_id, tankCode:item.tankCode, tankNumber:item.tankNumber, chassisNumber:item.chassisNumber })
  }

  const InsertUpdateModalBody = ({type}:{type:'insert'|'update'|'show'}) => {
    return(
      <div className='w-[20rem] sm:w-[25rem] space-y-2'>
        {
          tableHead.map((item,key)=>(
            <div key={key} className='flex flex-row items-center'>
              <span className='text-sm w-2/5'>{item.pName}:</span>
                <input
                  type="text" 
                  disabled={type === 'show' ? true : false}
                  id={item.pName}
                  className="p-2 w-full sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " 
                  placeholder={item.pName}
                  value={
                    type === 'insert' ? 
                    insertModalData[item.eName] 
                    :type === 'update' ? 
                    updateModalData[item.eName] 
                    :showModalData[item.eName]
                  } 
                  onChange={e => type === 'insert' ? setInsertModalData(prevState => ({...prevState,[item.eName]: e.target.value})) : setUpdateModalData(prevState => ({...prevState,[item.eName]: e.target.value}))}
                />
            </div>
          ))
        }
      </div>
    )
  }

  return (
    <>
      {loader && <FullLoader />}
      <Admin_Header title={headerTitle}/>
      <div className='m-r-nav px-1 mb-4 sm:px-6'>
        {SearchTableData()}
        <AdminTableList 
          TableList={filteredArray} 
          tableHeadParams={tableHead} 
          handlerDeleteFunc={handlerDeleteFunc}
          handlerUpdateFunc={handlerUpdateFunc}
          handlerShowFunc={handlerShowFunc}
        />
      </div>

      <Modal 
        title='حذف تانکر'
        body={"میخوای تانکر " + deleteModalData.name + " حذف بشه؟ "}
        icon='warning'
        onOpen={openDeleteModal}
        btnCancel={setOpenDeleteModal}
        btnDelete={handlerDeleteQuery}
      />

      <Modal 
        title='ویرایش تانکر'
        body={InsertUpdateModalBody({type:'update'})}
        onOpen={openUpdateModal}
        btnCancel={setOpenUpdateModal}
        btnSubmit={handlerUpdateQuery}
      />

      <Modal 
        title='افزودن تانکر'
        body={InsertUpdateModalBody({type:'insert'})}
        onOpen={openInsertModal}
        btnCancel={setOpenInsertModal}
        btnSubmit={handlerInsertQuery}
      />

      <Modal 
        title={'جزئیات تانکر'}
        body={InsertUpdateModalBody({type:'show'})}
        onOpen={openShowModal}
        btnCancel={setOpenShowModal}
      />
    </>
  )
}

export default Admin_TankersList
