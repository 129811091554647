import React,{ useState, useEffect } from 'react'
import Member_Header from './header.tsx'
import { AdminTableList } from '../../components/adminTableList.tsx'
import { PrimaryBtn, SuccessBtn, TransparentLink } from '../../components/btn.tsx';
import { Modal } from '../../components/modal.tsx';
import axios from 'axios';
import { openImageInNewTab, SerachInput, HandlerNavigateTankCode, ConvertPersianNumToEnglishNum, HandlerChangeDigitToEn, DatePicketComponent, ImageCard, SaveImage } from '../../components/common.tsx';
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText, uploadError } from '../../array/array.ts';
import {ShowProtectedImage} from '../../components/showProtectedImage.tsx';

export interface updateModalDataTypes {
  test_id:number;
  tanker:string;
  testDate:string;
  expireDate:string;
  sigImg:string;
  creatorName?:string;
}

export interface deleteModalDataTypes {
  test_id:number, 
  name:string | undefined, 
  testDate?:string | undefined,
  expireDate?:string | undefined,
  family?:string,
}

const tableHead = [
  {pName:"تاریخ ثبت", eName:"testDate", type:"date", sorted:true},
  {pName:"تاریخ پایان تست", eName:"expireDate", type:"date", sorted:true},
  {pName:"عکس تست تانک", eName:"sigImg", type:"string", sorted:true},
]

const tableHeadParams = [
    {pName:"کد تانکر", eName:"tanker", type:"string/number", sorted:true, showAllData:true},
    {pName:"تاریخ ثبت", eName:"testDate", type:"date", sorted:true, showAllData:true},
    {pName:"تاریخ پایان تست", eName:"expireDate", type:"date", sorted:true, showAllData:true},
    {pName:"ثبت شده توسط", eName:"creatorName", type:"string", sorted:true, showAllData:true},
]

const defaultArrayValue = {
  test_id:0, tanker:'', creatorName:'', testDate:'', expireDate:'', sigImg:''
}

const TankTestList = () => {
  const navigate = useNavigate();
  const tankCode = localStorage.getItem("tankCode");
  // const tankId = localStorage.getItem("tankId");

  const [data, setData] = useState<any[]>([])
  const [refreshPage, setRefreshPage] = useState<number>(0)
  const [image, setImage] = useState<any>('');
  const [filteredArray, setFilteredArray] = useState<any[]>([])
  const [loader, setLoader] = useState<boolean>(true)
  const [searchItem, setSearchItem] = useState<string>('')
  const [openShowModal, setOpenShowModal] = useState<boolean>(false)
  const [showModalData, setShowModalData] = useState<updateModalDataTypes>(defaultArrayValue)
  const headerTitle = ' لیست تست تمام تانک ها ';
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false)
  const [updateModalData, setUpdateModalData] = useState<updateModalDataTypes>(defaultArrayValue)
  const [searchStatus, setSearchStatus] = useState<'testDate'|'expireDate'>('testDate')

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}users/tankTestList`, {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
        });
        setFilteredArray([])
        setData([])

        // ----- show custom tanks for custom users ------
        if(localStorage.getItem('tank22to46')){
          response.data.filter((item)=>{
            const newTankCode = item.tankCode.slice(1,3)
            const newItem = newTankCode >= 22;
            if(newItem){
                setFilteredArray(prev=>[...prev,{test_id:item.test_id, tanker:item.tankCode, testDate:item.testDate, expireDate:item.expireDate, creatorName:item.family+' '+item.name, sigImg:item.testImg}]);
                setData(prev=>[...prev,{test_id:item.test_id, tanker:item.tankCode, testDate:item.testDate, expireDate:item.expireDate, creatorName:item.family+' '+item.name, sigImg:item.testImg}]);
              }
          })
        }else{
          response.data.map((item)=>{
            setFilteredArray(prev=>[...prev,{test_id:item.test_id, tanker:item.tankCode, testDate:item.testDate, expireDate:item.expireDate, creatorName:item.family+' '+item.name, sigImg:item.testImg}]);
            setData(prev=>[...prev,{test_id:item.test_id, tanker:item.tankCode, testDate:item.testDate, expireDate:item.expireDate, creatorName:item.family+' '+item.name, sigImg:item.testImg}]);
          })
        }

        setLoader(false);
      } catch (error) {
        setFilteredArray([])
        setData([])
        // console.error('Error fetching tanks:', error);
        setLoader(false); // Hide loader after fetching data
        alert(loginText)
        navigate('/member')
      }
    };

    fetchData();
  }, [refreshPage]);
   
  async function handlerShowQuery(status:'testDate'|'expireDate') {
    setSearchStatus(status)
    if (startDate && endDate) {
      setLoader(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_ADDRESS}users/tankTestListSearch`, 
          {
            searchFor: status,
            startDate:HandlerChangeDigitToEn(startDate),
            endDate: HandlerChangeDigitToEn(endDate),
          },  
          {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
          }
        );
        setFilteredArray([]);
        setData([]);

        // ----- show custom tanks for custom users ------
        if(localStorage.getItem('tank22to46')){
          response.data.filter((item)=>{
            const newTankCode = item.tankCode.slice(1,3)
            const newItem = newTankCode >= 22;
            if(newItem){
                setFilteredArray(prev=>[...prev,{test_id:item.test_id, tanker:item.tankCode, testDate:item.testDate, expireDate:item.expireDate, creatorName:item.family+' '+item.name, sigImg:item.testImg}]);
                setData(prev=>[...prev,{test_id:item.test_id, tanker:item.tankCode, testDate:item.testDate, expireDate:item.expireDate, creatorName:item.family+' '+item.name, sigImg:item.testImg}]);
              }
          })
        }else{
          response.data.map((item)=>{
            setFilteredArray(prev=>[...prev,{test_id:item.test_id, tanker:item.tankCode, testDate:item.testDate, expireDate:item.expireDate, creatorName:item.family+' '+item.name, sigImg:item.testImg}]);
            setData(prev=>[...prev,{test_id:item.test_id, tanker:item.tankCode, testDate:item.testDate, expireDate:item.expireDate, creatorName:item.family+' '+item.name, sigImg:item.testImg}]);
          })
        }

        setLoader(false);
    } catch (error) {
      // console.error('Error Fetching Repair List:', error);
      setLoader(false); // Hide loader after fetching data
      alert(loginText)
      navigate('/member')
    }
    }else{
     alert('لطفا تاریخ شروع و تاریخ پایان را انتخاب کنید')
    }
  }

  const handlerUpdateQuery = async () => {
    setLoader(true)
    axios.post(`${process.env.REACT_APP_API_ADDRESS}users/updateTankTest`, 
        {
          testDate: updateModalData.testDate,
          expireDate: updateModalData.expireDate,
          test_id: updateModalData.test_id,
          sigImg: image != '' ? image.base64 : null,
        },
        {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
        })
        .then(response => {
            // console.log('Response:', response.data);
            setLoader(false)
            // alert('تست تانک به درستی ویرایش شد')
            setRefreshPage((prev) => prev + 1)
            if(startDate && endDate){handlerShowQuery(searchStatus)}
            setOpenUpdateModal(false)
            setImage('')
        })
        .catch(error => {
            // console.error('Error:', error);
            setLoader(false)
            alert(uploadError)
            // alert(loginText)
            // navigate('/member')
            setImage('')
        }
    )
  };

  function SearchDate() {
    return (
      <div className='w-full'>
        {/* <p className='text-xs w-full text-right'>: جستجو بر اساس تاریخ تعویض </p> */}
        <div className='flex flex-row-reverse flex-wrap center sm:items-center sm:justify-start gap-3 my-4 px-2'>
          <div className='w-35'>
            <DatePicketComponent title="از تاریخ" state={startDate} setState={setStartDate} type='simple'/>
          </div>
          <div className='w-35'>
            <DatePicketComponent title="تا تاریخ" state={endDate} setState={setEndDate} type='simple'/>
          </div>
            <PrimaryBtn onClick={()=>handlerShowQuery('testDate')}>
              <p>جستجو براساس تاریخ ثبت </p>
            </PrimaryBtn>
            <PrimaryBtn onClick={()=>handlerShowQuery('expireDate')}>
              <p>جستجو براساس تاریخ پایان </p>
            </PrimaryBtn>
        </div>
      </div>
    );
  }

  function SearchTableData() {
    const handleInputSearch = (e) => { 
        const searchTerm = ConvertPersianNumToEnglishNum(e.target.value);
        setSearchItem(searchTerm);
        const filteredItems = data.filter((item) =>
          item.tanker && item.tanker.toUpperCase().includes(searchTerm.toUpperCase())        
        );
        // console.log('filteredItems ' + filteredItems)
        setFilteredArray(filteredItems);
    }
    return(
      <div className='flex flex-row flex-wrap-reverse items-center justify-between gap-3 p-2'>
        <div className='w-full sm:w-fit flex flex-row gap-2'>
          <TransparentLink to={'/member/printList/'} params={{array:filteredArray, listHead:tableHeadParams , pageHeader:headerTitle}}>
                فرم چاپی
          </TransparentLink>
        </div>

        <SerachInput title="جستجوی کد تانکر" state={searchItem} handler={handleInputSearch}/>
      </div>
    )
  }

  const handlerShowFunc = (item: updateModalDataTypes) => {
    setOpenShowModal(true)
    setShowModalData({test_id:0, tanker:item.tanker, creatorName:item.creatorName, testDate:item.testDate, expireDate:item.expireDate,sigImg:item.sigImg })
  }

  const handlerUpdateFunc = (item: updateModalDataTypes) => {
    setOpenUpdateModal(true)
    setUpdateModalData({test_id:item.test_id, tanker:item.tanker, creatorName:item.creatorName, testDate:item.testDate, expireDate:item.expireDate, sigImg:item.sigImg })
  }

  const UpdateModalBody = () => {
    const handleUpdateTestDate = (newTestDate) => {
      setUpdateModalData(prevState => ({
        ...prevState,
        testDate: HandlerChangeDigitToEn(newTestDate),
      }));
    };
    const handleUpdateExpireDate = (newTestDate) => {
      setUpdateModalData(prevState => ({
        ...prevState,
        expireDate: HandlerChangeDigitToEn(newTestDate),
      }));
    };
    return(
      <ul className='w-[20rem] sm:w-[25rem] space-y-2'>
        {
          tableHead.map((item,key)=>(
            <li key={key} className='flex flex-row items-center justify-start'>
              <span className='text-sm w-3/6'>{item.pName}:</span>
              {
                item.eName === 'sigImg' ?
                  <div className='w-full center'>
                    <ImageCard
                      state={image ? image : process.env.REACT_APP_API_ADDRESS + 'tankTestPhotos' + updateModalData.sigImg} 
                      setState={setImage} 
                      title={'آپلود عکس تست تانک'}
                    />
                  </div>
                :
                  item.eName === 'testDate' ?
                    <div className='w-full center'>
                      <DatePicketComponent 
                        title='انتخاب تاریخ ثبت تست'
                        state={updateModalData[item.eName]} 
                        setState={handleUpdateTestDate} 
                        type='custom'
                      />
                    </div>
                  :
                    <div className='w-full center'>
                      <DatePicketComponent 
                        title='انتخاب تاریخ پایان تست'
                        state={updateModalData[item.eName]} 
                        setState={handleUpdateExpireDate} 
                        type='custom'
                      />
                    </div>
              }
            </li>
          ))
        }
      </ul>
    )
  }
  
  const ShowModalBody = () => {
    return(
      <ul className='w-[20rem] sm:w-[25rem] space-y-4 -mt-3'>
        <li>
          <p className='whitespace-pre'>
                <span>تاریخ ثبت تست تانک:  </span>
                <span>{showModalData.testDate}</span>
            </p>
            <p>
                <span> تاریخ پایان تست تانک: </span>
                <span>{showModalData.expireDate}</span>
            </p>
        </li>
        <li>
          <ShowProtectedImage
            imageRoute={'tankTestPhotos'} 
            imageName={showModalData.sigImg} 
            imageAlt={'عکس تست تانک'} 
            imageClass={'w-full h-[25rem] object-contain rounded bg-gray-200' }
          />
          {/* <img 
            className='w-full h-[25rem] object-contain rounded bg-gray-200'
            src={showModalData.sigImg && process.env.REACT_APP_API_ADDRESS + 'tankTestPhotos' + showModalData.sigImg} 
            alt={'عکس تست تانک'}
          />   */}
        </li>
        <li className='w-full'>
          {/* <SuccessBtn onClick={()=>openImageInNewTab(process.env.REACT_APP_API_ADDRESS + 'tankTestPhotos' + showModalData.sigImg)}> */}
          {/* <SuccessBtn 
            onClick={()=>SaveImage({ 
              imageUrl: process.env.REACT_APP_API_ADDRESS + 'tankTestPhotos' + showModalData.sigImg, 
              imageName: showModalData.sigImg 
            })}
            >
              دانلود عکس
          </SuccessBtn> */}
        </li>
      </ul>
    )
  }
  
  return (
    <div className='m-r-nav'>
      {loader && <FullLoader />}
      <Member_Header title={headerTitle}/>
      {
        tankCode?
          <div className='px-4 mb-8 sm:px-8'>
            <div className='w-full text-justify sm:text-right px-2 flex flex-col gap-4'>
              <p className='text-sm text-gray-600' dir='rtl'>
                <span className='text-gray-800'> نکته یک : </span>
                بطور پیش فرض ۱۰ تست تانک آخر نمایش داده میشود . 
              </p>
              <p className='text-sm text-gray-600' dir='rtl'>
                <span className='text-gray-800'> نکته دو : </span>
                برای نمایش لیست  تاریخ مد نظر را انتخاب کرده سپس دکمه جستجو را کلیک کنید .
              </p>
            </div>

            {SearchDate()}
            {SearchTableData()}

            <AdminTableList
              TableList={filteredArray}
              tableHeadParams={tableHeadParams}
              handlerUpdateFunc={handlerUpdateFunc} 
              handlerShowFunc={handlerShowFunc}
            />
          </div>
        :
          <HandlerNavigateTankCode/>
      }

      <Modal 
        title={''}
        body={<ShowModalBody/>}
        onOpen={openShowModal}
        btnCancel={setOpenShowModal}
      />

      <Modal 
        title='ویرایش تست تانک'
        body={UpdateModalBody()}
        onOpen={openUpdateModal}
        btnCancel={setOpenUpdateModal}
        btnSubmit={handlerUpdateQuery}
      />
    </div>
  )
}

export default TankTestList
