import React, { useState} from 'react'
import Member_Header from './header.tsx'
import { SuccessBtn } from '../../components/btn.tsx'
import { DatePicketComponent, HandlerChangeDigitToEn, CustomInput, CustomTextArea, HandlerNavigateTankCode, ConvertPersianNumToEnglishNum } from '../../components/common.tsx'
import { Modal } from '../../components/modal.tsx'
import axios from 'axios'
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText, uploadError } from '../../array/array.ts';

const RefineryLoadingForm = () => {
  const navigate = useNavigate();
  const tankCode = localStorage.getItem("tankCode");
  const tankId = localStorage.getItem("tankId");

  const [loader, setLoader] = useState<boolean>(false)

  const [openSubmitModal, setOpenSubmitModal] = useState<boolean>(false)

  const [loadingDate, setloadingDate] = useState<string>()
  const [evacuationDate, setEvacuationDate] = useState<string>()
  const [tonnage, setTonnage] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  function handlerSubmitModal() {
    if(evacuationDate && loadingDate && tonnage ){
      setOpenSubmitModal(!openSubmitModal)
    }else{
      alert(' لطفا تاریخ بارگیری و تاریخ تخلیه و تناژ بارگیری را وارد کنید.')
    }
  }
  
  function handlerSubmitBtn() {
    setOpenSubmitModal(false)
    setLoader(true)

    axios.post(`${process.env.REACT_APP_API_ADDRESS}users/insertRefineryLoadingForm`, 
      {
        tank_id: tankId,
        user_id: Cookies.get('id'),
        loadingDate: HandlerChangeDigitToEn(loadingDate),
        evacuationDate: HandlerChangeDigitToEn(evacuationDate),
        tonnage: ConvertPersianNumToEnglishNum(tonnage),
        description: description
      },
      {
        headers: {
            'auth-token': Cookies.get('token'),
            'auth-id': Cookies.get('id')
        }
      })
      .then(response => {
          // console.log('Response:', response.data);
          setLoader(false)
          alert('فرم به درستی ثبت شد. برای نمایش لیست بارگیری های ثبت شده به صفحه لیست گزارشات پالایشگاه مراجعه کنید .')
          setloadingDate('')
          setEvacuationDate('')
          setTonnage('')
          setDescription('')
      })
      .catch(error => {
          // console.error('Error:', error);
          alert(uploadError)
          // alert(loginText)
          // navigate('/member')
          setLoader(false); // Hide loader in case of an error
      }
    );
  }
  
  return (
    <div className='m-r-nav'>
      {loader && <FullLoader />}
      <Member_Header tankName={tankCode+' ثبت بارگیری پالایشگاه'}/>

      {
        tankCode ?
          <div className='px-8 pb-10 pt-4 flex flex-row-reverse items-center justify-center flex-wrap gap-4 sm:gap-8 w-full'>
            <DatePicketComponent title="تاریخ بارگیری" state={loadingDate} setState={setloadingDate} />
            <DatePicketComponent title="تاریخ تخلیه" state={evacuationDate} setState={setEvacuationDate}/>
            {CustomInput({state:tonnage, setState:setTonnage, label:'توناژ', width:15, length:6})}
            {CustomTextArea({state:description, setState:setDescription, label:'توضیحات', width:50, length:500, height:6})}
            
            <div className='w-full center'>
              <SuccessBtn onClick={handlerSubmitModal}>
                ثبت اطلاعات
              </SuccessBtn>
            </div>
          </div>
        :
          <HandlerNavigateTankCode/>
      }   
      
      <Modal 
        icon="success"
        title="ثبت نهایی فرم"
        body="آیا از صحت اطلاعات فوق اطمینان دارید."
        btnCancel={setOpenSubmitModal}
        btnSubmit={handlerSubmitBtn}
        onOpen={openSubmitModal}
      />
    </div>
  )
}

export default RefineryLoadingForm
