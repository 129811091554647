import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);
};

export const paginationScrollTop = () => {
  return window.scrollTo({ top: 0, behavior: 'smooth' });
}
