import React, { useState, useEffect } from 'react';
import { AdminTableList } from '../../components/adminTableList.tsx';
import { Modal } from '../../components/modal.tsx';
import Guest_Header from './header.tsx'
import axios from 'axios';
import { SerachInput, ConvertPersianNumToEnglishNum, handlerGetUsersTanks } from '../../components/common.tsx';
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText } from '../../array/array.ts';

const tableHead = [
    {pName:"کد تانکر", eName:"tankCode", type:"string/number", sorted:true, showAllData:true},
    {pName:"شماره مخزن", eName:"tankNumber", type:"number", sorted:true, showAllData:true},
    {pName:"شماره شاسی", eName:"chassisNumber", type:"number", sorted:true, showAllData:true},
]

const tableHeadFull = [
    {pName:"نام راننده", eName:"name", type:"string",},
    {pName:"نام خانوادگی راننده", eName:"family", type:"string",},
    // {pName:"شماره تماس راننده", eName:"number", type:"number",},
    // {pName:"شماره حساب راننده", eName:"cartNumber", type:"number",},
    {pName:"شماره پلاک", eName:"plateNumber", type:"string/number",},
    {pName:"کد تانکر", eName:"tankCode", type:"string/number",},
    {pName:"شماره شاسی", eName:"chassisNumber", type:"string/number",},
    {pName:"شماره مخزن", eName:"tankNumber", type:"string/number",},
]

interface showModalDataTypes {
    id:number, 
    tankCode:string, 
    tankNumber?:number, 
    chassisNumber?:number,
    name?:string,
    family?:string,
    number?:number,
    plateNumber?:string,
}

export default function Guest_Tanks() {
    const navigate = useNavigate();

    const [data, setData] = useState<any[]>([])
    const [filteredArray, setFilteredArray] = useState<any[]>([])
    const [loader, setLoader] = useState<boolean>(true)
    const [searchItem, setSearchItem] = useState<string>('')
    const [openShowModal, setOpenShowModal] = useState<boolean>(false)
    const [showModalData, setShowModalData] = useState<showModalDataTypes>({id:0, tankCode:'', tankNumber:undefined, chassisNumber:undefined, name:'', family:'', plateNumber:''})
    // const [showModalData, setShowModalData] = useState<showModalDataTypes>({id:0, tankCode:'', tankNumber:undefined, chassisNumber:undefined, name:'', family:'', number:undefined, plateNumber:''})

    useEffect(() => {
        setLoader(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}guest/tanksList`, 
                {
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                handlerGetUsersTanks(setData, setFilteredArray, response.data)
                setLoader(false);
            } catch (error) {
                // console.error('Error fetching tanks:', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate('/guest')
            }
        };
    
        fetchData();
    }, []);
    
    const SearchTableData = () => { 
        const handleInputSearch = (e) => { 
            const searchTerm = ConvertPersianNumToEnglishNum(e.target.value);
            setSearchItem(searchTerm)
        
            const filteredItems = data.filter((item) =>
                item.tankCode && item.tankCode.toUpperCase().includes(searchTerm.toUpperCase())
                // item.tankNumber && item.tankNumber.toUpperCase().includes(searchTerm.toUpperCase()) ||
                // item.tankChassis&& item.tankChassis.toUpperCase().includes(searchTerm.toUpperCase()) 
            );

            setFilteredArray(filteredItems);
        }
        return <SerachInput title="جستجوی تانک" state={searchItem} handler={handleInputSearch}/>
    }
    
    const ShowModalBody = () => {
        return(
          <ul className='w-[20rem] sm:w-[25rem] space-y-2'>
            {
              tableHeadFull.map((item,key)=>(
                <li key={key} className='flex flex-row items-center'>
                  <span className='text-sm w-2/5'>{item.pName}:</span>
                    <input
                      type="text" 
                      disabled
                      id={item.eName}
                      className="p-2 w-full sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " 
                      placeholder={item.pName}
                      value={showModalData[item.eName]} 
                    />
                </li>
              ))
            }
          </ul>
        )
    }

    const handlerShowFunc = (item: showModalDataTypes) => {
        setOpenShowModal(true)
        setShowModalData({id:item.id, tankCode:item.tankCode, tankNumber:item.tankNumber, chassisNumber:item.chassisNumber, name:item.name, family:item.family, number:item.number, plateNumber:item.plateNumber })
    }

    return (
        loader ? <FullLoader /> :
            <>
                <Guest_Header />

                <p className='font-bold text-4xl py-8 pb-0 w-full text-center pt-24'>
                    لیست تانک ها
                </p>
                <p className='font-bold text-پی p-6 w-full text-center'>
                    توضیحات: برای نمایش جزئیات, تانک مورد نظر را انتخاب کنید
                </p>

                {
                    loader ?
                        <></>
                    :
                        <div className='px-4 mb-4 sm:px-12'>
                            {SearchTableData()}
                            <AdminTableList 
                                TableList={filteredArray} 
                                tableHeadParams={tableHead} 
                                handlerShowFunc={handlerShowFunc}
                            />
                        </div>
                }

                <Modal 
                    title={'جزئیات تانکر'}
                    body={<ShowModalBody/>}
                    onOpen={openShowModal}
                    btnCancel={setOpenShowModal}
                />
            </>
    );
}