import React, {useState, useEffect} from 'react'
import Member_Header from './header.tsx';
import axios from 'axios';
import { HandlerNavigateTankCode, openImageInNewTab, ConvertDateToJalali } from '../../components/common.tsx';
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText } from '../../array/array.ts';
import {ShowProtectedImage} from '../../components/showProtectedImage.tsx';

const albumArray = [
    {title:'عکس جلوی تانکر'},
    {title:'عکس سمت راننده - جلوی تانکر'},
    {title:'عکس سمت راننده - وسط تانکر'},
    {title:'عکس لاستیک های سمت راننده'},
    {title:'عکس عقب تانکر'},
    {title:'عکس سمت شاگرد - جلوی تانکر'},
    {title:'عکس سمت شاگرد - وسط تانکر'},
    {title:'عکس لاستیک های سمت شاگرد'},
    {title:'عکس شیرآلات'},
]

const TankerPhotosList = () => {
    const navigate = useNavigate();
    const tankCode = localStorage.getItem("tankCode");
    const tankId = localStorage.getItem("tankId");
    
    const [loader, setLoader] = useState<boolean>(false)
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [registeredPhotosArray, setRegisteredPhotosArray] = useState<any>([])
    
    const [data, setData] = useState<any>({});

    useEffect(() => {
        const fetchData = async () => {
            setLoader(true)
            
            axios.post(`${process.env.REACT_APP_API_ADDRESS}users/tankPhotosDate`, 
            {
                tank_id: tankId,
            },
            {
                headers: {
                    'auth-token': Cookies.get('token'),
                    'auth-id': Cookies.get('id')
                }
            })
            .then(response => {
                setRegisteredPhotosArray([])
                response.data.map((item) => (
                    setRegisteredPhotosArray(prev => [...prev, {photoId: item.photo_id,date:ConvertDateToJalali(item.createAt)}])
                ));
                // console.log('Response:', response.data);
                setLoader(false)
            })
            .catch(error => {
                // console.error('Error:', error);
                setLoader(false)
                alert(loginText)
                navigate('/member')
            })
        }
        
        fetchData();
    }, []);

    const handlerSelectedDate = async (photoId) => {
        setLoader(true)
        setSelectedDate(photoId)

        axios.post(`${process.env.REACT_APP_API_ADDRESS}users/tankPhotosList`, 
          {
            photo_id:photoId
          },
          {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
        })
          .then(response => {
                setData({})
                response.data.map((item)=>(
                    setData({
                        0: item.imageFront,
                        1: item.imageDriverSideFront,
                        2: item.imageDriverSideCenter,
                        3: item.imageDriverSideBack,
                        4: item.imageBack,
                        5: item.imageChauffeurSideFront,
                        6: item.imageChauffeurSideCenter,
                        7: item.imageChauffeurSideBack,
                        8: item.faucets
                    })
                ))
                // console.log('Response:', response.data);
                setLoader(false)
          })
          .catch(error => {
            //   console.error('Error:', error);
                setLoader(false)
                alert(loginText)
                navigate('/member')
          }
        )
    };
    
    const TankerPhotos = () => {
        return(
            albumArray.map((item, key)=>(
                <div 
                    key={key}
                    className={`w-72 h-60 pb-9 flex items-center justify-center relative overflow-hidden border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 hover:border-gray-400`}
                    // onClick={()=>openImageInNewTab(process.env.REACT_APP_API_ADDRESS + 'tankPhotos' + data[key])}
                >
                    <label htmlFor={item.title} className="flex flex-col items-center justify-center cursor-pointer w-full h-full text-center">
                        <ShowProtectedImage
                            imageRoute={'tankPhotos'} 
                            imageName={data[key]} 
                            imageAlt={item.title + ' ثبت نشده '} 
                            imageClass={'w-full h-full object-contain' }
                        />
                        {/* <img 
                            src={process.env.REACT_APP_API_ADDRESS + 'tankPhotos' + data[key]} 
                            alt={item.title + ' ثبت نشده '} 
                            className='w-full h-full object-contain'   
                        /> */}
                    </label>
                    <p className='absolute w-full h-fit p-2 text-gray-100 text-sm text-center bottom-0 rounded-b-lg bg-gradient-to-r from-indigo-500 to-blue-500 cursor-pointer'>{item.title}</p>  
                </div>
            ))
        )
    }

    const RegisteredPhoto = () => {
        return(
            registeredPhotosArray.map((item, key)=>(
                <p 
                    key={key}
                    onClick={()=>handlerSelectedDate(item.photoId)}
                    className={`${selectedDate === item.photoId ? 'border-green-600 text-green-900 text-base' : 'border-zinc-500'} px-8 py-2 text-sm rounded-lg border-2  bg-transparent hover:shadow-md hover:bg-zinc-50 text-right cursor-pointer hover:scale-[1.02]`}
                >
                    {item.date} 
                </p>
            ))
        )
    }
    
    return (
        <div className='m-r-nav'>
            {loader && <FullLoader />}
            <Member_Header tankName={tankCode+' لیست عکس های '} desc='برای نمایش عکس های تانک ابتدا تاریخ مورد نظر را انتخاب کنید'/>
            {/* <Member_Header title={`${params.name} عکس های تانک`} desc='برای نمایش عکس های تانک ابتدا تاریخ مورد نظر را انتخاب کنید' backTo={'/admin/tankerPhotosList'}/> */}
            {
                tankCode ?
                    <div>
                        <div className='center flex-wrap flex-row-reverse p-4 gap-10'>
                            <div className='w-full flex items-end justify-center sm:justify-start flex-row-reverse flex-wrap gap-4' >
                                <RegisteredPhoto />
                            </div>
                            {
                                selectedDate ?
                                    <TankerPhotos />
                                :
                                    !registeredPhotosArray[0] ? <p> عکسی ثبت نشد</p> : <p>لطفا تاریخ مد نظر را انتخاب کنید</p> 
                            }
                        </div>
                    </div>
                :
                    <HandlerNavigateTankCode />
            }
        </div>
    )
}

export default TankerPhotosList
