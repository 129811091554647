import React from 'react'
import { IoWarningOutline } from "react-icons/io5";
import { RiErrorWarningFill } from "react-icons/ri";
import { FaRegCheckCircle } from "react-icons/fa";

interface modalType {
    title: string;
    body?: string | React.ReactElement;
    icon?: "success" | "warning" | "danger";
    btnCancel: React.Dispatch<React.SetStateAction<boolean>>;
    btnSubmit?: any;
    btnClear?: any;
    btnDelete?: any;
    btnEdit?: any;
    children?: React.ReactNode;
    onOpen: boolean,
    paddingTop?: boolean
}

// ----- usage ----

// ----- import { Modal } from '../../components/modal.tsx'
// ----- const [openModal, setOpenModal] = useState<boolean>(false)
//       <Modal 
//          onOpen={openModal}
//          icon="success"
//          title="ثبت امضای تحویل گیرنده"
//          body="لطفا در کادر پایین امضای خود را وارد و سپس آن را ثبت کنید"
//          btnCancel={setOpenModal}
//          btnClear={}
//          btnSubmit={}
//     />

export const Modal = ({title, body, icon, btnCancel, btnSubmit, btnClear, btnDelete, btnEdit, children, onOpen, paddingTop}:modalType) => {
    return (
        onOpen && 
            <div className="fixed inset-0 pt-0 sm:pt-10 center w-screen h-screen bg-zinc-900 bg-opacity-75 " aria-labelledby={title} role="dialog" aria-modal="true" dir='rtl' >
                <div 
                    className="fixed w-screen h-screen z-40" 
                    onClick={()=>btnCancel(false)}
                />

                <div className="relative z-40 top-4 mr-0 sm:mr-16 overflow-y-scroll hideScrollBar max-h-[80%] sm:max-h-[90%] rounded-lg bg-white shadow-xl max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="flex items-start gap-3">
                            {
                                icon &&
                                    <div className={`${icon === "danger"?'bg-red-100':icon === "warning" ?'bg-yellow-100':'bg-green-100'} mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10`}>
                                            {
                                                icon === "danger" ?
                                                    <RiErrorWarningFill size={'1.4rem'} className='text-red-600'/>
                                                :
                                                icon === "warning" ?
                                                    <IoWarningOutline size={'1.4rem'} className='text-yellow-600'/>
                                                :
                                                    <FaRegCheckCircle size={'1.4rem'} className='text-green-600'/>
                                            }
                                    </div>
                            }
                            <div className="mt-3 text-center sm:mt-0 sm:text-left" style={paddingTop ? {paddingTop:'10rem'} : {}}>
                                {title && <h3 className="text-base text-right font-semibold leading-6 text-gray-900" id="modal-title">{title}</h3>}
                                {
                                    body &&
                                        <div className="mt-2">
                                            <p className="text-sm text-right text-gray-500">{body}</p>
                                        </div>
                                }
                                {children}
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 flex flex-row sm:px-6 gap-1">
                        {
                            btnSubmit &&
                                <button 
                                    type="button" 
                                    onClick={btnSubmit}
                                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-green-500 sm:w-auto"
                                >
                                    ثبت
                                </button>
                        }
                        {
                            btnDelete &&
                                <button 
                                    type="button" 
                                    onClick={btnDelete}
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-red-500 sm:w-auto"
                                >
                                    حذف
                                </button>
                        }
                        {
                            btnEdit &&
                                <button 
                                    type="button" 
                                    onClick={btnEdit}
                                    className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    ویرایش
                                </button>
                        }
                        {
                            btnClear &&
                                <button 
                                    type="button" 
                                    onClick={btnClear}
                                    className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    پاک کردن
                                </button>
                        }
                        {
                            btnCancel &&
                                <button 
                                    type="button" 
                                    onClick={()=>btnCancel(false)}
                                    className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    بستن
                                </button>
                        }
                    </div>
                </div>
            </div>
            
    )
}

// <div className={``} aria-labelledby={title} role="dialog" aria-modal="true" dir='rtl' >
            //     <div className="fixed w-screen h-screen z-50 bg-zinc-900 bg-opacity-75" onClick={()=>console.log('d')}/>

            //     <div className="fixed z-50 py-4 inset-0 center overflow-y-scroll">
            //         <div className="mt-28 transform overflow-hidden rounded-lg bg-white shadow-xl mx-4 max-w-lg">
            //             <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            //                 <div className="flex items-start gap-3">
            //                     {
            //                         icon &&
            //                             <div className={`${icon === "danger"?'bg-red-100':icon === "warning" ?'bg-yellow-100':'bg-green-100'} mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10`}>
            //                                     {
            //                                         icon === "danger" ?
            //                                             <RiErrorWarningFill size={'1.4rem'} className='text-red-600'/>
            //                                         :
            //                                         icon === "warning" ?
            //                                             <IoWarningOutline size={'1.4rem'} className='text-yellow-600'/>
            //                                         :
            //                                             <FaRegCheckCircle size={'1.4rem'} className='text-green-600'/>
            //                                     }
            //                             </div>
            //                     }
            //                     <div className="mt-3 text-center sm:mt-0 sm:text-left" style={paddingTop ? {paddingTop:'10rem'} : {}}>
            //                         {title && <h3 className="text-base text-right font-semibold leading-6 text-gray-900" id="modal-title">{title}</h3>}
            //                         {
            //                             body &&
            //                                 <div className="mt-2">
            //                                     <p className="text-sm text-right text-gray-500">{body}</p>
            //                                 </div>
            //                         }
            //                         {children}
            //                     </div>
            //                 </div>
            //             </div>
            //             <div className="bg-gray-50 px-4 py-3 flex flex-row sm:px-6 gap-1">
            //                 {
            //                     btnSubmit &&
            //                         <button 
            //                             type="button" 
            //                             onClick={btnSubmit}
            //                             className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-green-500 sm:w-auto"
            //                         >
            //                             ثبت
            //                         </button>
            //                 }
            //                 {
            //                     btnDelete &&
            //                         <button 
            //                             type="button" 
            //                             onClick={btnDelete}
            //                             className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-red-500 sm:w-auto"
            //                         >
            //                             حذف
            //                         </button>
            //                 }
            //                 {
            //                     btnEdit &&
            //                         <button 
            //                             type="button" 
            //                             onClick={btnEdit}
            //                             className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            //                         >
            //                             ویرایش
            //                         </button>
            //                 }
            //                 {
            //                     btnClear &&
            //                         <button 
            //                             type="button" 
            //                             onClick={btnClear}
            //                             className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            //                         >
            //                             پاک کردن
            //                         </button>
            //                 }
            //                 {
            //                     btnCancel &&
            //                         <button 
            //                             type="button" 
            //                             onClick={()=>btnCancel(false)}
            //                             className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            //                         >
            //                             بستن
            //                         </button>
            //                 }
            //             </div>
            //         </div>
            //     </div>
            // </div>