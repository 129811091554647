import React, { useState, useEffect } from 'react';
import Admin_Header from './header.tsx';
import { AdminTableList } from '../../components/adminTableList.tsx'
import { SerachInput, DropDownSearchStatus, ConvertPersianNumToEnglishNum, handlerGetUsersTanks } from '../../components/common.tsx';
import axios from 'axios';
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText } from '../../array/array.ts';

const tableHead = [
    {pName:"کد تانکر", eName:"tankCode", type:"string/number", sorted:true},
    {pName:"نام راننده", eName:"name", type:"string", sorted:true},
    {pName:"فامیل راننده", eName:"family", type:"string", sorted:true},
    {pName:"شماره راننده", eName:"number", type:"string",},
]

const Admin_DeliveryReport = () => {
    const navigate = useNavigate();
  
    const [loader, setLoader] = useState<boolean>(false)  
    const [searchDropDownParam, setSearchDropDownParam] = useState<string>(tableHead[0].pName)
    const [filteredArray, setFilteredArray] = useState<any[]>([])
    const [data, setData] = useState<any[]>([])
    const [searchItem, setSearchItem] = useState<string>('')

    useEffect(() => {
        setLoader(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}admin/tankerAndDriverList`, {
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                handlerGetUsersTanks(setData, setFilteredArray, response.data)
                setLoader(false);
            } catch (error) {
                // console.error('Error fetching tanks:', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate('/admin')
            }
        };
    
        fetchData();
    }, []);

    const SearchTableData = () => { 
        const handleInputSearch = (e) => { 
            const searchTerm = ConvertPersianNumToEnglishNum(e.target.value);
            setSearchItem(searchTerm)
                const filteredItems = data.filter((item) =>
                    searchDropDownParam === tableHead[0].pName ?
                        item.tankCode && item.tankCode.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase()) :
                    searchDropDownParam === tableHead[1].pName ?
                        item.name && item.name.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase()) :
                    searchDropDownParam === tableHead[2].pName ?
                        item.family && item.family.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase()) :
                    searchDropDownParam === tableHead[3].pName ?
                        item.number && item.number.includes(searchTerm):
                    null
            );
            setFilteredArray(filteredItems);
        }
        return(
            <div className='w-full p-3 center flex sm:items-end sm:justify-end flex-row gap-3'>
                <SerachInput title={"جستجوی " + searchDropDownParam} state={searchItem} handler={handleInputSearch}/>
                <DropDownSearchStatus list={tableHead} state={searchDropDownParam} setState={setSearchDropDownParam}/>
            </div>
        )
    }
    
    return (
        <div className='m-r-nav'>
            {loader && <FullLoader />}

            <Admin_Header title='لیست گزارشات تحویل' desc='توضیحات: ابتدا تانک مورد نظر را انتخاب کرده. سپس دکمه انتخاب را کلیک کنید تا وارد صفحه ی گزارشات تانک شوید' />
            <div className='px-4 mb-4 sm:px-12'>
                {SearchTableData()}
                <AdminTableList 
                    TableList={filteredArray} 
                    tableHeadParams={tableHead} 
                    linkTo={'/admin/deliveryReport/'}
                />
            </div>

        </div>
    )
}

export default Admin_DeliveryReport
