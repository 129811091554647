import React, { useState,useEffect } from 'react'
import axios from 'axios';
import { FullLoader } from '../components/loader.tsx';
import Cookies from 'js-cookie';
import { loginText } from '../array/array.ts';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import {ShowProtectedImage} from '../components/showProtectedImage.tsx';

interface dataType {
    companyName:string;
    chassisNumber:string;
    deliveryDate:string;
    contractNumber:string;
    fromState:string;
    fullDesc:string;
    driverName:string;
    driverFamily:string;
    driverSigImg:string;
    userName:string;
    userFamily:string;
    userSigImg:string;
}

interface listType {
    name: string;
    state: string;
}

const PrintableDeliveryReport = () => {
    // Split the URL based on the '=' character
    const parts = window.location.href.split('=');
    const navigate = useNavigate();
    const params = useParams();
    const pathName = useLocation();
    const [loader, setLoader] = useState<boolean>(false)  
    const [error, setError] = useState<boolean>(false)  
    const [data, setData] = useState(pathName.state.params.data)
    const [list, setList] = useState(pathName.state.params.list)

    function BoldParagraphText({children}:{children:React.ReactNode}) {
        return(
            <span className=' underline underline-offset-4 text-black font-bold px-1'>
                {" "+children+" "}
            </span>
        )
    }

    function TankParams() {
        return(
            <div className='w-full grid grid-cols-2 grid-rows-[repeat(22,minmax(0,1fr))] grid-flow-col' dir='rtl'>
                {
                    list.map((item, key)=>(
                        <div key={key} className={`flex flex-row items-start px-4 py-2 border-b-2 ${key < 22 && 'border-l-2'}`}>
                            <p className='w-2/3 text-sm'>
                                <span>{key+1}- </span>
                                <span>{item.name}</span>
                            </p>
                            <p className='w-1/3 text-sm text-center'>
                                {item.state}
                            </p>
                        </div>
                    ))
                }
            </div>
        )
    }

    useEffect(() => {
        setLoader(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}adminCheckAuth`, {
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                setLoader(false);
                // console.log(response.data)
            } catch (error) {
                // console.error('Error conecting server', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate(-1)
            }
        };
    
        fetchData();
    }, []);
    
    const handlePrint = () => {
        window.print();
    };

    return (
        <div className=''>
            {loader && <FullLoader />}
            {
                error === true?
                    <p>خطا در نمایش فرم</p>
                :
                    <>
                        <a onClick={()=>navigate(-1)} className='p-0 sm:p-0 cursor-pointer hover:underline underline-offset-4 flex flex-row gap-2 '>
                            <FaArrowLeft className=' text-zinc-800 '/>
                            <p className='text-sm text-zinc-800 '>برگشت به صفحه قبل</p>
                        </a>

                        <div id={'printFinalTank'} className='w-[1000px] border-2 border-black rounded-md p-4 space-y-2'>
                            <div className='w-full flex flex-row items-center justify-between pb-4'>
                                <button onClick={handlePrint} className={"w-1/8 text-sm px-4 py-2 text-white bg-green-700 hover:bg-green-600 rounded hover:scale-[1.02] "}>
                                    چاپ فرم
                                </button>
                                <p className='w-6/8 text-center text-lg font-black'>فرم گزارش تحویل مخزن گاز مایع به راننده</p>
                                <p className='text-xs w-1/8'>{new Date().toLocaleDateString('fa-IR')} : تاریخ چاپ</p>
                            </div>
                            
                            <p className='w-full min-h-20 text-justify text-gray-700 leading-loose text-sm' dir={'rtl'}>
                                بدینوسیله یک مخزن حمل گاز مایع ساخت کارخانه
                                <BoldParagraphText>{data.companyName}</BoldParagraphText>
                                به شماره شاسی
                                <BoldParagraphText>{data.chassisNumber}</BoldParagraphText>
                                در تاریخ
                                <BoldParagraphText>{data.deliveryDate}</BoldParagraphText>
                                طبق قرارداد شماره
                                <BoldParagraphText>{data.contractNumber}</BoldParagraphText>
                                به آقای
                                <BoldParagraphText>{data.driverName +' '+ data.driverFamily}</BoldParagraphText>
                                اعزامی اعزامی
                                <BoldParagraphText>{data.fromState}</BoldParagraphText>
                                به شرح اقلام مشروحه ذیل تحویل گردید
                                <>:</>
                            </p>
            
                            <div className='pb-4'>
                                <TankParams/>
                            </div>
            
                            {/* <div className='center w-full px-8 py-10'>
                                <WheelsPercent />
                            </div> */}
                            
                            {/* <div className='flex flex-row px-8 py-10'>
                                <div className='w-full flex flex-row-reverse items-center m-auto p-2 gap-2'>
                                    <p className='text-sm'>:لاستیک ها</p>
                                    <WheelsPercent />
                                    <p className='rotate-90 text-xs px-4 py-1 line-clamp-1 rounded-sm ring-1 ring-black bg-slate-100 -mr-8 text-nowrap'>عقب کشنده</p>
                                </div>
            
                                <div className='w-full flex flex-row-reverse items-center m-auto p-2 gap-2'>
                                    <p className='text-sm'>:لنت ها</p>
                                    <WheelsPercent />
                                    <p className='rotate-90 text-xs px-4 py-1 line-clamp-1 rounded-sm ring-1 ring-black bg-slate-100 -mr-8 text-nowrap'>عقب کشنده</p>
                                </div>
                            </div> */}
            
                            <div className='space-y-1 min-h-[5rem] h-3 px-3' dir='rtl'>
                                <p className='text-sm font-black'> توضیحات : </p>
                                <p className='text-xs font-thin leading-loose'>
                                    {data.fullDesc}
                                </p>
                            </div>
            
                            <div className='w-full p-4 pt-2 flex flex-row items-center justify-around'>
                                <div className='center flex-row gap-4'>
                                    <ShowProtectedImage
                                        imageRoute='signaturePhotos'
                                        imageName={data.driverSigImg}
                                        imageAlt={'عکس امضا ثبت نشده است'} 
                                        imageClass={'w-44 max-h-44 object-contain text-center'}
                                    />
                                    {/* <img
                                        className='w-44 max-h-44 object-contain text-center'
                                        src={process.env.REACT_APP_API_ADDRESS + 'signaturePhotos' + data.driverSigImg} 
                                        alt={'عکس امضا ثبت نشده است'}
                                    />   */}
                                    <p className='text-sm text-center'>
                                        <p>:تحویل گیرنده </p>
                                        <p>{data.driverName +' '+ data.driverFamily}</p>
                                    </p>
                                </div>

                                <div className='center flex-row gap-4'>
                                    <ShowProtectedImage
                                        imageRoute='signaturePhotos'
                                        imageName={data.userSigImg}
                                        imageAlt={'عکس امضا ثبت نشده است'} 
                                        imageClass={'w-44 max-h-44 object-contain text-center'}
                                    />
                                    {/* <img 
                                        className='w-44 max-h-44 object-contain text-center'
                                        src={process.env.REACT_APP_API_ADDRESS + 'signaturePhotos' + data.userSigImg} 
                                        alt={'عکس امضا ثبت نشده است'}
                                    />   */}
                                    <p className='text-sm text-center'>
                                        <p>:تحویل دهنده </p>
                                        <p>{data.userName +' '+ data.userFamily}</p>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
            }

        </div>
    );  
}

export default PrintableDeliveryReport
