import React, { useEffect, useState} from 'react'
import Admin_Header from './header.tsx'
import { DropDownSearchStatus, HandlerChangeDigitToEn, SerachInput, ConvertPersianNumToEnglishNum, ConvertNumberToText, DatePicketComponent, ConvertNumberSeparate, DropDownDefualtValue } from '../../components/common.tsx'
import { Modal } from '../../components/modal.tsx'
import axios from 'axios'
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { cityArray, loginText, partArray, selecedTonnageArray, uploadError } from '../../array/array.ts';
import { AdminTableList } from '../../components/adminTableList.tsx';
import { PrimaryBtn, TransparentLink } from '../../components/btn.tsx';

interface ShowModalDataTypes {
  refinery_id:number,
  tankCode:string,
  driverName:string, 
  driverNumber:number, 
  driverPlateNumber:string,
  loadingDate:string, 
  evacuationDate:string, 
  tonnage:number,
  description:string
}

interface deleteModalDataTypes {
  refinery_id:number, 
  name:string, 
  family?:string,
}

interface updateModalDataTypes {
  refinery_id:number,
  loadingDate:string, 
  evacuationDate:string, 
  tonnage:number,
  description:string,
  tankCode?:string,
  driverName?:string, 
  driverNumber?:number, 
  driverPlateNumber?:string,
}


const tableHead = [
  {pName:"کد تانکر", eName:"tankCode", type:"string/number", sorted:true, showAllData:true},
  {pName:"تاریخ بارگیری", eName:"loadingDate", type:"date", sorted:true, showAllData:true},
  {pName:"تاریخ تخلیه", eName:"evacuationDate", type:"date", sorted:true, showAllData:true},
  {pName:"تناژ", eName:"tonnage", type:"number", sorted:true, showAllData:true, numberSeparate:true},
  {pName:"توضیحات", eName:"description", type:"string", sorted:true, showAllData:true},
]

const tableHeadParams = [
  {pName:"کد تانکر", eName:"tankCode", type:"string/number"},
  {pName:"نام راننده", eName:"driverName", type:"string"},
  {pName:"شماره راننده", eName:"driverNumber", type:"number"},
  {pName:"پلاک", eName:"driverPlateNumber", type:"string/number"},
  {pName:"تاریخ بارگیری", eName:"loadingDate", type:"date", star:true},
  {pName:"تاریخ تخلیه", eName:"evacuationDate", type:"date", star:true},
  {pName:"تناژ", eName:"tonnage", type:"number", star:true, numberSeparate:true},
  {pName:"توضیحات", eName:"description", type:"string", star:true},
]

const tableHeadSearchParams = [
  {pName:"نام راننده", eName:"driverName", type:"string"},
  {pName:"شماره راننده", eName:"driverNumber", type:"number"},
  {pName:"کد تانکر", eName:"tankCode", type:"string/number"},
  {pName:"توضیحات", eName:"description", type:"string"},
]

const Admin_RefineryLoadingList = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState<boolean>(false)
  const [refreshPage, setRefreshPage] = useState<number>(0)
  const [searchDropDownParam, setSearchDropDownParam] = useState<string>(tableHeadSearchParams[0].pName)
  const [data, setData] = useState<any[]>([])
  const [filteredArray, setFilteredArray] = useState<any[]>([])
  const [searchItem, setSearchItem] = useState<string>('')
  const [totalTonnage, setTotalTonnage] = useState<number>()
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const [searchStatus, setSearchStatus] = useState<'loadingDate'|'evacuationDate'>('loadingDate')
  const [city, setCity] = useState<string>(cityArray[0].title)
  const [part, setPart] = useState<string>(partArray[0].title)
  const [selecedTonnage, setSelecedTonnage] = useState<number>(selecedTonnageArray[0].title)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [deleteModalData, setDeleteModalData] = useState<deleteModalDataTypes>({refinery_id:0, name:'', family:''})
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false)
  const [updateModalData, setUpdateModalData] = useState<updateModalDataTypes>({refinery_id:0,tankCode:'',loadingDate:'',evacuationDate:'',tonnage:0,description:''})
  // const [openInsertModal, setOpenInsertModal] = useState<boolean>(false)
  // const [insertModalData, setInsertModalData] = useState<{}>({})
  const [openShowModal, setOpenShowModal] = useState<boolean>(false)
  const [showModalData, setShowModalData] = useState<{}>({})
  
  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}admin/refineryLoadingList`,   
          {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
          }
        );
        setFilteredArray([]);
        setData([]);

        // ----- show custom tanks for custom users ------
        if(localStorage.getItem('tank22to46')){
          response.data.filter((item)=>{
            const newTankCode = item.tankCode.slice(1,3)
            const newItem = newTankCode >= 22;
            if(newItem){
              setFilteredArray(prev=>[...prev,{
                refinery_id:item.refinery_id, 
                loadingDate:item.loadingDate,
                evacuationDate:item.evacuationDate, 
                tonnage:item.tonnage, 
                description:item.description,  
                tank_id:item.tank_id, 
                tankCode:item.tankCode, 
                tankNumber:item.tankNumber, 
                chassisNumber:item.chassisNumber, 
                driverName:item.family+' '+item.name, 
                driverNumber:item.number, 
                driverPlateNumber:item.plateNumber
              }]);
              setData(prev=>[...prev,{
                repair_id:item.repair_id, 
                refinery_id:item.refinery_id, 
                loadingDate:item.loadingDate,
                evacuationDate:item.evacuationDate, 
                tonnage:item.tonnage, 
                description:item.description,  
                tank_id:item.tank_id, 
                tankCode:item.tankCode, 
                tankNumber:item.tankNumber, 
                chassisNumber:item.chassisNumber, 
                driverName:item.family+' '+item.name, 
                driverNumber:item.number, 
                driverPlateNumber:item.plateNumber
              }])
              }
          })
        }else{
          response.data.map((item)=>(
            setFilteredArray(prev=>[...prev,{
              refinery_id:item.refinery_id, 
              loadingDate:item.loadingDate,
              evacuationDate:item.evacuationDate, 
              tonnage:item.tonnage, 
              description:item.description,  
              tank_id:item.tank_id, 
              tankCode:item.tankCode, 
              tankNumber:item.tankNumber, 
              chassisNumber:item.chassisNumber, 
              driverName:item.family+' '+item.name, 
              driverNumber:item.number, 
              driverPlateNumber:item.plateNumber
            }]),
            setData(prev=>[...prev,{
              repair_id:item.repair_id, 
              refinery_id:item.refinery_id, 
              loadingDate:item.loadingDate,
              evacuationDate:item.evacuationDate, 
              tonnage:item.tonnage, 
              description:item.description,  
              tank_id:item.tank_id, 
              tankCode:item.tankCode, 
              tankNumber:item.tankNumber, 
              chassisNumber:item.chassisNumber, 
              driverName:item.family+' '+item.name, 
              driverNumber:item.number, 
              driverPlateNumber:item.plateNumber
            }])
          ))
        }

        setLoader(false);
      } catch (error) {
        // console.error('Error Fetching Repair List:', error);
        setLoader(false); // Hide loader after fetching data
        alert(loginText)
        navigate('/admin')
      }
    };

    fetchData();
  }, [!endDate && refreshPage]);

  // ----- update totalTonnage after filter
  useEffect(()=>{
    setTotalTonnage(0)
    filteredArray.map((item)=>{
      setTotalTonnage(prevTotalTonnage => prevTotalTonnage + item.tonnage);
    })
  },[filteredArray])
  
  const handlerShowFunc = (item:ShowModalDataTypes) => {
    setOpenShowModal(true)
    setShowModalData({
      refinery_id: item.refinery_id,
      tankCode:item.tankCode,
      driverName:item.driverName, 
      driverNumber:item.driverNumber, 
      driverPlateNumber:item.driverPlateNumber,
      loadingDate:item.loadingDate,
      evacuationDate:item.evacuationDate, 
      tonnage:item.tonnage, 
      description:item.description, 
    })
  }

  const handlerDeleteFunc = (item: deleteModalDataTypes) => {
    setOpenDeleteModal(true)
    setDeleteModalData({refinery_id:item.refinery_id, name:item.loadingDate})
  } 
  
  const handlerUpdateFunc = (item: ShowModalDataTypes) => {
    setOpenUpdateModal(true)
    setUpdateModalData({
      refinery_id: item.refinery_id,
      tankCode:item.tankCode,
      driverName:item.driverName, 
      driverNumber:item.driverNumber, 
      driverPlateNumber:item.driverPlateNumber,
      loadingDate:item.loadingDate,
      evacuationDate:item.evacuationDate, 
      tonnage:item.tonnage, 
      description:item.description, 
    })
  }

  const handlerUpdateQuery = async () => {
    if(updateModalData.loadingDate, updateModalData.evacuationDate, updateModalData.tonnage){
      setLoader(true)
      await axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/updateRefineryLoading`, 
        {
            refinery_id: updateModalData.refinery_id,
            user_id: Cookies.get('id'),
            loadingDate:  HandlerChangeDigitToEn(updateModalData.loadingDate),
            evacuationDate:  HandlerChangeDigitToEn(updateModalData.evacuationDate),
            tonnage:  ConvertPersianNumToEnglishNum(String(updateModalData.tonnage)),
            description: updateModalData.description
        },
        {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
        })
        .then(response => {
            // console.log('Response:', response.data);
            setLoader(false)
            // alert('ردیف انتخابی به درستی ویرایش شد')
            setRefreshPage((prev) => prev + 1)
            setOpenUpdateModal(false)
            if(startDate && endDate){handlerShowQuery(searchStatus)}
        })
        .catch(error => {
            // console.error('Error:', error);
            setLoader(false); // Hide loader after fetching data
            alert(uploadError)
            // alert(loginText)
            // navigate('/admin')
        }
      )
    }else{
      alert('برای ویرایش اطلاعات باید فیلد های تاریخ بارگیری , تاریخ تخلیه , تناژ و توضیحات کامل باشند .')
    }
  };

  const handlerDeleteQuery = async () => {
    setLoader(true)
    axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/DeleteRefineryLoading`, 
        {
            user_id: Cookies.get('id'),
            refinery_id: deleteModalData.refinery_id
        },
        {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
        })
        .then(response => {
            console.log('Response:', response.data);
            setLoader(false)
            setRefreshPage((prev) => prev + 1)
            setOpenDeleteModal(false)
            if(startDate && endDate){handlerShowQuery(searchStatus)}
        })
        .catch(error => {
            // console.error('Error:', error);
            setLoader(false); // Hide loader after fetching data
            alert(uploadError)
            // alert(loginText)
            // navigate('/admin')       
     })
  };

  async function handlerShowQuery(status:'loadingDate'|'evacuationDate') {
    setSearchStatus(status)
    if (startDate && endDate) {
      setLoader(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_ADDRESS}admin/refineryLoadingListSearchDate`, 
          {
            searchFor: status,
            loadingDate:HandlerChangeDigitToEn(startDate),
            evacuationDate: HandlerChangeDigitToEn(endDate),
          },  
          {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
          }
        );
        setFilteredArray([]);
        setData([]);
        // ----- show custom tanks for custom users ------
        if(localStorage.getItem('tank22to46')){
          response.data.filter((item)=>{
            const newTankCode = item.tankCode.slice(1,3)
            const newItem = newTankCode >= 22;
            if(newItem){
              setFilteredArray(prev=>[...prev,{
                refinery_id:item.refinery_id, 
              }]);
              setData(prev=>[...prev,{
                repair_id:item.repair_id, 
                refinery_id:item.refinery_id, 
                loadingDate:item.loadingDate,
                evacuationDate:item.evacuationDate, 
                tonnage:item.tonnage, 
                description:item.description,  
                tank_id:item.tank_id, 
                tankCode:item.tankCode, 
                tankNumber:item.tankNumber, 
                chassisNumber:item.chassisNumber, 
                driverName:item.family+' '+item.name, 
                driverNumber:item.number, 
                driverPlateNumber:item.plateNumber
              }])
              }
          })
        }else{
          response.data.map((item)=>(
            setFilteredArray(prev=>[...prev,{
              refinery_id:item.refinery_id, 
              loadingDate:item.loadingDate,
              evacuationDate:item.evacuationDate, 
              tonnage:item.tonnage, 
              description:item.description,  
              tank_id:item.tank_id, 
              tankCode:item.tankCode, 
              tankNumber:item.tankNumber, 
              chassisNumber:item.chassisNumber, 
              driverName:item.family+' '+item.name, 
              driverNumber:item.number, 
              driverPlateNumber:item.plateNumber
            }]),
            setData(prev=>[...prev,{
              repair_id:item.repair_id, 
              refinery_id:item.refinery_id, 
              loadingDate:item.loadingDate,
              evacuationDate:item.evacuationDate, 
              tonnage:item.tonnage, 
              description:item.description,  
              tank_id:item.tank_id, 
              tankCode:item.tankCode, 
              tankNumber:item.tankNumber, 
              chassisNumber:item.chassisNumber, 
              driverName:item.family+' '+item.name, 
              driverNumber:item.number, 
              driverPlateNumber:item.plateNumber
            }])
          ))
        }
        setLoader(false);
    } catch (error) {
      // console.error('Error Fetching Repair List:', error);
      setLoader(false); // Hide loader after fetching data
      alert(loginText)
      navigate('/admin')
    }
    }else{
     alert('لطفا تاریخ شروع و تاریخ پایان را انتخاب کنید')
    }
  }

  function SearchDate() {
    return (
      <div className='w-full'>
        {/* <p className='text-xs w-full text-right'>: جستجو بر اساس تاریخ تعویض </p> */}
        <div className='flex flex-row-reverse flex-wrap center sm:items-center sm:justify-start gap-3 my-4 px-4'>
          <div className='w-35'>
            <DatePicketComponent title="از تاریخ" state={startDate} setState={setStartDate} type='simple'/>
          </div>
          <div className='w-35'>
            <DatePicketComponent title="تا تاریخ" state={endDate} setState={setEndDate} type='simple'/>
          </div>
            <PrimaryBtn onClick={()=>handlerShowQuery('loadingDate')}>
              <p>جستجو براساس تاریخ بارگیری</p>
            </PrimaryBtn>
            <PrimaryBtn onClick={()=>handlerShowQuery('evacuationDate')}>
              <p>جستجو براساس تاریخ تخلیه</p>
            </PrimaryBtn>
        </div>
      </div>
    );
  }

  function SearchTableParams() {
    const handleInputSearch = (e) => { 
      const searchTerm = ConvertPersianNumToEnglishNum(e.target.value);
      setSearchItem(searchTerm);
      const filteredItems = data.filter((item) =>
          (searchDropDownParam === tableHeadSearchParams[0].pName && item.driverName && item.driverName.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase())) ||
          (searchDropDownParam === tableHeadSearchParams[1].pName && item.driverNumber && item.driverNumber.includes(searchTerm)) ||
          (searchDropDownParam === tableHeadSearchParams[2].pName && item.tankCode && item.tankCode.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase())) ||
          (searchDropDownParam === tableHeadSearchParams[3].pName && item.description && item.description.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase()))
      );
    
      setFilteredArray(filteredItems);
    }
    return(
      <>
        <div className='w-full flex flex-row center md:items-center md:justify-end gap-3 p-4'>
          <SerachInput title={"جستجوی " + searchDropDownParam} state={searchItem} handler={handleInputSearch}/>
          <DropDownSearchStatus list={tableHeadSearchParams} state={searchDropDownParam} setState={setSearchDropDownParam}/>
        </div>
      </>
    )
  }
  
  const showModalBody = () => {
    return(
      <ul className='w-[20rem] sm:w-[25rem] space-y-2'>
        {
          tableHeadParams.map((item,key)=>(
            <li key={key} className='flex flex-row items-center justify-start'>
              <span className='text-sm w-2/5'>
                {item.pName}:
              </span>
                {
                  item.eName === 'description'?
                    <textarea
                      disabled={true}
                      id={item.eName}
                      className="p-2 w-full min-h-20 sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " 
                      placeholder={item.pName}
                      value={showModalData[item.eName]}
                    />
                  :
                    <input
                      type="text" 
                      disabled={true}
                      id={item.eName}
                      className="p-2 w-full sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " 
                      placeholder={item.pName}
                      value={showModalData[item.eName]}
                    />
                }
            </li>
          ))
        }
      </ul>
    )
  }
  
  const updateModalBody = () => {
    return(
      <ul className='w-[20rem] sm:w-[25rem] space-y-2'>
        {
          tableHeadParams.map((item,key)=>(
            <li key={key} className='flex flex-row items-center justify-start'>
              <span className='text-sm w-2/5'>
                {item.pName}:
                {item.star && <span className='text-red-500'>*</span> }
              </span>
                {
                  item.type === 'date'?
                    <DatePicketComponent title={updateModalData[item.pName]} state={updateModalData[item.eName]} setState={e=>setUpdateModalData(prev=>({...prev, [item.eName]:e}))} />
                  :
                    item.eName === 'description'?
                      <textarea
                        id={item.eName}
                        className="p-2 w-full min-h-40 sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " 
                        placeholder={item.pName}
                        value={updateModalData[item.eName]}
                        onChange={e=>setUpdateModalData(prev=>({...prev, [item.eName]:e.target.value}))}
                      />
                    :
                      item.star ?
                        <input
                          type="text" 
                          id={item.eName}
                          className="p-2 w-full sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " 
                          placeholder={item.pName}
                          value={updateModalData[item.eName]}
                          onChange={e=>setUpdateModalData(prev=>({...prev, [item.eName]:e.target.value}))}
                        />
                      :
                        <input
                          type="text" 
                          disabled
                          id={item.eName}
                          className="p-2 w-full sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " 
                          placeholder={item.pName}
                          value={updateModalData[item.eName]}
                        />
                }
            </li>
          ))
        }
      </ul>
    )
  }
  
  return (
    <div className='m-r-nav'>
      {loader && <FullLoader />}
      <Admin_Header title={'لیست گزارشات بارگیری پالایشگاه'}/>

      <div className='px-4 mb-8 sm:px-8'>
        
        <p className='text-sm text-gray-600 p-4 pt-6 pb-0' dir='rtl'>
          <span className='text-gray-800'> نکته : </span>
          برای نمایش لیست ابتدا تاریخ مد نظر را انتخاب کرده سپس دکمه جستجو را کلیک کنید .
        </p>

        {SearchDate()}
        {SearchTableParams()}

        <p className='text-sm text-gray-600 p-4 pt-6 pb-2' dir='rtl'>
          <span className='text-gray-800 pt-8'> نکته : </span>
          قبل از چاپ فرم مقادیر تناژ , پارت و شهر را انتخاب کنید .
        </p>

        <div className='w-full flex items-center justify-between flex-wrap-reverse pb-6 pt-2 px-4 gap-4'>
          <TransparentLink 
            to={'/admin/printList/'} 
            params={{
              array:filteredArray, 
              listHead:tableHeadParams , 
              pageHeader:`${city} پارت ${part} (${selecedTonnage} تن)`,
              pageDescription:`مجموع تناژ ردیف های نمایش داده شده به عدد ${ConvertNumberSeparate(totalTonnage)} و به حروف ${ConvertNumberToText(totalTonnage)} (تن)`
            }} 
          >
            فرم چاپی
          </TransparentLink>

          <div className='center gap-4 flex-wrap'>
            <DropDownDefualtValue list={cityArray} state={city} setState={setCity} dropTitle='انتخاب شهر' cssClass='mb-0' wFit/>
            <DropDownDefualtValue list={partArray} state={part} setState={setPart} dropTitle='انتخاب پارت' cssClass='mb-0' wFit/>
            <DropDownDefualtValue list={selecedTonnageArray} state={selecedTonnage} setState={setSelecedTonnage} dropTitle='انتخاب تناژ' cssClass='mb-0' wFit/>
          </div>
        </div>

        <p className='w-full p-4 py-8 sm:py-4 text-center sm:text-right text-sm leading-6'>
          <span> مجموع تناژ ردیف های نمایش داده شده به عدد </span>
          <span className='font-black underline underline-offset-8'> {ConvertNumberSeparate(totalTonnage)} </span>
          <span> و به حروف </span>
          <span className='font-black underline underline-offset-8'> {ConvertNumberToText(totalTonnage)} </span>
          <span> (تن) </span>
        </p>

        <AdminTableList
          TableList={filteredArray} 
          tableHeadParams={tableHead} 
          handlerShowFunc={handlerShowFunc}
          handlerDeleteFunc={handlerDeleteFunc}
          handlerUpdateFunc={handlerUpdateFunc}
        />


      </div>
      
      <Modal 
        title='حذف ردیف'
        body={"میخوای ردیف با تاریخ " + deleteModalData.name + " حذف بشه؟ "}
        icon='warning'
        onOpen={openDeleteModal}
        btnCancel={setOpenDeleteModal}
        btnDelete={handlerDeleteQuery}
      />

      <Modal 
        title='ویرایش ردیف'
        body={updateModalBody()}
        onOpen={openUpdateModal}
        btnCancel={setOpenUpdateModal}
        btnSubmit={handlerUpdateQuery}
      />

      <Modal 
        title={'جزئیات ردیف'}
        body={showModalBody()}
        onOpen={openShowModal}
        btnCancel={setOpenShowModal}
      />

    </div>
  )
}

export default Admin_RefineryLoadingList
