import React, {useState, useEffect} from 'react'
import axios from 'axios';
import { FullLoader } from '../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText } from '../array/array.ts';
import { FaArrowLeft } from 'react-icons/fa';

const SiteServices = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState<boolean>(true)

    useEffect(() => {
        setLoader(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}userCheckAuth`, {
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                setLoader(false);
                // console.log(response.data)
            } catch (error) {
                // console.error('Error conecting server', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate('/')
            }
        };
    
        fetchData();
    }, []);

    return (
        <>
            {
                loader ? 
                    <FullLoader />
                :
                    <>
                        <a onClick={()=>navigate(-1)} className='p-0 sm:p-0 cursor-pointer hover:underline underline-offset-4 flex flex-row gap-2 '>
                            <FaArrowLeft className=' text-zinc-800 '/>
                            <p className='text-sm text-zinc-800 '>برگشت به صفحه قبل</p>
                        </a>

                        <embed className='w-full h-screen' src={require('../assets/pdf/services.pdf')} type="application/pdf"/>
                    </>
            }
        </>
    )
}

export default SiteServices
