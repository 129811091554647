import React from 'react'

export function FullLoader () {
  return (
    <div className='z-50 fixed w-full h-full'>
      <div className='absolute w-full h-full bg-zinc-800 opacity-60' />
      <div className='absolute w-full h-full center'>
        <div className='p-8 pb-6 bg-white rounded-lg shadow-lg'>
            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite]" />
        </div>
      </div>
    </div>
  )
}
