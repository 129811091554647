import React,{ useState, useEffect } from 'react';
import Member_Header from './header.tsx'
import { AdminTableList } from '../../components/adminTableList.tsx';
import { PrimaryBtn, TransparentLink } from '../../components/btn.tsx';
import { DropDownSearchStatus, ImageCard, SerachInput, ConvertPersianNumToEnglishNum } from '../../components/common.tsx';
import { Modal } from '../../components/modal.tsx';
import axios from 'axios';
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText, uploadError } from '../../array/array.ts';
import {ShowProtectedImage} from '../../components/showProtectedImage.tsx';

export interface updateModalDataTypes {
  driver_id: number;
  name: string;
  family: string;
  number: string;
  nationalCode?: number;
  address?: string;
  cartNumber?: number;
  plateNumber?: string;
  conventionStartDate?: string;
  conventionEndDate?: string;
  sig?: any;
  tankNumber?: number;
  tankCode?: string;
  sigImg?: {
    url: string;
    size: number;
    type: string;
    base64: string;
  };
}

export interface ShowModalDataTypes {
  driver_id: number;
  name: string;
  family: string;
  number: string;
  nationalCode?: number;
  address?: string;
  cartNumber?: number;
  plateNumber?: string;
  conventionStartDate?: string;
  conventionEndDate?: string;
  sig?: any;
  tankNumber?: number;
  tankCode?: string;
  sigImg?: {
    url: string;
    size: number;
    type: string;
    base64: string;
  };
}

export interface deleteModalDataTypes {
  driver_id:number, 
  name:string, 
  family?:string,
}

export interface handlerDeleteFuncTypes {
  driver_id:number, 
  name:string,
  family:string
}

const tableHead = [
  {pName:"نام", eName:"name", type:"string", sorted:true, showAllData:true},
  {pName:"نام خانوادگی", eName:"family", type:"string", sorted:true, showAllData:true},
  {pName:"تلفن", eName:"number", type:"number", sorted:true, showAllData:true},
  {pName:"کد ملی", eName:"nationalCode", type:"number", sorted:true, showAllData:true},
]

const tableHeadParams = [
  {pName:"نام", eName:"name", type:"string", star:true},
  {pName:"نام خانوادگی", eName:"family", type:"string", star:true},
  {pName:"تلفن", eName:"number", type:"number", star:true},
  {pName:"کد ملی", eName:"nationalCode", type:"number", star:true},
  {pName:"آدرس", eName:"address", type:"string"},
  {pName:"شماره کارت", eName:"cartNumber", type:"string"},
  {pName:"شماره پلاک", eName:"plateNumber", type:"string"},
  // {pName:"تاریخ شروع قرارداد", eName:"conventionStartDate", type:"string"},
  // {pName:"تاریخ پایان قرارداد", eName:"conventionEndDate", type:"string"},
  {pName:"امضا", eName:"sig", type:"string"}
]

const tablePrintableHeadParams = [
  {pName:"نام", eName:"name", type:"string"},
  {pName:"نام خانوادگی", eName:"family", type:"string"},
  {pName:"تلفن", eName:"number", type:"number"},
  {pName:"کد ملی", eName:"nationalCode", type:"number"},
  {pName:"آدرس", eName:"address", type:"string"},
  {pName:"شماره کارت", eName:"cartNumber", type:"string"},
  {pName:"شماره پلاک", eName:"plateNumber", type:"string"},
]

const defaultArrayValue = {
  driver_id:0, name:'', family:'', number:'', nationalCode:undefined, address:'', cartNumber:undefined, plateNumber:'' , conventionStartDate:'', conventionEndDate:'', sig:''
}

const DriversList = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState<boolean>(false)
  const [refreshPage, setRefreshPage] = useState<number>(0)
  const [searchDropDownParam, setSearchDropDownParam] = useState<string>(tableHead[0].pName)
  const [data, setData] = useState<any[]>([])
  const [filteredArray, setFilteredArray] = useState<any[]>([])
  const [image, setImage] = useState<any>('');
  const [searchItem, setSearchItem] = useState<string>('')
  const [openInsertModal, setOpenInsertModal] = useState<boolean>(false)
  const [insertModalData, setInsertModalData] = useState<updateModalDataTypes>(defaultArrayValue)
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false)
  const [updateModalData, setUpdateModalData] = useState<updateModalDataTypes>(defaultArrayValue)
  const [openShowModal, setOpenShowModal] = useState<boolean>(false)
  const [showModalData, setShowModalData] = useState<updateModalDataTypes>(defaultArrayValue)
  const headerTitle = 'لیست راننده ها';
  
  useEffect(() => {
    setLoader(true);
    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}users/driversList`, {
                headers: {
                    'auth-token': Cookies.get('token'),
                    'auth-id': Cookies.get('id')
                }
            });
            setFilteredArray(response.data);
            setData(response.data);
            setLoader(false);
        } catch (error) {
            // console.error('Error fetching tanks:', error);
            setLoader(false); // Hide loader after fetching data
            alert(loginText)
            navigate('/member')
        }
    };

    fetchData();
  }, [refreshPage]);

  const handlerInsertQuery = async () => {
    if(insertModalData.name && insertModalData.family && insertModalData.number && insertModalData.nationalCode){
      setLoader(true)
      axios.post(`${process.env.REACT_APP_API_ADDRESS}users/insertDriver`, 
          {
            name: insertModalData.name,
            family: insertModalData.family,
            number: ConvertPersianNumToEnglishNum(insertModalData.number),
            nationalCode: ConvertPersianNumToEnglishNum(insertModalData.nationalCode),
            address: insertModalData.address,
            cartNumber: ConvertPersianNumToEnglishNum(insertModalData.cartNumber),
            plateNumber: ConvertPersianNumToEnglishNum(insertModalData.plateNumber),
            sigImg: image.base64
          },
          {
              headers: {
                  'auth-token': Cookies.get('token'),
                  'auth-id': Cookies.get('id')
              }
          })
          .then(response => {
              // console.log('Response:', response.data);
              setLoader(false)
              // alert('راننده به درستی اضافه شد')
              setRefreshPage((prev) => prev + 1)
              setOpenInsertModal(false)
              setImage('')
              setInsertModalData(defaultArrayValue)
          })
          .catch(error => {
              // console.error('Error:', error);
              setLoader(false)
              setImage('')
              alert(uploadError)
              // alert(loginText)
              // navigate('/member')          
          }
      )
    }else{
      alert('لطفا تمام فیلد های ستاره دار را کامل کنید.')
    }
  };

  const handlerUpdateQuery = async () => {
    setLoader(true)
    axios.post(`${process.env.REACT_APP_API_ADDRESS}users/updateDriver`, 
        {
          name: updateModalData.name,
          family: updateModalData.family,
          number: ConvertPersianNumToEnglishNum(updateModalData.number),
          nationalCode: ConvertPersianNumToEnglishNum(updateModalData.nationalCode),
          address: updateModalData.address,
          cartNumber: updateModalData.cartNumber ? ConvertPersianNumToEnglishNum(updateModalData.cartNumber) : null,
          plateNumber: ConvertPersianNumToEnglishNum(updateModalData.plateNumber),
          sigImg: image != '' ? image.base64 : null,
          driver_id: updateModalData.driver_id,
        },
        {
            headers: {
                'auth-token': Cookies.get('token'),
                'auth-id': Cookies.get('id')
            }
        })
        .then(response => {
            // console.log('Response:', response.data);
            setLoader(false)
            // alert('راننده به درستی ویرایش شد')
            setRefreshPage((prev) => prev + 1)
            setOpenUpdateModal(false)
            setImage('')
        })
        .catch(error => {
            // console.error('Error:', error);
            setLoader(false)
            setImage('')
            alert(uploadError)
            // alert(loginText)
            // navigate('/admin')   
        }
    )
  };

  function SearchTableData() {
    const handleInputSearch = (e) => { 
      const searchTerm = ConvertPersianNumToEnglishNum(e.target.value);
      setSearchItem(searchTerm);
      const filteredItems = data.filter((item) =>
          searchDropDownParam === tableHead[0].pName ?
            item.name && item.name.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase()) :
          searchDropDownParam === tableHead[1].pName ?
            item.family && item.family.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase()) :
          searchDropDownParam === tableHead[2].pName ?
            item.number && item.number.includes(searchTerm) :
          searchDropDownParam === tableHead[3].pName ?
            item.nationalCode && item.nationalCode.includes(searchTerm) :
          null
      );
    
      setFilteredArray(filteredItems);
    }
    return(
      <div className='w-full flex flex-row flex-wrap-reverse center md:items-center md:justify-between gap-3 p-4'>
        <div className='w-full sm:w-fit flex flex-row flex-wrap-reverse gap-2'>
          <PrimaryBtn onClick={()=>setOpenInsertModal(true)}>
              افزودن راننده جدید
          </PrimaryBtn>
          <TransparentLink to={'/member/printList/'} params={{array:filteredArray, listHead:tablePrintableHeadParams , pageHeader:headerTitle}}>
                فرم چاپی
          </TransparentLink>
        </div>
        <div className='w-fit flex items-start justify-start flex-row gap-3'>
          <SerachInput title={"جستجوی " + searchDropDownParam} state={searchItem} handler={handleInputSearch}/>
          <DropDownSearchStatus list={tableHead} state={searchDropDownParam} setState={setSearchDropDownParam}/>
        </div>
      </div>
    )
  }

  const handlerUpdateFunc = (item: updateModalDataTypes) => {
    setOpenUpdateModal(true)
    setUpdateModalData({driver_id:item.driver_id, name:item.name, family:item.family, number:item.number, nationalCode:item.nationalCode, address:item.address, plateNumber:item.plateNumber, conventionStartDate:item.conventionStartDate, conventionEndDate:item.conventionEndDate, cartNumber:item.cartNumber, sig:item.sigImg })
  }
  
  const handlerShowFunc = (item: ShowModalDataTypes) => {
    setOpenShowModal(true)
    setShowModalData({driver_id:item.driver_id, name:item.name, family:item.family, number:item.number, nationalCode:item.nationalCode, address:item.address, plateNumber:item.plateNumber, conventionStartDate:item.conventionStartDate, conventionEndDate:item.conventionEndDate, cartNumber:item.cartNumber, sig:item.sigImg })
  }
  
  const InsertUpdateModalBody = ({type}:{type:'insert'|'update'|'show'}) => {
    return(
      <ul className='w-[20rem] sm:w-[25rem] space-y-2'>
        {
          tableHeadParams.map((item,key)=>(
            <li key={key} className='flex flex-row items-center justify-start'>
              {/* <ImageCard state={imageFront} setState={setImageFront} title={'عکس جلوی تانکر'}/> */}
              <span className='text-sm w-2/5'>
                {item.pName}:
                {type !== 'show' ? item.star && <span className='text-red-500'>*</span> : null}
              </span>
                {
                  item.eName === 'sig' ?
                    type === 'show' ?
                      showModalData[item.eName] ? 
                        <ShowProtectedImage
                            imageRoute={'signaturePhotos'} 
                            imageName={showModalData[item.eName]} 
                            imageAlt={'عکس امضا ثبت نشده'} 
                            imageClass={'w-full h-32 object-contain'}
                        />
                        // <img 
                        //   className='w-full h-32 object-contain'
                        //   src={process.env.REACT_APP_API_ADDRESS + 'signaturePhotos' + showModalData[item.eName]} 
                        //   alt={'عکس امضا ثبت نشده'}
                        // />
                      :
                        <input
                          type="text" 
                          disabled={true}
                          id={item.pName}
                          key={item.eName}
                          className={" p-2 w-full sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " }
                          placeholder={'امضا ثبت نشده'} 
                        /> 
                    :
                    type === 'update' ?
                      <ImageCard
                        state={image ? image : updateModalData[item.eName] ? process.env.REACT_APP_API_ADDRESS + 'signaturePhotos' + updateModalData[item.eName] : image} 
                        setState={setImage} 
                        title={'آپلود عکس امضا'}
                        size={'xs'}
                      />
                    : 
                      <ImageCard 
                        state={image} 
                        setState={setImage} 
                        title={'آپلود عکس امضا'}
                        size={'xs'}
                      />
                  :
                    <input
                      type="text" 
                      disabled={type === 'show' ? true : false}
                      id={item.pName}
                      className="p-2 w-full sm:max-w-80 text-xs text-gray-900 border rounded-lg bg-gray-50 " 
                      placeholder={item.pName}
                      value={
                        type === 'insert' ? 
                        insertModalData[item.eName] 
                        :type === 'update' ? 
                        updateModalData[item.eName] 
                        :showModalData[item.eName]
                      } 
                      onChange={e => type === 'insert' ? setInsertModalData(prevState => ({...prevState,[item.eName]: e.target.value})) : setUpdateModalData(prevState => ({...prevState,[item.eName]: e.target.value}))}
                    />
                }
            </li>
          ))
        }
      </ul>
    )
  }

  return (
    <div>
      {loader && <FullLoader />}
      <Member_Header title={headerTitle}/>

      <div className='m-r-nav px-4 mb-8 sm:px-8'>
        {SearchTableData()}
        <AdminTableList
          TableList={filteredArray} 
          tableHeadParams={tableHead} 
          handlerShowFunc={handlerShowFunc}
          handlerUpdateFunc={handlerUpdateFunc}
        />
      </div>

      <Modal 
        title='افزودن راننده'
        body={InsertUpdateModalBody({type:'insert'})}
        onOpen={openInsertModal}
        btnCancel={setOpenInsertModal}
        btnSubmit={handlerInsertQuery}
      />

      <Modal 
        title='ویرایش راننده'
        body={InsertUpdateModalBody({type:'update'})}
        onOpen={openUpdateModal}
        btnCancel={setOpenUpdateModal}
        btnSubmit={handlerUpdateQuery}
      />

      <Modal 
        title={'جزئیات راننده'}
        body={InsertUpdateModalBody({type:'show'})}
        onOpen={openShowModal}
        btnCancel={setOpenShowModal}
      />
    </div>
  )
}

export default DriversList
