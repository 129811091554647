import React,{useState, useEffect} from 'react'
import Member_Header from './header.tsx'
import { HiOutlineCloudUpload } from "react-icons/hi";
import { SuccessBtn } from '../../components/btn.tsx'
import { DatePicketComponent, HandlerNavigateTankCode, HandlerChangeDigitToEn,  } from '../../components/common.tsx'
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { Modal } from '../../components/modal.tsx'
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText, uploadError } from '../../array/array.ts';

const RecordTankTest = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState<boolean>(false)
  const [testDate, setTestDate] = useState<string>()
  const [expireDate, setExpireDate] = useState<string>()
  const [image, setImage] = useState<string>();
  const [openSubmitModal, setOpenSubmitModal] = useState<boolean>(false)
  const tankCode = localStorage.getItem("tankCode");
  const tankId = localStorage.getItem("tankId");

  useEffect(()=>{
    if(!Cookies.get('id')){
      alert(loginText)
      navigate('/member')
    }
  },[])

  function handlerSubmitModal() {
    if(testDate && expireDate && image){
      setOpenSubmitModal(!openSubmitModal)
    }else{
      alert('لطفا تاریخ و عکس تست تانک را وارد کنید.')
    }
  }
  
  const handlerSubmitBtn = async () => {
    setOpenSubmitModal(false)
    setLoader(true)

    axios.post(`${process.env.REACT_APP_API_ADDRESS}users/recordTankTest`, 
      {
        tank_id: tankId,
        user_id: Cookies.get('id'),
        testDate: HandlerChangeDigitToEn(testDate),
        expireDate: HandlerChangeDigitToEn(expireDate),
        img: image.base64 // Use base64 image data
      },
      {
        headers: {
          'auth-token': Cookies.get('token'),
          'auth-id': Cookies.get('id')
        }
      })
      .then(response => {
          // console.log('Response:', response.data);
          setLoader(false)
          alert('فرم به درستی ارسال شد. برای نمایش تست تانک به صفحه لیست تست تانک ها مراجعه کنید .')
          setTestDate(undefined)
          setExpireDate(undefined)
          setImage('')
      })
      .catch(error => {
          // console.error('Error:', error);
          setLoader(false)
          alert(uploadError)
          // alert(loginText)
          // navigate('/member')
      }
    )
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    // Resize the selected image
    Resizer.imageFileResizer(
      selectedImage,
      600, // Set the desired width
      600, // Set the desired height
      'JPEG', // Set the format of the resized image
      99, // Set the quality of the resized image
      0, // Set the Rotation of the image
      (blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const imageData = reader.result.split(',')[1]; // Extract base64 data
          setImage({
            url: URL.createObjectURL(blob),
            size: blob.size,
            type: blob.type,
            base64: imageData // Add base64 data to image object
          });
        };
        reader.readAsDataURL(blob);
      },
      "blob",
    );
  };

  return (
    <div className='m-r-nav'>
      {loader && <FullLoader />}
      <Member_Header tankName={tankCode ? tankCode+' ثبت تست تانک ' : ''}/>

      {
        tankCode ?
          <div className='center flex-col px-6 md:px-32 py-8 pt-0 space-y-8'>
            <div className='center flex-col-reverse sm:flex-row rounded center gap-4'>
              <DatePicketComponent 
                title='انتخاب تاریخ'
                state={testDate} 
                setState={setTestDate} 
                type='custom'
              />
              <p className='text-right'>
                .لطفا تاریخ ثبت تست تانک را انتخاب کنید
              </p>
            </div>

            <div className='center flex-col-reverse sm:flex-row rounded center gap-4'>
              <DatePicketComponent 
                title='انتخاب تاریخ'
                state={expireDate} 
                setState={setExpireDate} 
                type='custom'
              />
              <p className='text-right'>
                .لطفا تاریخ اتمام تست تانک را انتخاب کنید
              </p>
            </div>

            <div className='center w-full flex-row rounded center gap-4'>
              <div className="w-11/12 sm:w-8/12 rounded-lg shadow-xl bg-gray-50">
                <form onChange={handleImageChange} className="m-4">
                  <label className="inline-block mb-2 text-gray-800 text-right w-full">آپلود عکس</label>
                  {
                    image ?
                      <label className='space-y-4 flex center flex-col'>
                        <img src={image.url} alt="Selected Image" className='w-full h-fit shadow-xl rounded object-contain max-h-full'/>
                        <div className="w-full px-4 py-2 text-white bg-blue-500 rounded shadow-xl hover:scale-[1.02] text-center cursor-pointer">
                          انتخاب مجدد عکس
                        </div>
                        <input type="file" className="hidden" />
                      </label>
                    :
                      <div className="flex items-center justify-center w-full pb-6">
                        <label className="flex flex-col w-full h-32 border-4 border-blue-200 border-dashed hover:bg-gray-100 hover:border-gray-300 cursor-pointer">
                          <div className="flex flex-col items-center justify-center pt-7 gap-3">
                            <HiOutlineCloudUpload className='text-gray-400' size={'1.8rem'}/>
                            <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                              .لطفا عکس تست تانک را انتخاب کنید
                            </p>
                          </div>
                          <input type="file" className="hidden" />
                        </label>
                      </div>
                  }
                </form>
              </div>
            </div>


            <div className="flex justify-center p-2">
              <SuccessBtn onClick={handlerSubmitModal}>
                ثبت تغییرات
              </SuccessBtn>
            </div>

          </div>
        :
          <HandlerNavigateTankCode />
      } 

      <Modal 
        icon="success"
        title="ثبت نهایی فرم"
        body="آیا از صحت اطلاعات فوق اطمینان دارید."
        btnCancel={setOpenSubmitModal}
        btnSubmit={handlerSubmitBtn}
        onOpen={openSubmitModal}
      />
    </div>
  )
}

export default RecordTankTest
