import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ScrollToTop } from './components/ScrollTop.tsx'
import Favicon from 'react-favicon';

import Home from './pages/home.tsx'

import Member_Login from './pages/member/login.tsx'
import Member_Home from './pages/member/home.tsx'
import RecordTankTest from './pages/member/recordTankTest.tsx'
import TankTestList from "./pages/member/tankTestList.tsx";
import DeliveryReport from './pages/member/deliveryReport.tsx'
import DeliveryReportDetail from './pages/member/deliveryReportDetail.tsx'
import Tanks from './pages/member/tanks.tsx'
import RepairForm from './pages/member/repairForm.tsx'
import RepairFormList from './pages/member/repairFormList.tsx'
import AddTankerPhotos from './pages/member/addTankerPhotos.tsx';
import TankerPhotosList from './pages/member/tankerPhotosList.tsx';
import RefineryLoadingForm from './pages/member/refineryLoadingForm.tsx';
import RefineryLoadingList from './pages/member/refineryLoadingList.tsx';
import DriversList from './pages/member/driversList.tsx'
import SystemWarning from './pages/member/systemWarning.tsx'

import Admin_Home from './pages/admin/home.tsx';
import Admin_Login from './pages/admin/login.tsx';
import Admin_TankersList from './pages/admin/tankersList.tsx';
import Admin_DriversList from './pages/admin/driversList.tsx';
import Admin_DeliveryReport from './pages/admin/deliveryReport.tsx';
import Admin_DeliveryReportDetail from './pages/admin/deliveryReportDetail.tsx';
import Admin_EditDeliveryReport from './pages/admin/editDeliveryReport.tsx';
import Admin_LoginReport from './pages/admin/loginReport.tsx';
import Admin_UserAccountsList from './pages/admin/userAccountsList.tsx';
import Admin_TankTestList from './pages/admin/tankTestList.tsx';
import Admin_TankerPhotosList from './pages/admin/tankerPhotosList.tsx';
import Admin_TankerPhotosListDetail from './pages/admin/tankerPhotosListDetail.tsx';
// import Admin_RepairFormReport from './pages/admin/repairFormReport.tsx'
// import Admin_RepairFormReportDetail from './pages/admin/repairFormReportDetail.tsx'
import Admin_RepairFormList from './pages/admin/repairFormList.tsx'
import Admin_RefineryLoadingList from './pages/admin/refineryLoadingList.tsx'
import Admin_SystemWarning from './pages/admin/systemWarning.tsx'

import Guest_Login from './pages/guest/login.tsx';
import Guest_Tanks from './pages/guest/tanks.tsx';

import PrintableDeliveryReport from './pages/printableDeliveryReport.tsx';
import PrintList from './pages/printList.tsx';
import ForgetPassword from './pages/forgetPassword.tsx';
import ContactUs from './pages/contactUs.tsx';
import SiteServices from './pages/siteServices.tsx';
import Page404 from './pages/Page404.tsx';

function App() {
  return (
    <Router>
      <Favicon url={require('./assets/images/favicon.ico')} />
      <ScrollToTop/>
      <Routes>
        <Route path="/" index element={<Home />} />

        <Route path="/member" element={<Member_Login />} />
        <Route path="/member/tanks" element={<Tanks />} />
        <Route path="/member/home" element={<Member_Home />} />
        <Route path="/member/recordTankTest" element={<RecordTankTest />} />
        <Route path="/member/tankTestList" element={<TankTestList />} />
        <Route path="/member/deliveryReport" element={<DeliveryReport />} />
        <Route path="/member/deliveryReportDetail" element={<DeliveryReportDetail />} />
        <Route path="/member/printableDeliveryReport" element={<PrintableDeliveryReport />} />
        <Route path="/member/addTankerPhotos" element={<AddTankerPhotos />} />
        <Route path="/member/tankerPhotosList" element={<TankerPhotosList />} />
        <Route path="/member/repairForm" element={<RepairForm />} />
        <Route path="/member/repairFormList" element={<RepairFormList />} />
        <Route path="/member/refineryLoadingForm" element={<RefineryLoadingForm />} />
        <Route path="/member/refineryLoadingList" element={<RefineryLoadingList />} />
        <Route path="/member/driversList" element={<DriversList />} />
        <Route path="/member/systemWarning" element={<SystemWarning />} />
        <Route path="/member/printList" element={<PrintList />} />
        <Route path="/member/contactUs" element={<SiteServices />} />
        <Route path="/member/siteServices" element={<SiteServices />} />

        <Route path="/admin" element={<Admin_Login />} />
        <Route path="/admin/home" element={<Admin_Home />} />
        <Route path="/admin/tankersList" element={<Admin_TankersList />} />
        <Route path="/admin/driversList" element={<Admin_DriversList />} />
        <Route path="/admin/deliveryReport" element={<Admin_DeliveryReport />} />
        <Route path="/admin/deliveryReport/:name" element={<Admin_DeliveryReportDetail />} />
        <Route path="/admin/deliveryReport/editDeliveryReport/:name" element={<Admin_EditDeliveryReport />} />
        <Route path="/admin/loginReport" element={<Admin_LoginReport />} />
        <Route path="/admin/userAccountsList" element={<Admin_UserAccountsList />} />
        <Route path="/admin/contactUs" element={<ContactUs />} />
        <Route path="/admin/printList" element={<PrintList />} />
        <Route path="/admin/tankTestList" element={<Admin_TankTestList />} />
        <Route path="/admin/repairFormList" element={<Admin_RepairFormList />} />
        {/* <Route path="/admin/repairFormReport" element={<Admin_RepairFormReport />} />
        <Route path="/admin/repairFormReport/:name" element={<Admin_RepairFormReportDetail />} /> */}
        <Route path="/admin/printableDeliveryReport" element={<PrintableDeliveryReport />} />
        <Route path="/admin/tankerPhotosList" element={<Admin_TankerPhotosList />} />
        <Route path="/admin/tankerPhotosList/:name" element={<Admin_TankerPhotosListDetail />} />
        <Route path="/admin/refineryLoadingList" element={<Admin_RefineryLoadingList />} />
        <Route path="/admin/systemWarning" element={<Admin_SystemWarning />} />
        <Route path="/admin/siteServices" element={<SiteServices />} />

        <Route path="/forgetPassword" element={<ForgetPassword />} />

        <Route path="/guest" element={<Guest_Login />} />
        <Route path="/guest/tanks" element={<Guest_Tanks />} />

        <Route path="*" element={<Page404 />} />

      </Routes>
    </Router>
  );
}

export default App;
