import React, {useState, useEffect} from 'react'
import Admin_Header from './admin/header.tsx'
import axios from 'axios';
import { FullLoader } from '../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText } from '../array/array.ts';

const ContactUs = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState<boolean>(true)

    useEffect(() => {
        setLoader(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}userCheckAuth`, {
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                setLoader(false);
                // console.log(response.data)
            } catch (error) {
                // console.error('Error conecting server', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                navigate('/')
            }
        };
    
        fetchData();
    }, []);
    return (
        <>
            {
                loader ? 
                    <FullLoader />
                :
                    <>
                        <Admin_Header title='ارتباط با ما'/>

                        <div className='w-full h-full flex flex-row flex-wrap items-center justify-around p-12 gap-4'>
                            <img src={require('../assets/images/contact-us.png')} alt='Contact Us Image' className='w-full sm:max-w-md' />
                            <div className='p-0 sm:p-8 space-y-4'>
                                <p className='text-base text-stone-600 text-right'>جهت ارتباط با شماره زیر تماس حاصل فرمایید</p>
                                <p className='text-base font-black text-center'>0933 909 8855</p>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default ContactUs
