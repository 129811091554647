import React, { useState, useEffect } from 'react';
import Admin_Header from './header.tsx';
import { AdminTableList } from '../../components/adminTableList.tsx'
import { SerachInput, ConvertPersianNumToEnglishNum, handlerGetUsersTanks } from '../../components/common.tsx';
import axios from 'axios';
import { FullLoader } from '../../components/loader.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loginText } from '../../array/array.ts';

const tableHead = [
    {pName:"کد تانکر", eName:"tankCode", type:"string/number", sorted:true},
    {pName:"شماره مخزن", eName:"tankNumber", type:"number", sorted:true},
    {pName:"شماره شاسی", eName:"chassisNumber", type:"number", sorted:true},
]

const Admin_TankerPhotosList = () => {
    const navigate = useNavigate();
  
    const [loader, setLoader] = useState<boolean>(false)
    const [data, setData] = useState<any[]>([])
    const [filteredArray, setFilteredArray] = useState<any[]>([])
    const [searchItem, setSearchItem] = useState<string>('')

    useEffect(() => {
        setLoader(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}admin/tanksList`, {
                    headers: {
                        'auth-token': Cookies.get('token'),
                        'auth-id': Cookies.get('id')
                    }
                });
                handlerGetUsersTanks(setData, setFilteredArray, response.data)
                setLoader(false);
            } catch (error) {
                // console.error('Error fetching tanks:', error);
                setLoader(false); // Hide loader after fetching data
                alert(loginText)
                // navigate('/admin')
            }
        };
    
        fetchData();
    }, []);

    const SearchTableData = () => { 
        const handleInputSearch = (e) => { 
            const searchTerm = ConvertPersianNumToEnglishNum(e.target.value);
            setSearchItem(searchTerm)
            const filteredItems = data.filter((item) =>
                item.tankCode && item.tankCode.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredArray(filteredItems);
        }
        return(
            <div className="p-4 bg-white">
                <SerachInput title={"جستجو کد تانک"} state={searchItem} handler={handleInputSearch}/>
            </div>
        )
    }
    
    return (
        <div className='m-r-nav'>
            {loader && <FullLoader />}
            <Admin_Header title='عکس های تانک' desc='توضیحات: ابتدا تانک مورد نظر را انتخاب کرده. سپس دکمه انتخاب را کلیک کنید تا وارد صفحه ی عکس ها شوید' />
            <div className='px-4 mb-4 sm:px-12'>
                {SearchTableData()}
                <AdminTableList 
                    TableList={filteredArray} 
                    tableHeadParams={tableHead} 
                    linkTo={'/admin/tankerPhotosList/'}
                />
            </div>

        </div>
    )
}

export default Admin_TankerPhotosList
