import React from "react";
import { useNavigate } from 'react-router-dom'
import { IoIosExit } from "react-icons/io";
import { useLocation } from "react-router-dom";
import Cookies from 'js-cookie';


export default function Guest_Header() {
    const navigate = useNavigate();
    const location = useLocation();
    
    const handlerExit = () => {
        localStorage.removeItem('tankCode');
        localStorage.removeItem('tankId');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('tank22to46');
        Cookies.remove('token')
        Cookies.remove('id')
        navigate('/')
    }

    return (
        <>
            {/* ----- header line ----- */}
            <nav className={`w-full fixed z-50 h-16 flex flex-row justify-between items-center px-5 bg-gray-50 shadow-lg`}>
                <div className="tooltip">
                    <span className='tooltipTextBottom'>خروج</span>
                    <IoIosExit color="text-zinc-700" size={'1.8rem'} onClick={handlerExit}/>
                </div>
                <div className="flex flex-row items-center">
                    <p className="text-sm text-zinc-800 truncate px-2">
                        {localStorage.getItem('userInfo')}
                        {` خوش آمدید`}
                    </p>
                </div>
            </nav>
        </>
  );
}